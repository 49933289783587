import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

//components
import Input from '../../components/DesignSystem/Input'
import Spinner from '../../components/Spinner'
import { Button } from '../../components/DesignSystem'
import './index.scss'

// Services
import { getContracts, createDocument } from '../../services/documentsServices'

// validate
import { currency } from '../../constants/validate'
import CustomReactDatePicker from '../../components/DesignSystem/DateTime'
import Form from './components/Form'
import TitleSpace from '../../components/TitleSpace'
import DocumentGenerator from '../../components/DocumentGenerator'

function Contracts() {
  const {
    user: { token },
  } = useSelector(state => state.app)
  const [selectedContract, setSelectedContract] = useState('')
  const [form, setForm] = useState({})
  const [contractor, setContractor] = useState('')
  const [hired, setHired] = useState('')
  const [result, setResult] = useState('')
  const [step, setStep] = useState(1)
  const [documentId, setDocumentId] = useState('')
  const [isDone, setIsDone] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (selectedContract !== '' && data) {
      let contracts = data?.contracts?.filter(contract => contract.label === selectedContract)
      let newForm = {}
      contracts[0].fields.forEach(item => {
        newForm[item.name] = ''
      })

      setForm(newForm)
    } else {
      setForm({})
    }

    // eslint-disable-next-line
  }, [selectedContract])

  const { isLoading, data } = useQuery({
    queryKey: ['contracts'],
    queryFn: () => fetchContracts(),
    staleTime: 1000 * 30,
    enabled: !_.isEmpty(token),
    refetchOnWindowFocus: true,
    retry: false,
  })

  async function fetchContracts() {
    try {
      const { data } = await getContracts()

      return { contracts: data.contracts }
    } catch (error) {
      console.log(error)
      const errorContent = JSON.stringify(error)
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
          <Button
            icon='Copy'
            onlyIcon
            onClick={() => {
              navigator.clipboard.writeText(errorContent)
              toast.success('Erro copiado para área de transferência')
            }}
          />
        </div>
      )
    }
  }

  function returnSelectOptions() {
    let options = []

    data?.contracts?.forEach(item => {
      options.push({ value: item.value, label: item.value })
    })

    return options
  }

  function returnContractFields() {
    let contracts = data?.contracts?.filter(contract => contract.label === selectedContract) || []

    if (_.isEmpty(contracts)) {
      return []
    }

    return contracts[0].fields
  }

  function returnInputs(item) {
    switch (item.type) {
      case 'string':
        return (
          <Input
            placeholder={item.name}
            label={item.name}
            value={form[item.name]}
            onChange={value => setForm({ ...form, [item.name]: value })}
            key={item.name}
          />
        )
      case 'currency':
        return (
          <Input
            placeholder={item.name}
            label={item.name}
            value={form[item.name]}
            onChange={value => setForm({ ...form, [item.name]: currency(value) })}
            key={item.name}
          />
        )
      case 'date':
        return (
          <CustomReactDatePicker
            label={item.name}
            value={form[item.name]}
            onChange={value => setForm({ ...form, [item.name]: value })}
            key={item.name}
          />
        )
      default:
        break
    }
  }

  async function createContract() {
    let prompt = `Crie um ${selectedContract}. O nome do contratante é ${contractor} e o nome do contratado é ${hired}. `
    let infos = []

    for (let key in form) {
      if (form[key] !== '') {
        infos.push(`${key}: ${form[key]}. `)
      }
    }

    if (!_.isEmpty(infos)) {
      prompt += 'As informações do contrato são: '

      infos.forEach(item => {
        prompt += item
      })
    }

    try {
      setStep(2)
      await createDocument(prompt, 'contract', false, false, token, setResult, setDocumentId)
      setIsDone(true)
      queryClient.invalidateQueries(['usage-quantity'])
    } catch (error) {
      console.log(error)
      const errorContent = JSON.stringify(error)
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
          <Button
            icon='Copy'
            onlyIcon
            onClick={() => {
              navigator.clipboard.writeText(errorContent)
              toast.success('Erro copiado para área de transferência')
            }}
          />
        </div>
      )
    }
  }

  return (
    <div className='contracts'>
      <TitleSpace
        title='Contratos'
        subtitle={'Insira as informações e iremos gerar um modelo de contrato'}
      />
      {isLoading && (
        <div className='contracts__loading'>
          <Spinner size={40} />
        </div>
      )}
      {step === 1 && !isLoading && (
        <Form
          createContract={createContract}
          returnSelectOptions={returnSelectOptions}
          setSelectedContract={setSelectedContract}
          selectedContract={selectedContract}
          contractor={contractor}
          setContractor={setContractor}
          hired={hired}
          setHired={setHired}
          returnContractFields={returnContractFields}
          returnInputs={returnInputs}
        />
      )}
      {step === 2 && <DocumentGenerator result={result} documentId={documentId} isDone={isDone} />}
    </div>
  )
}

export default Contracts
