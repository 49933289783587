import ChatMessage from '../../../../components/ChatMessage'
import './index.scss'

export default function PdfPlaceholder({ message }) {
  return (
    <section>
      <ChatMessage profile='system' message={message} noCopy />
    </section>
  )
}
