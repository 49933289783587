import { useSelector, useDispatch } from 'react-redux'
import Draggable from 'react-draggable'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { X } from 'lucide-react'

import './index.scss'

function MessageDetail() {
  const { messageDetail } = useSelector(state => state.chat)
  const dispatch = useDispatch()

  function handleCloseMessageDetail() {
    dispatch({ type: 'CHANGE_MESSAGE_DETAIL_OPEN', payload: false })
    dispatch({ type: 'CHANGE_MESSAGE_DETAIL', payload: {} })
  }

  return (
    <Draggable cancel='article' >
      <div className='message__detail' >
        <article style={{ cursor: 'auto' }}>
          <header className='message__header'>
            <h3 className='message__header__title'>spectter</h3>
            <div>
              <button onClick={handleCloseMessageDetail}>
                <X size={24} color='#fff' />
              </button>
            </div>
          </header>
          <div className='message__section__text message__section__text--system '>
            <ReactMarkdown remarkPlugins={[remarkGfm]} className='markdown__container'>
              {messageDetail.message}
            </ReactMarkdown>
          </div>
        </article>
      </div>
    </Draggable>
  )
}

export default MessageDetail
