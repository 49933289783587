export async function generatePrompt({notifier,notified,address,info}) {

    let text = 'Crie uma notificação extrajudicial.'

    text += `O notificante se chama ${notifier.name} e seu documento é ${
      notifier.document
    }. O notificado se chama ${notified.name} e seu documento é ${
      notified.document
    }. O notificado reside no seguinte endereço: ${address.street}, ${address.number}${
      address.complement ? `,${address.complement}` : ''
    }. Cep: ${address.CEP}. No estado ${address.state} e cidade ${
      address.city
    }. O fato da notificação é: ${info.facts}.${
      info.requests ? `O pedido da notificação é: ${info.requests}.` : ''
    }`

    if (info.legalBase) {
      text += ` Considerando os fatos, os pedidos e por ser uma notificação da área do direito ${info.lawArea}, por favor encontre decisões, jurisprudências e fundamentos legais que auxiliem na confecção da notificação`
    }

    return text
  }