import React, { useCallback, useEffect, useState, useRef } from 'react'

import './index.scss'
import CustomIcon from '../CustomIcon'

export default function ItemValue({ value, icon, isDocumentName }) {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
  const itemValueRef = useRef(null)
  const tooltipRef = useRef(null)

  const handleClickOutside = useCallback(
    event => {
      if (tooltipIsOpen && tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setTooltipIsOpen(false)
        tooltipRef.current.classList.remove('document-item__value-tooltip--visible')
      }
    },
    [tooltipIsOpen]
  )

  useEffect(() => {
    if (typeof window != 'undefined') {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])

  function handleShowTooltip() {
    const { top, left } = itemValueRef.current.getBoundingClientRect()
    const tooltip = tooltipRef.current
    tooltip.classList.add('document-item__value-tooltip--visible')

    const tooltipHeight = tooltip.getBoundingClientRect().height
    const distanceToTop = top + window.scrollY
    tooltip.style.top = `${distanceToTop - (tooltipHeight + 10)}px`
    tooltip.style.left = `${left - 20}px`
    setTooltipIsOpen(true)
  }

  function returnClassName() {
    let className = 'document-item__value'

    if (isDocumentName) {
      className += ' document-item__value--document-name'
    }

    if (icon) className += ' document-item__value--with-icon'

    return className
  }

  return (
    <p
      className={returnClassName()}
      title={value}
      ref={itemValueRef}
      onClick={e => handleShowTooltip(e.target)}>
      <span className='document-item__value-tooltip' ref={tooltipRef}>
        {value}
      </span>
      {value}
      {icon && <CustomIcon icon={icon} size={18} />}
    </p>
  )
}
