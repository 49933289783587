// Components
import CustomIcon from '../CustomIcon'
import * as Accordion from '@radix-ui/react-accordion'

// Styles
import './index.scss'

function AccordionItem({ title, subtitle, value, children }) {
  return (
    <Accordion.Item value={value} className='accordion-item'>
      <Accordion.Trigger className='accordion-trigger'>
        <h2>{title}</h2>
        <p>{subtitle}</p>
        <CustomIcon icon='ChevronDown' className='accordion-chevron' />
      </Accordion.Trigger>
      <Accordion.Content className='accordion-content'>{children}</Accordion.Content>
    </Accordion.Item>
  )
}

export default AccordionItem
