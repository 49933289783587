import { Tabs } from '../DesignSystem'
import './index.scss'

export default function ChatToggle({ display, activeDisplay, handleDisplay, list }) {
  function generateFinalList() {
    let finalList = []

    list.forEach(item => {
      finalList.push({ label: item, action: handleDisplay })
    })

    return finalList
  }

  return (
    <nav className={`chat__toggle ${activeDisplay === display ? 'chat__toggle--active' : ''}`}>
      <Tabs list={generateFinalList()} maxWidth={200} />
    </nav>
  )
}
