import axios from 'axios'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ProtectedRoute from './components/ProtectedRoute'
import Chat from './containers/Chat'
import ReadDocsList from './containers/ReadDocsList'
import Invite from './containers/Invite'
import Login from './containers/Login'
import RedefinePassword from './containers/RedefinePassword'
import Configurations from './containers/Configurations'
import Notifications from './containers/Notifications'
import Contracts from './containers/Contracts'
import Complaints from './containers/Complaints'
import ComplaintDetails from './containers/ComplaintDetails/ComplaintDetails'
import DocumentsList from './containers/DocumentsList'
import Document from './containers/Documents'
import TechnicalDocumentation from './containers/TechnicalDocumentation'
import Transcription from './containers/Transcription'
import TranscriptsList from './containers/TranscriptsList'
import Terms from './containers/Terms'
import Payment from './containers/Payment'

import './styles/index.scss'

function App() {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const loggedUserStringified = localStorage.getItem('user')

  useEffect(() => {
    const checkUserData = async () => {
      if (loggedUserStringified) {
        const loggedUser = JSON.parse(loggedUserStringified)
        axios.defaults.headers.common['authentication'] = loggedUser.token
        const { data } = await axios.get('/user/get-user-status')
        dispatch({ type: 'CHANGE_USER', payload: { ...loggedUser, ...data.data } })
        if (data.data.status !== loggedUser.status) {
          localStorage.setItem('user', JSON.stringify({ ...loggedUser, ...data.data }))
        }
      }
    }
    checkUserData()
  }, [dispatch, loggedUserStringified, pathname])

  return (
    <div className='row'>
      <div className='column'>
        <ToastContainer theme='dark' draggable={false} />
        <Routes>
          <Route path='/login' element={<ProtectedRoute isPublic />}>
            <Route path='' element={<Login />} />
          </Route>
          <Route path='/aviso-de-privacidade' element={<Terms />} />
          <Route path='/termos-de-uso' element={<Terms />} />
          <Route path='/notifica' element={<ProtectedRoute />}>
            <Route path='' element={<Notifications />} />
          </Route>
          <Route path='/nova-senha' element={<ProtectedRoute isPublic />}>
            <Route path='' element={<RedefinePassword />} />
            <Route path=':hash' element={<RedefinePassword />} />
          </Route>
          <Route path='/convite' element={<ProtectedRoute isPublic />}>
            <Route path=':origin' element={<Invite />} />
          </Route>
          <Route path='/' element={<ProtectedRoute />}>
            <Route path='' element={<Navigate to='/lista-consultar-documentos' replace />} />
          </Route>
          <Route path='/configuracoes' element={<ProtectedRoute />}>
            <Route path='' element={<Configurations />} />
          </Route>
          <Route path='/lista-consultar-documentos' element={<ProtectedRoute />}>
            <Route path='' element={<ReadDocsList />} />
            <Route path=':page' element={<ReadDocsList />} />
          </Route>
          <Route path='/lista-criar-documentos' element={<ProtectedRoute />}>
            <Route path='' element={<DocumentsList />} />
            <Route path=':page' element={<DocumentsList />} />
          </Route>
          <Route path='/documento' element={<ProtectedRoute />}>
            <Route path=':documentId' element={<Document />} />
          </Route>
          <Route path='/peticao' element={<ProtectedRoute />}>
            <Route path=':complaintId' element={<ComplaintDetails />} />
          </Route>
          <Route path='/transcricoes' element={<ProtectedRoute />}>
            <Route path='' element={<TranscriptsList />} />
            <Route path=':page' element={<TranscriptsList />} />
          </Route>
          <Route path='/transcricao' element={<ProtectedRoute />}>
            <Route path=':transcriptId' element={<Transcription />} />
          </Route>
          <Route path='/chat' element={<ProtectedRoute />}>
            <Route exact path=':id' element={<Chat />} />
          </Route>
          <Route path='/contratos' element={<ProtectedRoute />}>
            <Route path='' element={<Contracts />} />
          </Route>
          <Route path='/peticao' element={<ProtectedRoute />}>
            <Route path='' element={<Complaints />} />
          </Route>
          <Route path='/documentacao' element={<ProtectedRoute isDeveloper />}>
            <Route path='' element={<TechnicalDocumentation />} />
          </Route>
          <Route path='/pagamento' element={<ProtectedRoute />}>
            <Route path='' element={<Payment />} />
          </Route>
          <Route exact path='*' element={<ProtectedRoute isNotFound />} />
        </Routes>
      </div>
    </div>
  )
}

export default App
