import CustomIcon from '../../../../components/CustomIcon';
import './index.scss';

export default function PdfController({ current, total, handlePageChange }) {
  return (
    <nav className="pdf__controller">
      <button
        className={`pdf__controller--icon ${current === 1 && 'icon--off'}`}
        onClick={() => handlePageChange('prev')}
      >
        <CustomIcon icon="ArrowLeft" size={16} />
      </button>
      <div className="page__display">
        <input
          className="page__display--current"
          value={current}
          onChange={(e) => handlePageChange(e)}
        />
        <p>/</p>
        <p>{total}</p>
      </div>
      <button
        className={`pdf__controller--icon ${current === total && 'icon--off'}`}
        onClick={() => handlePageChange('next')}
      >
        <CustomIcon icon="ArrowRight" size={16} />
      </button>
    </nav>
  );
}
