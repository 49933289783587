import axios from 'axios'
import env from '../env'

export async function getDocumentList({ page, documentSearch, type = 'docs', signal }) {
  const { data } = await axios.get('/read-docs/list', {
    params: {
      page,
      documentSearch,
      type,
    },
    signal,
  })

  return data
}

export async function uploadDocument(formData, customId) {
  const { data } = await axios.post(`/read-docs/create-vectors/${customId}`, formData)

  return data
}

export async function getDocument(id) {
  const { data } = await axios.post('/read-docs/get-document', {
    docId: id,
  })

  return data
}

export async function addFileToVectorStore({ formData, chatId }) {
  const { data } = await axios.post(`/ai/file/${chatId}`, formData)

  return data
}

export async function deleteFileFromVectorStore({ chatId, fileId }) {
  const { data } = await axios.delete(`/ai/file/${chatId}/${fileId}`)

  return data
}

export async function submitTranscriptReplacements(id, replacements) {
  const { data } = await axios.post(`/assemblyai/vectorize?audio_id=${id}`, {
    replacements,
  })

  return data
}

export async function postQuestion({
  chatId,
  prompt,
  token,
  setStreamedResponse,
  setIsGenerating,
  setResponseQueryData,
}) {
  let url = 'https://server.spectter.com.br'
  if (process.env.NODE_ENV !== 'production') {
    // Assuming env is from process.env.NODE_ENV
    url = 'http://localhost:3001'
  }

  if (env !== 'production') {
    url = 'http://localhost:3001'
  }

  const response = await fetch(`${url}/read-docs/chat?chatId=${chatId}&prompt=${prompt}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/event-stream',
      authentication: token,
    },
  })

  let documentStream = ''
  const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()

  while (true) {
    const { value, done } = await reader.read()

    if (done) {
      setIsGenerating(false)
      setStreamedResponse(prev => {
        setResponseQueryData(prev)
        return ''
      })
      break
    }

    let separatedValue = value.split('------Finalização do stream------')

    if (separatedValue.length === 1) {
      setStreamedResponse(prev => prev + separatedValue[0])
      documentStream += separatedValue[0]
    } else {
      setStreamedResponse(separatedValue[1])
      documentStream += separatedValue[1]
    }
  }
}

export async function summarizeFile({ readDocsId, fileId }) {
  const { data } = await axios.post('/ai/file/summarize', {
    fileId,
    readDocsId,
  })

  return data
}

export async function cancelChatRun(docId) {
  const { data } = await axios.put('/ai/chat/cancel', { docId })

  return data
}

export async function getProgress(id) {
  const { data } = await axios.post(`/read-docs/get-progress/${id}`)

  return data
}

export async function inactivateReadDocument(body) {
  const data = await axios.delete('/read-docs/inactivate', {
    data: body,
  })

  return data
}

export async function renameReadDocs(body) {
  const { data } = await axios.put(`/read-docs/rename-read-docs`, body)

  return data
}
