import React, { useState } from 'react'

import AnimationBg from '../../components/AnimationBg'
import ForgetPassword from '../../components/ForgetPassword'
import LoginForm from '../../components/LoginForm'

import './index.scss'

function Login() {
  const [step, setStep] = useState(1)

  return (
    <div className='login'>
      <section className='login__inputs_section'>
        {step === 1 ? (
          <LoginForm onChangeStep={value => setStep(value)} />
        ) : (
          <ForgetPassword onChangeStep={value => setStep(value)} />
        )}
      </section>
      <section className='login__welcome_section'>
        <div className='login__welcome'>
          <h1>
            Bem-vindo ao <br />
            <span>Spectter</span>
          </h1>
          <p>Inteligência artificial</p>
        </div>
        <AnimationBg />
      </section>
    </div>
  )
}

export default Login
