import React from 'react'

import './index.scss'

const LegacyCard = ({ title, description }) => {
  return (
    <button className='legacy'>
      <h3 className='legacy__title'>Spectter</h3>
      <p className='legacy__description'>
        Para melhorar sua experiência, atualizamos nossa IA! Por esse motivo, este chat está
        desabilitado, mas você pode baixar o documento e iniciar um novo chat com desempenho
        aprimorado e novas funcionalidades!
      </p>
    </button>
  )
}

export default LegacyCard
