import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import blocked_email_providers from '../../constants/blocked_email_providers.txt'
import {
  minLength,
  phone,
  validateCnpj,
  validateCpf,
  validateEmail,
} from '../../constants/validate'

import AnimationBg from '../../components/AnimationBg'
import { Button, Input, Select, TextArea } from '../../components/DesignSystem'
import Spinner from '../../components/Spinner'

import spectter from '../../assets/spectter.svg'

import './index.scss'

const FIELD_DIC = {
  Nome: { label: 'Nome', autoComplete: false, required: true },
  Sobrenome: { label: 'Sobrenome', autoComplete: false, required: true },
  'E-mail Corporativo': {
    label: 'E-mail Corporativo',
    type: 'email',
    autoComplete: false,
    validate: 'email',
    required: true,
  },
  Cargo: {
    label: 'Cargo',
    autoComplete: false,
    required: true,
  },
  Telefone: {
    label: 'Telefone',
    type: 'tel',
    autoComplete: false,
    validate: 'phone',
    mask: 'PHONE',
  },
  Tipo: {
    label: 'Tipo',
    fieldType: 'select',
    options: [
      { value: 'Pessoa Física', label: 'Pessoa Física' },
      { value: 'Pessoa Jurídica', label: 'Pessoa Jurídica' },
    ],
    hideOptionSelect: true,
    required: true,
  },
  CPF: {
    label: 'CPF',
    type: 'tel',
    mask: 'CPF',
    autoComplete: false,
    validate: 'cpf',
    required: true,
  },
  'Nome da Empresa': { label: 'Nome da Empresa', autoComplete: false, required: true },
  CNPJ: {
    label: 'CNPJ',
    type: 'tel',
    mask: 'CNPJ',
    autoComplete: false,
    validate: 'cnpj',
    required: true,
  },
  'O que você espera usando o Spectter AI?': {
    label: 'O que você espera usando o Spectter AI?',
    fieldType: 'textarea',
    bgColorSeparator: '#1b1b1b',
  },
}

function Invite() {
  const { origin } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [step, setStep] = useState('form')
  const [form, setForm] = useState({
    ...Object.keys(FIELD_DIC).reduce((a, v) => ({ ...a, [v]: '' }), {}),
    Tipo: 'Pessoa Física',
  })

  useEffect(() => {
    async function getOrigin() {
      try {
        const { data } = await axios.post('/invite/check-origin', { origin })
        const {
          data: { isValid },
        } = data
        if (!isValid) navigate(`/login`)
      } catch (error) {
        navigate(`/login`)
        const errorContent = JSON.stringify(error)
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
            <Button
              icon='Copy'
              onlyIcon
              onClick={() => {
                navigator.clipboard.writeText(errorContent)
                toast.success('Erro copiado para área de transferência')
              }}
            />
          </div>,
        )
      }
    }
    if (origin) getOrigin()
  }, [origin])

  async function handleSubmit(evt) {
    evt.preventDefault()
    setIsLoading(true)

    try {
      let newForm = {
        Origem: origin,
      }
      const formKeys = Object.keys(form)
      for (let key of formKeys) {
        let value = form[key]
        const fieldValidation = FIELD_DIC[key].validate || null

        if (fieldValidation === 'cpf' && form['Tipo'] !== 'Pessoa Física') value = ''
        if (fieldValidation === 'cnpj' && form['Tipo'] !== 'Pessoa Jurídica') value = ''
        if (key === 'Nome da Empresa' && form['Tipo'] !== 'Pessoa Jurídica') value = ''

        if (value !== '') {
          let isValid = true
          if (fieldValidation === 'email') {
            let emailBlocklist = await fetch(blocked_email_providers)
            emailBlocklist = await emailBlocklist.text()
            emailBlocklist = emailBlocklist.replace(/(\r)/g, '').split('\n')
            isValid = validateEmail(value) && !emailBlocklist.includes(value.split('@')[1])
          }
          if (fieldValidation === 'phone') {
            isValid = minLength(13)(value) === undefined
          }
          if (fieldValidation === 'cpf') {
            isValid = validateCpf(value)
          }
          if (fieldValidation === 'cnpj') {
            isValid = validateCnpj(value)
          }
          if (fieldValidation === null) {
            isValid = minLength(3)(value) === undefined
          }
          if (!isValid) throw new Error(`"${key}" inválido`)
          newForm = { ...newForm, [key]: value }
        }
      }

      await axios.post('/invite/create', {
        origin,
        name: newForm['Nome'],
        surname: newForm['Sobrenome'],
        email: newForm['E-mail Corporativo'],
        phoneNumber: phone(newForm['Telefone']),
        type: newForm['Tipo'],
        cpf: newForm['CPF'],
        companyName: newForm['Nome da Empresa'],
        cnpj: newForm['CNPJ'],
        expectationComment: newForm['O que você espera usando o Spectter AI?'],
      })
      toast.success('Enviado')
      setStep('confirmation')
    } catch ({ code, message, response, ...error }) {
      if (code === 'ERR_BAD_RESPONSE') toast.error(response.data.errors[0].message)
      else toast.error(message)
    } finally {
      setIsLoading(false)
    }
  }

  const renderField = ({ fieldType = 'text', ...props }) => {
    const COMPONENT_DIC = { text: Input, select: Select, textarea: TextArea }
    const DynamicFieldComponent = COMPONENT_DIC[fieldType]
    return (
      <DynamicFieldComponent
        value={form[props.label]}
        onChange={value => setForm({ ...form, [props.label]: value })}
        withDebounce={props.validate}
        disabled={isLoading}
        placeholder=''
        hasLabel
        {...props}
      />
    )
  }

  return (
    <div className='invite'>
      {step === 'form' && (
        <form className='invite__form' onSubmit={handleSubmit}>
          <img src={spectter} alt='Logo Spectter.' />
          <h1>
            Seja um dos <strong>primeiros a experimentar!</strong>
          </h1>
          <p>
            Junte-se à nossa lista de espera exclusiva e tenha acesso antecipado ao Spectter AI.
          </p>
          <div className='invite__form__row'>
            {renderField(FIELD_DIC['Nome'])}
            {renderField(FIELD_DIC['Sobrenome'])}
          </div>
          {renderField(FIELD_DIC['E-mail Corporativo'])}
          {renderField(FIELD_DIC['Cargo'])}
          {renderField(FIELD_DIC['Telefone'])}
          {renderField(FIELD_DIC['Tipo'])}
          {form.Tipo === 'Pessoa Física' && renderField(FIELD_DIC['CPF'])}
          {form.Tipo === 'Pessoa Jurídica' && (
            <>
              {renderField(FIELD_DIC['Nome da Empresa'])}
              {renderField(FIELD_DIC['CNPJ'])}
            </>
          )}
          {renderField(FIELD_DIC['O que você espera usando o Spectter AI?'])}
          <Button contained color='green' fullWidth type='submit'>
            {isLoading ? <Spinner color='#272929' size={24} /> : 'GARANTIR ACESSO ANTECIPADO'}
          </Button>
        </form>
      )}
      {step === 'confirmation' && (
        <div className='invite__confirmation'>
          <img src={spectter} alt='Logo Spectter.' />
          <h1>
            Você está um passo mais perto de{' '}
            <strong>experimentar em primeira mão o Spectter AI</strong>
          </h1>
          <p>
            Fique de olho na sua caixa de entrada, pois entraremos em contato em breve com mais
            detalhes sobre o acesso antecipado.
          </p>
          <p>Obrigado por se inscrever na nossa lista de espera. Até logo!</p>
        </div>
      )}
      <AnimationBg />
    </div>
  )
}

export default Invite
