import axios from 'axios'

export async function forgetPassword(email) {
  const { data } = await axios.post('/user/forget-password', {
    email,
  })

  return data
}

export async function login(email, password) {
  const { data } = await axios.post('/user/login', {
    email,
    password,
  })

  return data
}

export async function verifyRecoveryPassword(email, password, abortController) {
  const { data } = await axios.post(
    '/user/verify-recovery-password',
    {
      email,
      password,
    },
    {
      signal: abortController && abortController.signal,
    }
  )

  return data
}

export async function redefinePassword(password) {
  const { data } = await axios.post('/user/change-password', {
    password,
  })

  return data
}

export async function redefineProfileData(newData) {
  const { data } = await axios.put('/user/update', { newData })
  axios.defaults.headers.common['authentication'] = data.data.token

  return data
}

export async function verifyCurrentPassword(password) {
  const data = await axios.post('/user/verify-password', { password })

  return data
}

export async function getUsersList() {
  const { data } = await axios.get('/user/list')

  return data
}

export async function createNewUser(name, surname, groupId, email) {
  const { data } = await axios.post('/user/create', { name, surname, groupId, email })

  return data
}

export async function redefineUserData(newData, userId) {
  const { data } = await axios.put('/user/manager-update', { newData, userId })

  return data
}

export async function editUserPrompts({ type, promptText, promptId }) {
  const { data } = await axios.put('/user/edit-prompt', { type, prompt: promptText, promptId })

  return data

}


export async function getUserUsage() {
  const { data } = await axios.get('/user/get-user-usage')

  return data
}
