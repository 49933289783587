import { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// Libs
import _ from 'lodash'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import pdfMake from 'pdfmake/build/pdfmake'
import download from 'downloadjs'

// Components
import { Button } from '../../../../components/DesignSystem'

// Styles
import './index.scss'

pdfMake.vfs = pdfFonts.pdfMake.vfs

function TranscriptText({ text, className, title = 'Transcrição' }) {
  const [isDownloadingTranscription, setIsDownloadingTranscription] = useState(false)

  const { highlightedTranscript } = useSelector(state => state.transcript)

  const highlightedTranscriptRef = useRef(null)

  useEffect(() => {
    if (highlightedTranscriptRef.current) {
      highlightedTranscriptRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [highlightedTranscript])

  function getPdfContent(name) {
    const messages = text.split('\n').map(item => {
      const speakerRegex = /(?<=\[\d{2,3}:\d{2}\]\s).+:(?=\s)/

      if (item.match(speakerRegex)) {
        const speaker = item.match(speakerRegex)[0]
        const speech = item.replace(speakerRegex, '')

        return {
          table: {
            dontBreakRows: false,
            body: [
              [
                {
                  text: speaker,
                  color: '#000000',
                  border: [false],
                  bold: true,
                },
              ],
              [
                {
                  text: speech,
                  color: '#000000',
                  border: [false],
                  alignment: 'justify',
                },
              ],
            ],
          },
          margin: [0, 20, 0, 0],
        }
      }
      return
    })

    const content = [
      {
        columns: [
          {
            width: 100,
            margin: [-14, 0, 0, 32],
            alignment: 'left',
            svg: `<svg width="619" height="160" viewBox="0 0 619 160" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M80.3772 160V140.546C100.725 140.546 117.3 123.97 117.3 103.623C117.3 83.2754 100.725 66.6997 80.3772 66.6997V47.3449C88.0198 47.3449 95.3648 48.8337 102.313 51.8114C109.062 54.6898 115.017 58.66 120.179 63.9206C125.34 69.0819 129.409 75.1365 132.288 81.7866C135.265 88.7345 136.754 96.0794 136.754 103.722C136.754 111.365 135.265 118.71 132.288 125.658C129.409 132.407 125.439 138.362 120.179 143.524C115.017 148.685 108.963 152.754 102.313 155.633C95.3648 158.511 88.0198 160 80.3772 160ZM80.3772 93.201C60.0298 93.201 43.4541 76.6253 43.4541 56.2779C43.4541 35.9305 60.0298 19.3548 80.3772 19.3548V0C72.7345 0 65.3896 1.48883 58.4417 4.4665C51.7916 7.24566 45.737 11.3151 40.5757 16.4764C35.4144 21.6377 31.3449 27.6923 28.4665 34.3424C25.4888 41.2903 24 48.6352 24 56.2779C24 63.9206 25.4888 71.2655 28.4665 78.2134C31.3449 84.9628 35.4144 90.9181 40.5757 96.0794C45.737 101.241 51.7916 105.31 58.5409 108.189C65.4888 111.166 72.8337 112.655 80.4764 112.655V93.201H80.3772Z" fill="#009688"/>
<path d="M185 91.25L198.5 83.35C201.4 90.85 207 95.65 217 95.65C226.5 95.65 229.8 91.65 229.8 87.15C229.8 81.15 224.4 78.85 212.5 75.45C200.2 71.85 188.3 66.75 188.3 51.45C188.3 36.35 200.9 27.55 214.7 27.55C227.9 27.55 238 34.35 243.4 45.55L230.1 53.25C227.2 47.15 222.9 42.95 214.7 42.95C208 42.95 204.1 46.35 204.1 50.95C204.1 55.85 207.2 58.55 219.4 62.15C232.1 66.15 245.7 70.45 245.7 86.85C245.7 101.85 233.7 111.05 216.6 111.05C200.1 111.15 189.3 103.25 185 91.25Z" fill="#3B3B3B"/>
<path d="M315.5 80.85C315.5 97.95 302.9 111.15 287.5 111.15C279.1 111.15 273 108.05 268.9 102.75V132.45H254.1V52.15H268.9V58.95C273 53.65 279.1 50.55 287.5 50.55C302.9 50.55 315.5 63.75 315.5 80.85ZM300.7 80.85C300.7 71.25 293.9 64.65 284.8 64.65C275.7 64.65 269 71.15 269 80.85C269 90.55 275.8 97.05 284.8 97.05C293.9 97.05 300.7 90.45 300.7 80.85Z" fill="#3B3B3B"/>
<path d="M353.5 97.75C359.2 97.75 363.8 95.35 366.3 92.05L378.2 98.95C372.8 106.75 364.2 111.25 353.2 111.25C333.9 111.25 321.8 98.05 321.8 80.95C321.8 63.85 334.1 50.65 352.1 50.65C369.1 50.65 381.1 64.05 381.1 80.95C381.1 83.15 380.9 85.05 380.5 87.05H337.3C339.3 94.45 345.7 97.75 353.5 97.75ZM366.3 75.45C364.5 67.15 358.3 63.85 352.1 63.85C344.2 63.85 338.8 68.05 337.1 75.45H366.3Z" fill="#3B3B3B"/>
<path d="M386.8 80.85C386.8 63.75 399.6 50.55 417.1 50.55C428.3 50.55 438.1 56.55 442.8 65.35L430.1 72.85C427.8 68.15 422.9 65.15 417 65.15C408.2 65.15 401.6 71.65 401.6 80.85C401.6 89.95 408.1 96.45 417 96.45C423 96.45 427.9 93.55 430.2 88.85L443 96.15C438.1 105.05 428.3 111.05 417.1 111.05C399.7 111.15 386.8 97.95 386.8 80.85Z" fill="#3B3B3B"/>
<path d="M463.3 66.35V90.25C463.3 96.45 467.8 96.55 476.3 96.15V109.55C455.5 111.85 448.5 105.75 448.5 90.25V40.55L463.3 36.05V52.15H476.3V66.35H463.3Z" fill="#3B3B3B"/>
<path d="M500.5 66.35V90.25C500.5 96.45 505 96.55 513.5 96.15V109.55C492.7 111.85 485.7 105.75 485.7 90.25V40.55L500.5 36.05V52.15H513.5V66.35H500.5Z" fill="#3B3B3B"/>
<path d="M549.7 97.75C555.4 97.75 560 95.35 562.5 92.05L574.4 98.95C569 106.75 560.4 111.25 549.4 111.25C530.1 111.25 518 98.05 518 80.95C518 63.85 530.3 50.65 548.3 50.65C565.3 50.65 577.3 64.05 577.3 80.95C577.3 83.15 577.1 85.05 576.7 87.05H533.5C535.6 94.45 541.9 97.75 549.7 97.75ZM562.6 75.45C560.8 67.15 554.6 63.85 548.4 63.85C540.5 63.85 535.1 68.05 533.4 75.45H562.6Z" fill="#3B3B3B"/>
<path d="M618.8 51.05V67.55C611.1 66.65 601.4 70.05 601.4 82.15V109.55H586.6V52.15H601.4V62.05C604.3 54.25 611.5 51.05 618.8 51.05Z" fill="#3B3B3B"/>
</svg>
            `,
          },
        ],
      },
      {
        layout: 'lightHorizontalLines',
        margin: [0, 0, 0, 2],
        table: {
          body: [
            [
              {
                text: 'Título do documento: ',
                fontSize: 18,
                color: '#000',
                alignment: 'left',
                margin: [0, 0, 0, 0],
                bold: true,
              },
            ],
          ],
        },
      },
      {
        layout: 'lightHorizontalLines',
        margin: [0, 0, 0, 30],
        table: {
          body: [
            [
              {
                text: name,
                fontSize: 16,
                color: '#000',
                alignment: 'left',
                margin: [0, 0, 0, 0],
              },
            ],
          ],
        },
      },

      ...messages,
    ]
    return content
  }

  async function handleClickDownloadChat(filename, name) {
    setIsDownloadingTranscription(true)
    const pdfFilename = filename.split('.').slice(0, -1).join('.') + '.pdf'

    let docDefinition = {
      pageSize: {
        width: 595.28,
        height: 841.89,
      },
      background: function () {
        return {
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 595.28,
              h: 841.89,
              color: '#ffffff',
            },
          ],
        }
      },
      content: getPdfContent(name),
    }

    async function generatePdf() {
      return new Promise((resolve, reject) => {
        pdfMake.createPdf(docDefinition).getBlob(result => {
          result.name = pdfFilename
          resolve(result)
        })
      })
    }

    const pdfFile = await generatePdf()
    download(pdfFile, pdfFilename)

    setIsDownloadingTranscription(false)
  }

  function returnParsedText() {
    let colors = [
      '#FFFFFF',
      '#64FEDA',
      '#FEFA1D',
      '#FF47CB',
      '#56EC1A',
      '#00F6FE',
      '#FFB200',
      '#939AFF',
      '#E6FF6A',
      '#498FFF',
      '#F82424',
      '#A3FF39',
      '#FF8787',
      '#9B19F5',
      '#FF8A00',
      '#A2C4F1',
      '#FFF5A1',
      '#FFC69D',
      '#1AE5B5',
    ]

    let participantColor = []
    let i = 0
    const parsedText = text.split('\n').map((line, index) => {
      const splitLine = line.replace(/\[\d{2}:\d{2}]/, '')?.split(':')
      const parsedLine = [...splitLine].slice(1).join(':').trim()
      const parsedHighlight = highlightedTranscript.replace(/\[\d{2}:\d{2}]/, '').trim()

      const regex = /\[([^\]]+)\]/g // Expressão regular para encontrar conteúdo entre [ ]
      let timeline = []
      let match

      while ((match = regex.exec(line))) {
        timeline.push(match[1])
      }

      let speaker = line?.split(']')[1]?.split(':')[0]
      let text = line?.split(']')[1]?.split(':')[1]?.split('[')[0]
      if (!participantColor[speaker]) {
        participantColor[speaker] = colors[i]
        i++
      }
      if (parsedLine && parsedHighlight.includes(parsedLine)) {
        return (
          <div
            className='transcript-text--content--message transcript-text--content--message__highlight'
            ref={highlightedTranscriptRef}
            key={`excerpt-${index}`}>
            <header style={{ color: participantColor[speaker] }}>{speaker}: </header>
            <span>{text}</span>
            <footer>
              {timeline[0]} - {timeline[1]}
            </footer>
          </div>
        )
      }

      if (text !== undefined) {
        return (
          <div className='transcript-text--content--message' key={`excerpt-${index}`}>
            <header style={{ color: participantColor[speaker] }}>{speaker}: </header>
            <span>{text}</span>
            <footer>
              {timeline[0]} - {timeline[1]}
            </footer>
          </div>
        )
      }
    })
    return parsedText
  }

  return (
    <article className={`transcript-text  transcript__section--sticky ${className}`}>
      <header className='transcript-text--heading'>
        <div>
          <h3>{title}</h3>
          <p>Texto completo da transcrição do áudio</p>
        </div>
        <div className='chat__section__header'>
          <Button
            icon='DownloadCloud'
            sizeIcon={16}
            bordered
            disabled={isDownloadingTranscription}
            onClick={() => handleClickDownloadChat(title, title)}
            contained>
            Baixar transcrição
          </Button>
        </div>
      </header>
      <div className='transcript-text--content'>{returnParsedText()}</div>
    </article>
  )
}

export default TranscriptText
