import _ from 'lodash'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { ReactComponent as Sparkle } from '../../../../assets/sparkle.svg'

import './index.scss'
import { summarizeFile } from '../../../../services/readDocsServices'

function FileSummary({ fileData }) {
  const [isSummarizing, setIsSummarizing] = useState(false)

  const { id } = useParams()

  const queryClient = useQueryClient()

  async function handleSummarizeFile() {
    try {
      setIsSummarizing(true)

      await summarizeFile({ readDocsId: id, fileId: fileData.openAiId })

      queryClient.invalidateQueries(['document-download'])

      toast.success('Resumo gerado com sucesso. Em breve você poderá visualizá-lo.')
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro. Tente novamente mais tarde.')
    } finally {
      setIsSummarizing(false)
    }
  }

  if (_.isEmpty(fileData)) {
    return (
      <section className='file-summary'>
        <h3>Resumo automatizado indisponível para chats legados</h3>
      </section>
    )
  }

  return (
    <>
      {fileData?.summary ? (
        <section className='file-summary'>
          <ReactMarkdown remarkPlugins={[remarkGfm]} className='markdown__container'>
            {fileData.summary}
          </ReactMarkdown>
        </section>
      ) : (
        <section className='file-summary'>
          <div className='file-summary--info'>
            <h3>O documento ainda não possui resumo.</h3>
            <p>Gostaria de gerar um resumo seguindo as normas de linguagem simples do Brasil?</p>
          </div>
          <button
            type='button'
            className={`summary__button ${isSummarizing ? 'summary__button--animated' : ''}`}
            disabled={isSummarizing}
            onClick={handleSummarizeFile}>
            <div className='sparkle__container'>
              <Sparkle className='sparkle minor-sparkle--up' />
              <Sparkle className='sparkle main-sparkle' />
              <Sparkle className='sparkle minor-sparkle--down' />
            </div>
            {isSummarizing ? 'Resumindo...' : 'Gerar resumo'}
          </button>
        </section>
      )}
    </>
  )
}

export default FileSummary
