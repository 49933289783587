import React, { useState } from 'react'

//components
import Select from '../../../../components/DesignSystem/Select'
import Input from '../../../../components/DesignSystem/Input'
import DialogAlert from '../../../../components/AlertDialogDemo'

// validate
import { Button } from '../../../../components/DesignSystem'

function Form({
  createContract,
  returnSelectOptions,
  setSelectedContract,
  selectedContract,
  contractor,
  setContractor,
  hired,
  setHired,
  returnContractFields,
  returnInputs,
}) {
  const [isOpenModal, setIsOpenModal] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()
    setIsOpenModal(true)
  }

  return (
    <form onSubmit={e => handleSubmit(e)}>
      <DialogAlert
        title={'Confimar envio'}
        description={'Verifique se os dados estão preenchidos corretamente antes de confirmar.'}
        cancelButton={'Cancelar'}
        confirmButton={'Confirmar'}
        modalOpen={isOpenModal}
        setModalOpen={() => {
          setIsOpenModal(!isOpenModal)
        }}
        confirmAction={createContract}
      />
      <Select
        options={returnSelectOptions()}
        label='Tipo de contrato'
        onChange={setSelectedContract}
        value={selectedContract}
        required={true}
      />
      <Input
        placeholder='Contratante'
        label='Contratante'
        value={contractor}
        onChange={setContractor}
        required={true}
      />
      <Input
        placeholder='Contratado(a)'
        label='Contratado(a)'
        value={hired}
        onChange={setHired}
        required={true}
      />
      {returnContractFields().map(item => {
        return returnInputs(item)
      })}
      <Button
        contained={true}
        fullWidth={true}
        type='submit'
        disabled={contractor === '' || hired === '' || selectedContract === ''}>
        Confirmar
      </Button>
    </form>
  )
}

export default Form
