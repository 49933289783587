import React, { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import _ from 'lodash'

// Services
import { getDocumentList } from '../../services/readDocsServices'
import { uploadMediaDocument } from '../../services/transcriptionServices'

// Utils
import { formatIsoDate, returnListCounterText } from '../../constants/others'
import { formatBytes } from '../../constants/validate'

// Components
import { Button, Input } from '../../components/DesignSystem'
import HeaderFeatures from '../../components/HeaderFeatures'
import Spinner from '../../components/Spinner'
import DocumentItem from '../../components/DocumentItem'
import Pagination from '../../components/Pagination'
import DynamicLoading from '../../components/DynamicLoading'
import CustomTooltip from '../../components/CustomTooltip'

// Styles
import './index.scss'

function TranscriptsList() {
  const {
    user: { token, status },
  } = useSelector(state => state.app)
  const { page } = useParams()
  const navigate = useNavigate()

  const headerRef = useRef(null)
  const inputFileRef = useRef(null)

  const [documentSearch, setDocumentSearch] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [progress, setProgress] = useState({ value: 0, message: 'Enviando transcrição...' })

  const MAX_FILE_SIZE = 500 * 1024 * 1024
  const queryClient = useQueryClient()
  const { isLoading, isFetching, data } = useQuery({
    queryKey: ['transcripts-list', page, documentSearch],
    queryFn: ({ signal }) => fetchDocuments(signal),
    staleTime: 1000 * 30,
    enabled: !_.isEmpty(token),
    refetchOnWindowFocus: true,
    retry: false,
  })

  async function fetchDocuments(signal) {
    try {
      const { data, meta } = await getDocumentList({
        page,
        documentSearch,
        type: 'transcript',
        signal,
      })

      return { documents: data.documents, totalPages: meta.totalPages, totalDocs: meta.total }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log(error)
        const errorContent = JSON.stringify(error)
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
            <Button
              icon='Copy'
              onlyIcon
              onClick={() => {
                navigator.clipboard.writeText(errorContent)
                toast.success('Erro copiado para área de transferência')
              }}
            />
          </div>
        )
      }
    }
  }

  async function handleUploadDocument(file) {
    setIsUploading(true)
    let formData = new FormData()
    formData.append('file', file)
    try {
      let { data } = await uploadMediaDocument({ formData, setProgress })
      setProgress({ value: 100, message: 'Arquivo adicionado à fila com sucesso' })
      await new Promise(resolve => setTimeout(resolve, 1000))
      toast.success(data.message)
      queryClient.invalidateQueries(['transcripts-list'])
      queryClient.invalidateQueries(['usage-quantity'])
    } catch (error) {
      console.log(error.response?.data?.errors[0]?.message)
      const errorContent = JSON.stringify(error)
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
          <Button
            icon='Copy'
            onlyIcon
            onClick={() => {
              navigator.clipboard.writeText(errorContent)
              toast.success('Erro copiado para área de transferência')
            }}
          />
        </div>
      )
    } finally {
      setIsUploading(false)
      setProgress({ value: 0, message: 'Enviando transcrição...' })
    }
  }

  function handleSelectFile(e) {
    const supportedMimeTypes = ['video/mp4', 'audio/mp3', 'video/webm', 'audio/wav', 'audio/ogg', 'audio/mpeg', 'audio/opus']

    const file = e.target?.files[0]
    if (file?.size >= MAX_FILE_SIZE) {
      toast.error(`O arquivo excedeu o tamanho de ${formatBytes(MAX_FILE_SIZE)}`)
      return
    }

    if (!supportedMimeTypes.includes(file?.type)) {
      toast.error('Formato de arquivo não permitido')
      return
    }
    handleUploadDocument(file)
    inputFileRef.current.value = null
  }

  function handleSearch(value) {
    setDocumentSearch(value)
    navigate('/transcricoes')
  }

  function handleChangePageNumber(pageNumber) {
    handleScrollToTop()
    navigate(`/transcricoes/${pageNumber}`)
  }

  function handleScrollToTop() {
    headerRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  function returnListItemsClassName() {
    let className = 'transcripts-list__items'
    if (isLoading) className += ' transcripts-list__items--loading'
    if (!isLoading && _.isEmpty(data?.documents)) className += ' transcripts-list__items--empty'

    return className
  }

  function returnRefreshInfoClassName() {
    let className = 'transcripts-list__refresh-info'
    if (!isLoading && isFetching) className += ' transcripts-list__refresh-info--visible'

    return className
  }

  function returnType(data) {
    switch (data) {
      case 'queued':
        return { text: 'Transcrevendo', icon: 'Clock' }
      case 'waiting-speakers':
        return { text: 'Identificar falantes', icon: 'Speech' }
      default:
        return { text: 'Sucesso', icon: 'Check' }
    }
  }

  return (
    <>
      <HeaderFeatures />
      <div className='transcripts-list'>
        <section className='transcripts-list__info' ref={headerRef}>
          <div>
            <h1>Transcrição de áudio e vídeo</h1>
            <p>Acesse todas suas transcrições</p>
          </div>
          {isUploading && <DynamicLoading percent={progress.value} loadingMessage={progress.message} />}
          <input
            type='file'
            ref={inputFileRef}
            hidden
            onChange={handleSelectFile}
            accept='.mp4, .mp3, .webm, .wav, .ogg'
            size={MAX_FILE_SIZE}
            multiple={false}
          />
          {status !== 'paid' ? (
            <CustomTooltip
              description={
                'Você não possui um plano ativo. Para contratar basta ir em "Configurações" e preencher os dados do seu cartão.'
              }
              name='Assinatura necessária'>
              <Button
                loading={isUploading}
                outlined
                icon='ListVideo'
                onClick={() => inputFileRef.current.click()}
                style={{
                  borderRadius: 0,
                  textTransform: 'uppercase',
                  fontSize: 10,
                  fontWeight: 700,
                  letterSpacing: 2,
                  color: 'white',
                }}
                disabled={status !== 'paid'}>
                Nova transcrição
              </Button>
            </CustomTooltip>
          ) : (
            <Button
              loading={isUploading}
              outlined
              icon='ListVideo'
              onClick={() => inputFileRef.current.click()}
              style={{
                borderRadius: 0,
                textTransform: 'uppercase',
                fontSize: 10,
                fontWeight: 700,
                letterSpacing: 2,
                color: 'white',
              }}
              disabled={status !== 'paid'}>
              Nova transcrição
            </Button>
          )}
        </section>
        <div className='transcripts-list__divider' />
        <section className='transcripts-list__listing-section'>
          <div className='transcripts-list__listing-section__search'>
            <Input
              withDebounce
              placeholder='Nome do documento'
              label='Nome do documento'
              value={documentSearch}
              onChange={handleSearch}
              icon={'Search'}
            />
          </div>
          <div>
            <div className='transcripts-list__listing-section__header'>
              {returnListCounterText(data, page)}
            </div>
            <div className={returnRefreshInfoClassName()}>
              <p>Sincronizando dados</p>
              <Spinner size={10} />
            </div>
          </div>
          <div className={returnListItemsClassName()}>
            {isLoading && <Spinner />}
            {!isLoading && _.isEmpty(data?.documents) && (
              <p className='transcripts-list__empty-message'>Nenhum documento encontrado</p>
            )}
            {!isLoading &&
              !_.isEmpty(data?.documents) &&
              data.documents.map(document => {
                const { text, icon } = returnType(document.status)
                return (
                  <DocumentItem
                    icon='FileAudio2'
                    editable
                    key={document._id}
                    documentId={document._id}
                    documentName={document.name}
                    uploadDate={formatIsoDate(document.createdAt)}
                    chat={document.chat}
                    path='transcricao'
                    disabled={document.status === 'queued'}
                    status={document.status}
                    lastItem={{ value: text, icon, label: 'Status' }}
                  />
                )
              })}
            {!isLoading && !_.isEmpty(data?.documents) && (
              <Pagination
                currentPage={page}
                totalPages={data ? data.totalPages : 0}
                onClickAction={handleChangePageNumber}
              />
            )}
          </div>
        </section>
      </div>
      <ReactQueryDevtools />
    </>
  )
}

export default TranscriptsList
