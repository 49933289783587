import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ReactComponent as Star } from '../../assets/star.svg'

// Services
import { editUserPrompts } from '../../services/authServices'

// Components
import { Button } from '../DesignSystem'

function AddPromptFavorites({ promptText }) {
  const { user } = useSelector(state => state.app)
  const dispatch = useDispatch()

  const [updatingPrompts, setUpdatingPrompts] = useState(false)

  const prompts = user?.prompts || []
  const isFavorite = prompts.find(prompt => prompt.text === promptText)

  async function handleAddPrompt() {
    setUpdatingPrompts(true)
    try {
      const { data } = await editUserPrompts({ type: 'add', promptText })

      const newUser = { ...user, prompts: data.prompts }

      dispatch({ type: 'CHANGE_USER', payload: newUser })
      localStorage.setItem('user', JSON.stringify(newUser))

      setUpdatingPrompts(false)

      toast.success('Prompt adicionado aos favoritos')
    } catch (error) {
      console.error(error)
      setUpdatingPrompts(false)
      const errorContent = JSON.stringify(error)
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
          <Button
            icon='Copy'
            onlyIcon
            onClick={() => {
              navigator.clipboard.writeText(errorContent)
              toast.success('Erro copiado para área de transferência')
            }}
          />
        </div>
      )
    }
  }

  if (isFavorite) {
    return (
      <Button
        rounded
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          height: 24,
          width: 24,
          padding: 0,
          marginRight: 4,
          marginTop: 4,
          pointerEvents: 'none',
        }}>
        <Star style={{ width: 16, height: 16, fill: '#64feda', stroke: 'transparent' }} />
      </Button>
    )
  }

  return (
    <Button
      onlyIcon
      icon={updatingPrompts ? 'Clock' : 'Star'}
      disabled={updatingPrompts}
      sizeIcon={16}
      rounded
      onClick={handleAddPrompt}
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        height: 24,
        width: 24,
        padding: 0,
        marginRight: 4,
        marginTop: 4,
      }}
    />
  )
}

export default AddPromptFavorites
