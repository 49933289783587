import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Input } from '../../components/DesignSystem'

import { redefinePassword, verifyRecoveryPassword } from '../../services/authServices'

import './index.scss'
import { toast } from 'react-toastify'
import { validatePassword } from '../../constants/validate'

export default function RedefinePassword() {
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { hash } = useParams()

  useEffect(() => {
    const abortController = new AbortController()

    async function verifyPassword() {
      const hashDecoded = window.atob(hash)
      const [email, password] = hashDecoded.split('/')
      try {
        const { data } = await verifyRecoveryPassword(email, password, abortController)
        localStorage.setItem('user', JSON.stringify(data))
        dispatch({ type: 'CHANGE_USER', payload: data })
        axios.defaults.headers.common['authentication'] = data.token
      } catch (error) {
        if (error.code !== 'ERR_CANCELED') {
          navigate('/login')
        }
        toast.error('Link inválido')
        console.log(error)
      }
    }

    if (!hash) {
      navigate('/login')
    } else {
      verifyPassword()
    }

    return () => {
      abortController.abort()
    }
  }, [dispatch, navigate, hash])

  async function handleChangePassword(event) {
    event.preventDefault()

    if (!newPassword) {
      toast.error('Senha obrigatória.')
    } else if (newPassword !== newPasswordConfirmation) {
      toast.error('Senhas não coincidem.')
    } else if (!validatePassword(newPassword)) {
      toast.error(
        'Sua senha deve ter no mínimo 8 caracteres contendo letras maiúsculas e minúsculas, números e caracteres especiais.',
      )
    } else {
      try {
        await redefinePassword(newPassword)
        navigate('/')
      } catch (error) {
        console.log(error)
        const errorContent = JSON.stringify(error)
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
            <Button
              icon='Copy'
              onlyIcon
              onClick={() => {
                navigator.clipboard.writeText(errorContent)
                toast.success('Erro copiado para área de transferência')
              }}
            />
          </div>,
        )
      }
    }
  }

  return (
    <div className='redefine-password'>
      <h1>Redefinir senha</h1>
      <form onSubmit={handleChangePassword}>
        <Input
          label='Nova senha'
          value={newPassword}
          type='password'
          placeholder='Digite sua nova senha'
          onChange={value => setNewPassword(value)}
        />
        <Input
          label='Confirmar senha'
          value={newPasswordConfirmation}
          type='password'
          placeholder='Confirme sua nova senha'
          onChange={value => setNewPasswordConfirmation(value)}
        />
        <Button contained fullWidth type='submit'>
          Redefinir senha
        </Button>
        <span onClick={() => navigate('/')}>Redefinir depois</span>
      </form>
    </div>
  )
}
