import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import Spinner from '../../../../components/Spinner'
import PdfController from '../PdfController'
import PdfPlaceholder from '../PdfPlaceholder'

import './index.scss'
import Tabs from '../../../../components/DesignSystem/Tabs'
import FileSummary from '../FileSummary'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function PdfViewer({ pdfFile, fileData }) {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [loadingPage, setLoadingPage] = useState(true)
  const [renderedPageNumber, setRenderedPageNumber] = useState(null)
  const [renderedDocument, setRenderedDocument] = useState('PDF')

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages)
  }

  function handlePageChange(direction) {
    if (
      (direction === 'next' && pageNumber !== numPages) ||
      (direction === 'prev' && pageNumber !== 1)
    ) {
      setLoadingPage(true)
    }
    if (direction === 'next') {
      const value = pageNumber + 1
      if (value > numPages) return setPageNumber(numPages)
      return setPageNumber(value)
    }
    if (direction === 'prev') {
      const value = pageNumber - 1
      if (value < 1) return setPageNumber(1)
      return setPageNumber(value)
    }
    if (direction.target.value > numPages) return setPageNumber(numPages)
    if (direction.target.value < 1) return setPageNumber('')
    setPageNumber(parseInt(direction.target.value, 10))
  }

  return (
    <div className='pdf__viewer'>
      <Tabs
        list={[
          { label: 'PDF', action: () => setRenderedDocument('PDF') },
          { label: 'Resumo', action: () => setRenderedDocument('Summary') },
        ]}
      />
      <div style={renderedDocument === 'PDF' ? {} : { display: 'none' }}>
        <PdfController current={pageNumber} total={numPages} handlePageChange={handlePageChange} />
        <Document
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<Spinner size={24} />}>
          <div className='pdf__framer'>
            {loadingPage && renderedPageNumber ? (
              <Page
                key={renderedPageNumber}
                pageNumber={renderedPageNumber}
                width={window.innerWidth > 960 ? 472 : window.innerWidth - 96}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                className='pdf__framer'
                loading={
                  <div className='pdf__framer__loading'>
                    <Spinner size={24} />
                  </div>
                }
              />
            ) : null}
            <Page
              onRenderSuccess={() => {
                setLoadingPage(false)
                setRenderedPageNumber(pageNumber)
              }}
              key={pageNumber}
              pageNumber={pageNumber}
              renderTextLayer={false}
              width={window.innerWidth > 960 ? 472 : window.innerWidth - 96}
              renderAnnotationLayer={false}
              className={!loadingPage ? 'pdf__framer' : 'loading-page'}
              loading={
                <div className='pdf__framer__loading'>
                  <Spinner size={24} />
                </div>
              }
              noData={
                <PdfPlaceholder message={'A página indicada não foi encontrada no documento.'} />
              }
              error={
                <PdfPlaceholder
                  message={'Ocorreu um erro ao carregar o documento. Por favor, tente novamente.'}
                />
              }
            />
          </div>
        </Document>
      </div>
      <div style={renderedDocument === 'Summary' ? {} : { display: 'none' }}>
        <FileSummary fileData={fileData} />
      </div>
    </div>
  )
}
