import React, { useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import DropDown from '../../components/DropDown'
import { Button, Input } from '../../components/DesignSystem'

// Components
import DocumentItem from '../../components/DocumentItem'
import Pagination from '../../components/Pagination'
import Spinner from '../../components/Spinner'
import HeaderFeatures from '../../components/HeaderFeatures'
import CustomIcon from '../../components/CustomIcon'

// Services
import { getDocumentsList } from '../../services/documentsServices'

// Utils
import { formatIsoDate, returnListCounterText } from '../../constants/others'

import './index.scss'
import CustomTooltip from '../../components/CustomTooltip'

export default function DocumentsList() {
  const {
    user: { token, status },
  } = useSelector(state => state.app)
  const { page } = useParams()
  const navigate = useNavigate()
  const headerRef = useRef(null)

  const [documentSearch, setDocumentSearch] = useState('')

  const { isLoading, isFetching, data } = useQuery({
    queryKey: ['documents-list', page, documentSearch],
    queryFn: ({ signal }) => fetchDocuments(signal),
    staleTime: 1000 * 30,
    enabled: !_.isEmpty(token),
    refetchOnWindowFocus: true,
    retry: false,
  })

  function navigateTo(path) {
    navigate(path)
  }

  function handleSearch(value) {
    setDocumentSearch(value)
    navigate('/lista-criar-documentos/')
  }

  async function fetchDocuments(signal) {
    try {
      const { data, meta } = await getDocumentsList(page, documentSearch, signal)

      return { documents: data.documents, totalPages: meta.totalPages, totalDocs: meta.total }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log(error)
        const errorContent = JSON.stringify(error)
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
            <Button
              icon='Copy'
              onlyIcon
              onClick={() => {
                navigator.clipboard.writeText(errorContent)
                toast.success('Erro copiado para área de transferência')
              }}
            />
          </div>,
        )
      }
    }
  }

  function handleChangePageNumber(pageNumber) {
    handleScrollToTop()
    navigate(`/lista-criar-documentos/${pageNumber}`)
  }

  function handleScrollToTop() {
    headerRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  function returnListItemsClassName() {
    let className = 'documents-list__items'
    if (isLoading) className += ' documents-list__items--loading'
    if (!isLoading && _.isEmpty(data?.documents)) className += ' documents-list__items--empty'

    return className
  }

  function returnRefreshInfoClassName() {
    let className = 'documents-list__refresh-info'
    if (!isLoading && isFetching) className += ' documents-list__refresh-info--visible'

    return className
  }

  function returnType(data) {
    switch (data) {
      case 'contract':
        return 'Contrato'
      case 'notification':
        return 'Notificação'
      case 'complaint':
        return 'Petição'
      default:
        return 'Documento'
    }
  }
  return (
    <>
      <HeaderFeatures />
      <div className='documents-list'>
        <section className='documents-list__info' ref={headerRef}>
          <div>
            <h1>Criação de documentos</h1>
            <p>Acesse todos os seus documentos criados</p>
          </div>
          {status !== 'paid' ? (
            <CustomTooltip
              description={
                'Você não possui um plano ativo. Para contratar basta ir em "Configurações" e preencher os dados do seu cartão.'
              }
              name='Assinatura necessária'>
              <div className='documents-list__new-document'>
                <DropDown
                  className='header__nav__dropdown'
                  container={headerRef.current}
                  options={[
                    {
                      label: '<strong>Petições</strong>',
                      action: () => {
                        navigateTo('/peticao')
                      },
                      icon: 'FilePenLine',
                    },
                    {
                      label: '<strong>Contratos</strong>',
                      action: () => {
                        navigateTo('/contratos')
                      },
                      icon: 'FileText',
                    },
                    {
                      label: '<strong>Notificação</strong>',
                      action: () => {
                        navigateTo('/notifica')
                      },
                      icon: 'Mail',
                    },
                  ]}
                  disabled={status !== 'paid'}>
                  <CustomIcon icon='FilePlus' size={20} /> Novo documento
                </DropDown>
              </div>
            </CustomTooltip>
          ) : (
            <div className='documents-list__new-document'>
              <DropDown
                className='header__nav__dropdown'
                container={headerRef.current}
                options={[
                  {
                    label: '<strong>Petições</strong>',
                    action: () => {
                      navigateTo('/peticao')
                    },
                    icon: 'FilePenLine',
                  },
                  {
                    label: '<strong>Contratos</strong>',
                    action: () => {
                      navigateTo('/contratos')
                    },
                    icon: 'FileText',
                  },
                  {
                    label: '<strong>Notificação</strong>',
                    action: () => {
                      navigateTo('/notifica')
                    },
                    icon: 'Mail',
                  },
                ]}
                disabled={status !== 'paid'}>
                <CustomIcon icon='FilePlus' size={20} /> Novo documento
              </DropDown>
            </div>
          )}
        </section>
        <div className='documents-list__divider' />
        <section className='documents-list__listing-section'>
          <div className='documents-list__listing-section__search'>
            <Input
              withDebounce
              placeholder='Nome do documento'
              label='Nome do documento'
              value={documentSearch}
              onChange={handleSearch}
              icon={'Search'}
            />
          </div>
          <div>
            <div className='documents-list__listing-section__header'>
              {/* <span>
                <h6>Listagem de documentos</h6>
                <p>Acesse todos os seus documentos e suas respectivas conversas</p>
              </span> */}
              {returnListCounterText(data, page)}
            </div>
            <div className={returnRefreshInfoClassName()}>
              <p>Sincronizando dados</p>
              <Spinner size={10} />
            </div>
          </div>
          <div className={returnListItemsClassName()}>
            {isLoading && <Spinner />}
            {!isLoading && _.isEmpty(data?.documents) && (
              <p className='documents-list__empty-message'>Nenhum documento encontrado</p>
            )}
            {!isLoading &&
              !_.isEmpty(data?.documents) &&
              data.documents.map(document => (
                <DocumentItem
                  icon='FilePen'
                  editable
                  key={document._id}
                  documentId={document.type === 'complaint' ? document.complaintId : document._id}
                  documentName={document.name}
                  uploadDate={formatIsoDate(document.createdAt)}
                  chat={document.chat}
                  lastItem={{ value: returnType(document.type), label: 'Tipo' }}
                  legacy={document.legacy}
                  path={document.type === 'complaint' ? 'peticao' : 'documento'}
                />
              ))}
            {!isLoading && !_.isEmpty(data?.documents) && (
              <Pagination
                currentPage={page}
                totalPages={data ? data.totalPages : 0}
                onClickAction={handleChangePageNumber}
              />
            )}
          </div>
        </section>
      </div>
      <ReactQueryDevtools />
    </>
  )
}
