import { composeWithDevTools } from '@redux-devtools/extension'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter as Router } from 'react-router-dom'
import axios from 'axios'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import App from './App'
import env from './env'
import reducers from './reducers/reducers'

const root = ReactDOM.createRoot(document.getElementById('root'))

if (env === 'production') {
  axios.defaults.baseURL = 'https://server.spectter.com.br'
} else {
  axios.defaults.baseURL = 'http://localhost:3001'
}

// Logout when unauthorized
let user = localStorage.getItem('user')
if (typeof user === 'string') {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 401) {
        localStorage.removeItem('user')
        window.location.href = '/login'
      }
      return Promise.reject(error)
    }
  )
}

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)))
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 1000,
      cacheTime: 24 * 60 * 60 * 1000,
    },
  },
})

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <App />
      </Router>
    </QueryClientProvider>
  </Provider>
)
