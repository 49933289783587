import axios from 'axios'
import env from '../env'

export async function getContracts() {
  const { data } = await axios.get('/documents/get-contracts')

  return data
}

export async function getDocument(id, token) {
  let url = 'https://server.spectter.com.br'
  if (env !== 'production') {
    url = 'http://localhost:3001'
  }

  const config = {
    headers: {
      authentication: token,
    },
  }

  try {
    const { data } = await axios.get(`${url}/documents/get-document/${id}`, config)
    return data.data
  } catch (error) {
    console.log(error)
  }
}

export async function createDocument(
  prompt,
  type,
  documentId,
  complement,
  token,
  setResult,
  setDocumentId,
) {
  let url = 'https://server.spectter.com.br'
  if (env !== 'production') {
    url = 'http://localhost:3001'
  }

  const response = await fetch(
    `${url}/documents/create-document?prompt=${complement ? complement : prompt}&type=${type}${
      documentId ? `&documentId=${documentId}` : ''
    }`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'text/event-stream',
        authentication: token,
      },
    },
  )

  let documentStream = ''
  const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
  while (true) {
    const { value, done } = await reader.read()

    if (done) {
      break
    }

    let separatedValue = value.split('#separator#')

    if (separatedValue.length === 1) {
      setResult(prev => prev + separatedValue[0])
      documentStream += separatedValue[0]
    } else {
      let newDocumentId = separatedValue[0].replace('documentId: ', '')
      if (setDocumentId) {
        setDocumentId(newDocumentId)
      }
      setResult(prev => prev + separatedValue[1])
      documentStream += separatedValue[1]
    }
  }
}

export async function getAddressByCEP(CEP) {
  try {
    const { data } = await axios.get(`/documents/getAddress/${CEP}`)
    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export async function getDocumentsList(page, documentSearch, signal) {
  const { data } = await axios.get('/documents/list', {
    params: {
      page,
      documentSearch,
    },
    signal,
  })

  return data
}

export async function renameDocument(body) {
  const { data } = await axios.put(`/documents/rename-document`, body)

  return data
}

export async function inactivateDocument(body) {
  const data = await axios.delete('/documents/inactivate', {
    data: body,
  })

  return data
}

export async function generateComplaintArguments(body) {
  const { data } = await axios.post('/complaints/generate-arguments', { data: body })

  return data
}

export async function getComplaintDetails(complaintId) {
  const { data } = await axios.get(`/complaints/details/${complaintId}`)

  return data
}

export async function addArgumentToComplaint(complaintId, argumentData) {
  const { data } = await axios.patch(`/complaints/add-argument/${complaintId}`, argumentData)

  return data
}

export async function editComplaintArgument({ complaintId, argumentId, title, thesis }) {
  const { data } = await axios.patch(`/complaints/edit-argument/${complaintId}`, {
    title,
    thesis,
    argumentId,
  })

  return data
}

export async function deleteComplaintArgument({ complaintId, argumentId }) {
  const { data } = await axios.delete(`/complaints/delete-argument/${complaintId}/${argumentId}`)

  return data
}

export async function updateComplaintFullContent({
  complaintId,
  complaintIndex = 0,
  instructions,
}) {
  const { data } = await axios.put(
    `/complaints/update-full-content/${complaintId}/${complaintIndex}`,
    {
      instructions,
    },
  )

  return data
}

export async function getComplaintFileURL({ complaintId, complaintIndex }) {
  const { data } = await axios.post(`/complaints/create-file/${complaintId}/${complaintIndex}`)

  return data
}
