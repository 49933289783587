import { Link } from 'react-router-dom'
import './index.scss'

function Footer() {
  return (
    <footer className='privacy__footer'>
      <Link to='/aviso-de-privacidade' target='_blank'>
        Aviso de Privacidade
      </Link>
      <Link to='/termos-de-uso' target='_blank'>
        Termos de Uso
      </Link>
    </footer>
  )
}

export default Footer
