import React, { useEffect, useRef, useState } from 'react'
import DropDown from '../../DropDown'
import { Mic, Pause } from 'lucide-react'
import Spinner from '../../Spinner'

import './index.scss'

/**
 * @param {String} value - Input value
 * @param {String} label - The label
 * @param {String} placeholder - Text that appears in the input when it has no value set
 * @param {String} type - Type of input
 * @param {Boolean} required - Flag indicating required
 * @param {String} name - Input name
 * @param {Boolean} error - Flag indicating error
 * @param {Boolean} disabled - Flag indicating disabled
 * @param {Number} maxLength - Maximum number of characters of value
 * @param {Boolean} autoComplete - Enable/disable browser autocomplete feature
 * @param {Boolean} resizeVertical - Defines if an element is vertically resizable
 * @param {Boolean} resizeHorizontal - Defines whether an element is horizontally resizable
 * @param {Boolean} hasLabel - Defines if textarea has a label
 * @param {Boolean} autoResize - Defines if the textarea is resizable
 * @param {Boolean} bgColorSeparator - Separator color
 * @param {Function} onBlur - Callback on blur
 * @param {Function} onChange - Callback on change
 * @param {Function} onPressEnter - Function to be called when pressing enter
 * @param {Function} onInvalid - Callback on invalid
 * @param {Boolean} shouldListen - Flag indicating whether to listen
 * @param {Boolean} isListening - Flag indicating whether it is listening
 * @param {Function} startListeningFunction - Function that will be called when starting to listen
 * @param {Function} stopListeningFunction - Function that will be called when stopping listening
 * @param {Boolean} isTranscribing - Flag indicating whether it is transcribing
 * @param {Function} transcribeAudioFunction - Function that will be called when transcribing audio
 * @param {Object}  style - Styling object
 * @param {React.ReactElement} children - Children
 *
 * @returns {React.ReactElement}
 */

const TextArea = ({
  value = '',
  label = 'Sample',
  placeholder = 'Sample',
  required = false,
  name,
  error = false,
  disabled = false,
  maxLength = 1000,
  autoComplete = true,
  resizeVertical = false,
  resizeHorizontal = false,
  hasLabel = false,
  autoResize = false,
  bgColorSeparator = 'transparent',
  separatorColor = 'transparent',
  onBlur = () => true,
  onChange = () => {},
  onPressEnter = () => {},
  shouldListen,
  isListening,
  startListeningFunction = () => {},
  stopListeningFunction = () => {},
  isTranscribing,
  transcribeAudioFunction = () => {},
  style,
  children,
}) => {
  const [isFocus, setIsFocus] = useState(false)
  const [hasError, setHasError] = useState(error)
  const [hasValue, setHasValue] = useState(false)
  const [listeningOptions, setListeningOptions] = useState([
    {
      label: 'Subir arquivo de áudio',
      action: () => {},
      icon: 'Upload',
    },
  ])
  const textAreaRef = useRef(null)
  const recognitionRef = useRef(null)

  useEffect(() => setHasError(error), [error])
  useEffect(() => {
    if (shouldListen) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition

      if (SpeechRecognition) {
        recognitionRef.current = new SpeechRecognition()
        setListeningOptions([
          {
            label: 'Subir arquivo de áudio',
            action: () => transcribeAudioFunction(),
            icon: 'Upload',
          },
          {
            label: 'Ativar microfone',
            action: startListeningFunction,
            icon: 'Mic',
          },
        ])
      } else {
        setListeningOptions([
          {
            label: 'Subir arquivo de áudio',
            action: transcribeAudioFunction,
            icon: 'Upload',
          },
        ])
      }
    }
  }, [shouldListen])

  function handleOnBlur(evt) {
    const value = evt.target.value
    if (onBlur) {
      const response = onBlur(value)
      if (response === false) {
        setHasError(true)
        textAreaRef.current.setCustomValidity('Valor inválido')
      }
    }
    setIsFocus(false)
  }

  function handleOnChange(evt) {
    const value = evt.target.value

    onChange && onChange(value)
    setHasError(error)
    textAreaRef.current.setCustomValidity('')
  }

  function handlePressEnter(evt) {
    if (evt.key === 'Enter') onPressEnter && onPressEnter()
  }

  function returnLabelClassName() {
    let currentClassName = 'textarea__label'
    if (isFocus || hasValue || value) currentClassName += ' textarea__label--open'
    if (isFocus) currentClassName += ' textarea__label--focus'
    return currentClassName
  }

  function returnClassName() {
    let currentClassName = 'textarea__wrapper'
    if (hasError) currentClassName += ' textarea__wrapper--error'
    if (disabled) currentClassName += ' textarea__wrapper--disabled'
    if (resizeVertical) currentClassName += ' textarea__wrapper--resize-vertical'
    if (resizeHorizontal) currentClassName += ' textarea__wrapper--resize-horizontal'
    if (!hasLabel) currentClassName += ' textarea__wrapper--without-label'
    if (autoResize) currentClassName += ' textarea__wrapper--auto-resize'
    return currentClassName
  }

  function returnListeningComponent() {
    if (shouldListen) {
      if (isTranscribing) {
        return (
          <button type='button' disabled className='mic-button'>
            <Spinner size={20} color='#64FEDA'/>
          </button>
        )
      }
      if (!isListening) {
        return (
          <DropDown options={listeningOptions} className='listening__dropdown' noArrow>
            <Mic size={24} color='#64FEDA' />
          </DropDown>
        )
      }
      return (
        <button
          type='button'
          onClick={isListening ? stopListeningFunction : startListeningFunction}
          className={isListening ? 'mic-button mic-button--active' : 'mic-button'}>
          {isListening ? <Pause size={24} color='#64FEDA' /> : <Mic size={24} color='#64FEDA' />}
        </button>
      )
    }
  }

  return (
    <div className={returnClassName()}>
      {hasLabel && (
        <span className={returnLabelClassName()}>
          {label}
          {required && ' *'}
        </span>
      )}
      <div className='textarea__wrapper__separator' style={{ backgroundColor: bgColorSeparator }} />
      <textarea
        ref={textAreaRef}
        className='textarea'
        value={value}
        placeholder={placeholder}
        style={style}
        onMouseDown={() => setIsFocus(true)}
        onClick={() => setIsFocus(true)}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={() => setIsFocus(true)}
        onKeyPress={handlePressEnter}
        onAnimationStart={e =>
          e.animationName === 'onAutoFillStart' ? setHasValue(true) : setHasValue(false)
        }
        required={required}
        disabled={disabled}
        onInvalid={() => setHasError(true)}
        maxLength={maxLength}
        autoComplete={autoComplete ? 'on' : 'none'}
        name={name}
      />
      {returnListeningComponent()}
      {children}
    </div>
  )
}

export default TextArea
