import React, { forwardRef, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FolderHeart } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'

// // Assets
// import { ReactComponent as FavoriteList } from '../../assets/favorite-list.svg'

// Services
import { editUserPrompts } from '../../services/authServices'

// Components
import { Button, Input } from '../DesignSystem'
import Popover from '../Popover'
import AlertDialogDemo from '../AlertDialogDemo'
import DialogDemo from '../DialogDemo'
import ScrollArea from '../ScollArea'

// Styles
import './index.scss'

const Trigger = forwardRef(({ handleOpen, onlyIcon, props }, ref) => {
  function returnTriggerClassName() {
    let className = 'favorite-prompts__trigger'

    if (onlyIcon) {
      className += ' favorite-prompts__trigger--only-icon'
    }

    return className
  }

  return (
    <button
      {...props}
      ref={ref}
      className={returnTriggerClassName()}
      type='button'
      title='Abrir favoritos'
      onClick={handleOpen}>
      {onlyIcon ? (
        <FolderHeart style={{ width: 20, height: 20, color: '#64feda' }} />
      ) : (
        <>
          <span>Favoritos</span>
          <FolderHeart style={{ width: 20, height: 20, color: '#64feda' }} />
        </>
      )}
    </button>
  )
})

function Content({ prompts, onChoosePrompt, onRemovePrompt, onEditPrompt }) {
  return (
    <div className='favorite-prompts__content'>
      <h2 className='favorite-prompts__title'>Prompts favoritos</h2>
      {prompts.length === 0 && <p className='favorite-prompts__empty'>Nenhum prompt favorito</p>}
      {prompts.length > 0 && (
        <ul className='favorite-prompts__list'>
          <ScrollArea>
            {prompts.map((prompt, index) => (
              <li key={prompt._id} className='favorite-prompts__item'>
                <div
                  className='favorite-prompts__item__prompt'
                  role='button'
                  onClick={event => {
                    if (event.target.tagName !== 'DIV' && event.target.tagName !== 'SPAN') return
                    onChoosePrompt(prompt.text)
                  }}>
                  <span title={prompt.text}>{prompt.text}</span>
                  <div className='favorite-prompts__action-buttons'>
                    <Button
                      icon='Trash2'
                      sizeIcon={16}
                      onlyIcon
                      rounded
                      outlined
                      onClick={() => onRemovePrompt(prompt, index)}
                    />
                    <Button
                      icon='Pencil'
                      sizeIcon={16}
                      onlyIcon
                      rounded
                      contained
                      onClick={() => onEditPrompt(prompt, index)}
                    />
                  </div>
                </div>
                {index < prompts.length - 1 && <div className='favorite-prompts__item__divider' />}
              </li>
            ))}
          </ScrollArea>
        </ul>
      )}
    </div>
  )
}

function EditPrompt({ promptText = '', modalOpen, isLoading, setModalOpen, onConfirm }) {
  const [documentInputName, setDocumentInputName] = useState('')

  useEffect(() => {
    setDocumentInputName(promptText)
  }, [promptText])

  return (
    <DialogDemo
      customOpenButton
      style={{ height: '40px' }}
      title='Editar prompt'
      description='Edite o texto e clique em salvar alterações para aplicar as mudanças.'
      confirmButton='Salvar alterações'
      confirmAction={() => onConfirm(documentInputName)}
      loading={isLoading}
      modalOpen={modalOpen}
      onOpenChange={e => setModalOpen(e ? 'edit-prompt' : '')}>
      <Input
        placeholder='Texto do prompt'
        label='Texto do prompt'
        value={documentInputName}
        onChange={setDocumentInputName}
      />
    </DialogDemo>
  )
}

function FavoritePrompts({ onlyIcon = false }) {
  const { user } = useSelector(state => state.app)
  const dispatch = useDispatch()

  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState('')
  const [updatingPrompts, setUpdatingPrompts] = useState(false)
  const [promptToEdit, setPromptToEdit] = useState(null)
  const [promptToRemove, setPromptToRemove] = useState(null)

  const prompts = user.prompts ?? []

  function handleSelectPrompt(value) {
    setPopoverIsOpen(false)
    const inputElement = document.querySelector('.chat-viewer__back-space__input-space__bar')

    /* If input element exists, create custom event to change input value */
    if (inputElement) {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      ).set
      nativeInputValueSetter.call(inputElement, value)
      const event = new Event('input', { bubbles: true })
      inputElement.dispatchEvent(event)
    }
  }

  function handleSelectPromptToRemove(prompt, index) {
    setPromptToRemove({ ...prompt, index })
    setPopoverIsOpen(false)
    setModalOpen('remove-prompt')
  }

  function handleSelectPromptToEdit(prompt, index) {
    setPromptToEdit({ ...prompt, index })
    setPopoverIsOpen(false)
    setModalOpen('edit-prompt')
  }

  async function handleRemovePrompt() {
    setUpdatingPrompts(true)
    try {
      await editUserPrompts({ type: 'remove', promptId: promptToRemove._id })

      const beforePrompts = prompts.slice(0, promptToRemove.index)
      const afterPrompts = prompts.slice(promptToRemove.index + 1)
      const newPrompts = [...beforePrompts, ...afterPrompts]

      const newUser = { ...user, prompts: newPrompts }

      dispatch({ type: 'CHANGE_USER', payload: newUser })
      localStorage.setItem('user', JSON.stringify(newUser))

      setUpdatingPrompts(false)
      setModalOpen('')
      toast.success('Prompt removido')
    } catch (error) {
      console.error(error)
      setUpdatingPrompts(false)
      const errorContent = JSON.stringify(error)
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
          <Button
            icon='Copy'
            onlyIcon
            onClick={() => {
              navigator.clipboard.writeText(errorContent)
              toast.success('Erro copiado para área de transferência')
            }}
          />
        </div>
      )
    }
  }

  async function handleEditPrompt(promptText) {
    setUpdatingPrompts(true)
    try {
      await editUserPrompts({ type: 'edit', promptText, promptId: promptToEdit._id })

      const beforePrompts = prompts.slice(0, promptToEdit.index)
      const afterPrompts = prompts.slice(promptToEdit.index + 1)
      const updatedPrompt = { ...prompts[promptToEdit.index], text: promptText }
      const newPrompts = [...beforePrompts, updatedPrompt, ...afterPrompts]

      const newUser = { ...user, prompts: newPrompts }

      dispatch({ type: 'CHANGE_USER', payload: newUser })
      localStorage.setItem('user', JSON.stringify(newUser))

      setUpdatingPrompts(false)
      setModalOpen('')
      toast.success('Prompt atualizado')
    } catch (error) {
      console.error(error)
      setUpdatingPrompts(false)
      const errorContent = JSON.stringify(error)
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
          <Button
            icon='Copy'
            onlyIcon
            onClick={() => {
              navigator.clipboard.writeText(errorContent)
              toast.success('Erro copiado para área de transferência')
            }}
          />
        </div>
      )
    }
  }

  return (
    <>
      <Popover
        open={popoverIsOpen}
        onClose={() => setPopoverIsOpen(false)}
        trigger={<Trigger onlyIcon={onlyIcon} handleOpen={() => setPopoverIsOpen(true)} />}
        content={
          <Content
            prompts={prompts}
            onChoosePrompt={handleSelectPrompt}
            onRemovePrompt={handleSelectPromptToRemove}
            onEditPrompt={handleSelectPromptToEdit}
          />
        }
      />
      <AlertDialogDemo
        title='Remover prompt'
        description='Tem certeza que deseja remover esse prompt?'
        cancelButton='Cancelar'
        confirmButton='Sim, quero remover'
        modalOpen={modalOpen === 'remove-prompt'}
        setModalOpen={setModalOpen}
        confirmAction={handleRemovePrompt}
        loading={updatingPrompts && modalOpen === 'remove-prompt'}
      />
      <EditPrompt
        promptText={promptToEdit?.text}
        modalOpen={modalOpen === 'edit-prompt'}
        isLoading={updatingPrompts && modalOpen === 'edit-prompt'}
        setModalOpen={setModalOpen}
        onConfirm={handleEditPrompt}
      />
    </>
  )
}

export default FavoritePrompts
