import { useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

// Components
import CustomIcon from '../../../components/CustomIcon'
import DropDown from '../../../components/DropDown'
import DialogDemo from '../../../components/DialogDemo'
import Input from '../../../components/DesignSystem/Input'
import TextArea from '../../../components/DesignSystem/TextArea'

// Services
import { deleteComplaintArgument, editComplaintArgument } from '../../../services/documentsServices'

// Styles
import './index.scss'

function ArgumentCard({ complaintId, title, thesis, _id }) {
  const [modalOpen, setModalOpen] = useState('')
  const [newArgument, setNewArgument] = useState({ title: '', thesis: '' })

  const argumentRef = useRef(null)
  const queryClient = useQueryClient()

  async function editArgument() {
    try {
      await editComplaintArgument({
        complaintId,
        title: newArgument.title ? newArgument.title : title,
        thesis: newArgument.thesis ? newArgument.thesis : thesis,
        argumentId: _id,
      })
      queryClient.invalidateQueries('complaint-detail')
      setModalOpen('')
      toast.success('Argumento editado com sucesso')
    } catch (error) {
      console.log(error)
      toast.error('Erro ao editar argumento. Verifique se os campos estão preenchidos corretamente')
    }
  }

  async function deleteArgument() {
    try {
      await deleteComplaintArgument({ complaintId, argumentId: _id })
      queryClient.invalidateQueries('complaint-detail')
      setModalOpen('')
      toast.success('Argumento excluído com sucesso')
    } catch (error) {
      console.log(error)
      toast.error('Erro ao excluir argumento. Tente novamente mais tarde')
    }
  }

  return (
    <article className='argument-card' ref={argumentRef}>
      <h3>{title}</h3>
      <p>{thesis}</p>
      <div className='argument-card--dropdown__container'>
        <DropDown
          noArrow
          container={argumentRef.current}
          options={[
            {
              label: 'Editar',
              action: () => setModalOpen('edit-argument'),
              icon: 'PenLine',
            },
            {
              label: 'Excluir',
              action: () => setModalOpen('delete-argument'),
              icon: 'Trash',
            },
          ]}>
          <CustomIcon icon={'MoreVertical'} size={'20px'} />
        </DropDown>
      </div>
      <DialogDemo
        customOpenButton
        modalOpen={modalOpen === 'edit-argument'}
        onClose={() => setModalOpen('')}
        title='Editar Argumento'
        description={'Edite o argumento a ser incorporado na petição.'}
        confirmButton={'Editar'}
        confirmAction={editArgument}
        onOpenChange={e => setModalOpen(e ? 'edit-argument' : '')}>
        <Input
          placeholder='Ex: Responsabilidade Objetiva do Estado'
          label='Título'
          value={newArgument.title || title}
          onChange={e => setNewArgument({ ...newArgument, title: e })}
          required
        />
        <TextArea
          label='Tese'
          hasLabel
          placeholder={
            'Ex: De acordo com o art. 37, § 6º, da Constituição Federal, o Estado é responsável por danos causados aos particulares em decorrência de sua atuação, independentemente de culpa, ou seja, basta que se comprove o nexo causal entre a ação ou omissão do Estado e o dano sofrido.'
          }
          value={newArgument.thesis || thesis}
          onChange={e => setNewArgument({ ...newArgument, thesis: e })}
          maxLength={2000}
          required
        />
      </DialogDemo>
      <DialogDemo
        customOpenButton
        modalOpen={modalOpen === 'delete-argument'}
        onClose={() => setModalOpen('')}
        title='Excluir Argumento'
        description={'Tem certeza que deseja excluir este argumento? Esta ação não pode ser desfeita.'}
        confirmButton={'Excluir'}
        confirmAction={deleteArgument}
        onOpenChange={e => setModalOpen(e ? 'delete-argument' : '')}
      />
    </article>
  )
}

export default ArgumentCard
