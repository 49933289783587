import React, {useEffect,useRef} from 'react'
import { useNavigate } from 'react-router-dom';

import './index.scss'

function DocumentGenerator({result,documentId,isDone}) {

  const textareaRef = useRef(null);
  const navigate = useNavigate()

  const scrollToBottom = () => {
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
    }, [result]);

  useEffect(() => {
    if(isDone && documentId) navigate(`/documento/${documentId}`);
    }, [documentId, isDone]);

  return (
    <textarea ref={textareaRef} disabled value={result} className={'document__textarea'}/>
  )
}

export default DocumentGenerator
