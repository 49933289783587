import { useState, useEffect } from 'react'
import { ReactComponent as Loading1 } from '../../assets/loading1.svg'
import { ReactComponent as Loading2 } from '../../assets/loading2.svg'
import { ReactComponent as Loading3 } from '../../assets/loading3.svg'
import './index.scss'

function DynamicLoading({
  percent,
  loadingMessage = 'Enviando arquivo...',
  title = 'Carregando arquivos',
  subtitle = 'Estamos processando o seu arquivo. Por favor, mantenha esta janela aberta até que o processo seja concluído.',
}) {
  const [hintTitle, setHintTitle] = useState('')
  const [hintContent, setHintContent] = useState('')

  const hints = [
    {
      title: 'Clareza',
      content:
        'Formule perguntas claras e precisas. Ex: "Qual o prazo para manifestação no documento X?"',
    },
    {
      title: 'Contexto',
      content:
        'Inclua o contexto necessário para respostas adequadas. Ex: "Com base nos documentos X e Y, qual é o procedimento para recurso de apelação?"',
    },
    {
      title: 'Relações entre Documentos',
      content:
        'Conecte documentos para fornecer contexto completo. Ex: "Segundo o documento X e a apelação no documento Y, quais pontos foram atacados?"',
    },
    {
      title: 'Estrutura',
      content:
        'Comece com o contexto seguido da pergunta. Ex: "No contrato entre A e B, quais são as obrigações da parte B?"',
    },
    {
      title: 'Exemplos',
      content:
        'Use exemplos para ilustrar perguntas. Ex: "Se a empresa A viola a cláusula Y, quais são as penalidades possíveis?"',
    },
  ]

  useEffect(() => {
    const changeHint = () => {
      const randomIndex = Math.floor(Math.random() * hints.length)
      setHintTitle(hints[randomIndex].title)
      setHintContent(hints[randomIndex].content)
    }

    changeHint()

    const intervalId = setInterval(changeHint, 6000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <dialog open={false} className='dynamic-loading__container'>
      <div className='dynamic-loading'>
        <header>
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </header>
        <div className='line' style={{ marginBlock: '40px' }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
          }}>
          {percent !== undefined && <h3>{percent}%</h3>}
          <p className='dynamic-loading--message'>{loadingMessage}</p>
          <div className='dynamic-loading--svg__container'>
            <Loading1 className='dynamic-loading--svg-1' />
            <Loading2 className='dynamic-loading--svg-2' />
            <Loading3 className='dynamic-loading--svg-3' />
          </div>
        </div>
        <p className='dynamic-loading--hint-title'>{hintTitle}</p>
        <p className='dynamic-loading--hint-content'>{hintContent}</p>
      </div>
    </dialog>
  )
}

export default DynamicLoading
