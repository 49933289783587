import React, { useEffect, useRef } from 'react'

import './index.scss'

function AnimationBg() {
  const stringRef = useRef(null)
  const mouseRef = useRef(null)

  useEffect(() => {
    const interval = setInterval(() => {
      const randomString = getRandomString(55)
      stringRef.current.innerText = Array(100).fill(randomString).join('')
    }, 250)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    const getMousePosition = event => {
      const { clientX, clientY } = event
      mouseRef.current.style.transform = `translate(${clientX}px,${clientY}px)`
      // mouseRef.current.style.left = clientX + 'px'
      // mouseRef.current.style.top = clientY + 'px'
    }
    window.addEventListener('mousemove', getMousePosition)
    return () => {
      window.removeEventListener('mousemove', getMousePosition)
    }
  }, [])

  const getRandomString = length => {
    let result = ''
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return result
  }

  return (
    <div className='animation-bg'>
      <div ref={mouseRef} className={'animation-bg__mouse'}>
        ggg
      </div>
      <p className='animation-bg__random-text' ref={stringRef}>
        1
      </p>
      <div className='animation-bg__bubble'></div>
      <div className='animation-bg__bubble2'></div>
      <div className='animation-bg__bubble2'></div>
      <div className='animation-bg__bubble2'></div>
    </div>
  )
}

export default AnimationBg
