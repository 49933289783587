import React, { useState } from 'react'

import './index.scss'

/**
 * Tabs component
 * @param {{
 * list: [{ label: string, action: function }]
 * disabledTab: ?array
 * maxWidth: ?number | string
 * }} props Props
 * @returns {React.Element} - the Tabs component
 */
function Tabs({
  list = [{ label: 'Sample', action: () => alert('Sample') }],
  disabledTab = [],
  maxWidth,
  ...props
}) {
  const [activeTab, setActiveTab] = useState(0)

  function handleClickTab(index) {
    if (activeTab === index) return
    setActiveTab(index)
    list[index].action && list[index].action()
  }

  function returnTabStyle() {
    let style = {
      width: `${100 / list.length}%`,
      left: `calc(${100 / list.length}% * ${activeTab})`,
    }
    return style
  }

  function returnClassName(index) {
    let className = 'tabs__item'

    if (index === activeTab) className += ' tabs__item--selected'
    if (disabledTab.includes(index)) className += ' tabs__item--disabled'

    return className
  }

  return (
    <div className='tabs' style={{ maxWidth }} {...props}>
      {list.map((item, index) => (
        <div
          key={index}
          className={returnClassName(index)}
          onClick={() => (!disabledTab.includes(index) ? handleClickTab(index) : undefined)}>
          {item.label}
        </div>
      ))}
      <div className='tabs__choice' style={returnTabStyle()}></div>
    </div>
  )
}

export default Tabs
