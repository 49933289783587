import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import {
  onlyNumber,
  formatDocument,
  validadeEin,
  validateCpf,
  validateCnpj,
  validateEmail,
} from '../../../../constants/validate'
import TitleSpace from '../../../../components/TitleSpace'
import { Input, Select, TextArea, Button, Checkbox } from '../../../../components/DesignSystem'
import DialogAlert from '../../../../components/AlertDialogDemo'

import { getAddressByCEP } from '../../../../services/documentsServices'

import { generatePrompt } from '../../../../utils/NotificationUtils'

import './index.scss'

function NotificationForm({ setStep, createNotification, setPrompt }) {
  const { user } = useSelector(state => state.app)
  const [notifierName, setNotifierName] = useState('')
  const [notifierDocument, setNotifierDcoument] = useState('')
  const [name, setName] = useState('')
  const [document, setDocument] = useState('')
  const [email, setEmail] = useState('')
  const [CEP, setCEP] = useState('')
  const [street, setStreet] = useState('')
  const [number, setNumber] = useState('')
  const [complement, setComplement] = useState('')
  const [city, setCity] = useState('')
  const [addressState, setAddressState] = useState('')
  const [address, setAddress] = useState({})
  const [lawArea, setLawArea] = useState(undefined)
  const [facts, setFacts] = useState('')
  const [requests, setRequests] = useState('')
  const [legalBase, setLegalBase] = useState(true)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isLoadingCepInfo, setIsLoadingCepInfo] = useState(false)

  const navigate = useNavigate()

  let lawAreas = [
    { value: 'Civil', label: 'Civil' },
    { value: 'Penal', label: 'Penal' },
    { value: 'Tributário', label: 'Tributário' },
    { value: 'Trabalhista', label: 'Trabalhista' },
    { value: 'Contratual', label: 'Contratual' },
    { value: 'Ambiental', label: 'Ambiental' },
    { value: 'Empresarial', label: 'Empresarial' },
    { value: 'Consumidor', label: 'Consumidor' },
  ]

  function handleDocumentError(value) {
    let pass = false
    if (!value) return pass
    if (
      onlyNumber(value).length === 6 ||
      (onlyNumber(value).length === 9 && validadeEin(value)) ||
      (onlyNumber(value).length === 11 && validateCpf(value.replace(/\D/g, ''))) ||
      (onlyNumber(value).length === 14 && validateCnpj(value))
    ) {
      pass = true
    }

    return pass
  }

  function validateButton() {
    let error = false
    if (_.isEmpty(name) || !lawArea || _.isEmpty(facts)) {
      error = true
    }
    return error
  }

  async function fetchCEPData() {
    if (CEP.length === 8) {
      setIsLoadingCepInfo(true)
      const newAddress = await getAddressByCEP(CEP)
      setAddress(newAddress)
      setIsLoadingCepInfo(false)
    }
  }

  async function saveData(event) {
    event.preventDefault()
    const newPrompt = await generatePrompt({
      notifier: { name: notifierName, document: notifierDocument },
      notified: { name, document, email },
      address: { CEP, street, number, complement, city, state: addressState },
      info: { lawArea, facts, requests, legalBase },
    })

    setPrompt(newPrompt)
  }

  async function handleSubmit() {
    setStep(2)
    await createNotification()
  }

  useEffect(() => {
    setStreet(address.logradouro)
    setCity(address.localidade)
    setAddressState(address.uf)
  }, [address])

  useEffect(() => {
    if (user.name) {
      setNotifierName(user.name + ' ' + user.surname)
    }
  }, [user])

  return (
    <form className='notification-form-container'>
      <TitleSpace
        title='Notificação'
        subtitle={
          'Insira as informações abaixo e vamos gerar um modelo de notificação extrajudicial'
        }
      />
      <div className='notification-form__form-space'>
        <div className='notification-form__document-form'>
          <h3 className='notification-form__address-form__title'>Notificante</h3>
          <Input
            label='Nome'
            placeholder=''
            value={notifierName}
            onChange={setNotifierName}
            required
          />
          <Input
            label='Documento'
            placeholder=''
            value={notifierDocument}
            onChange={value => setNotifierDcoument(formatDocument(value))}
          />
        </div>
        <div className='notification-form__document-form'>
          <h3 className='notification-form__address-form__title'>Notificado</h3>
          <Input label='Nome' placeholder='' value={name} onChange={setName} required />
          <Input
            label='Documento'
            placeholder=''
            value={document}
            onChange={value => setDocument(formatDocument(value))}
          />
          <Input label='Email' placeholder='' type='email' value={email} onChange={setEmail} />
        </div>
        <div className='notification-form__address-form'>
          <h3 className='notification-form__address-form__title'>Endereço</h3>
          <Input
            label='CEP'
            placeholder=''
            value={CEP}
            onChange={value => setCEP(onlyNumber(value))}
            disabled={isLoadingCepInfo}
            onBlur={fetchCEPData}
          />
          <Input
            label='Rua'
            placeholder=''
            value={street}
            onChange={setStreet}
            disabled={isLoadingCepInfo}
            isLoading={isLoadingCepInfo}
          />
          <div className='notification-form__address-form__data-flex'>
            <Input
              label='Numero'
              placeholder=''
              type='text'
              value={onlyNumber(number)}
              onChange={setNumber}
            />
            <Input label='Complemento' placeholder='' value={complement} onChange={setComplement} />
          </div>
          <Input
            placeholder={'Cidade'}
            label={'Cidade'}
            value={city}
            onChange={setCity}
            disabled={isLoadingCepInfo}
            isLoading={isLoadingCepInfo}
          />
          <Input
            placeholder={'Estado'}
            label={'Estado'}
            value={addressState}
            onChange={setAddressState}
            disabled={isLoadingCepInfo}
            isLoading={isLoadingCepInfo}
          />
        </div>
        <div className='notification-form__address-form'>
          <h3 className='notification-form__address-form__title'>Informações</h3>
          <Select
            value={lawArea}
            label={'Área do direito'}
            options={lawAreas}
            onChange={setLawArea}
            required
          />
          <TextArea
            hasLabel
            value={facts}
            label={'Dos fatos'}
            placeholder={'Dos fatos'}
            onChange={setFacts}
            bgColorSeparator={'#1b1b1b'}
            required
          />
          <TextArea
            hasLabel
            value={requests}
            label={'Dos pedidos'}
            placeholder={'Dos pedidos'}
            onChange={setRequests}
          />

          <div className='checkbox__space-container'>
            <Checkbox value={legalBase} onChange={() => setLegalBase(!legalBase)} blackCheck />
            <p>
              Compor texto da notificação com com base na legislação, doutrina e jurisprudência mais
              atual.
            </p>
          </div>

          <DialogAlert
            title={'Confimar mudanças?'}
            description={'Verifique se os dados estão preenchidos corretamente antes de confirmar.'}
            cancelButton={'Cancelar'}
            confirmButton={'Confirmar'}
            modalOpen={isOpenModal}
            setModalOpen={() => {
              setIsOpenModal(!isOpenModal)
            }}
            confirmAction={handleSubmit}
          />
        </div>
        {/* <ButtonSpace isDisable={validateButton()} handleSubmit={() => {saveData();setIsOpenModal(true)}} /> */}
        <div className='notification-form__button-space'>
          <Button
            children={'Cancelar'}
            outlined
            fullWidth
            onClick={() => navigate('/')}
            type={'button'}
          />
          <Button
            children={'Confirmar'}
            contained
            fullWidth
            disabled={validateButton()}
            type={'submit'}
            onClick={event => {
              saveData(event)
              setIsOpenModal(true)
            }}
          />
        </div>
      </div>
    </form>
  )
}

export default NotificationForm
