import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { login } from '../../services/authServices'

import spectter from '../../assets/spectter.svg'
import { Button, Input } from '../../components/DesignSystem'
import Spinner from '../Spinner'

import './index.scss'

const LoginForm = props => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  async function handleLogin(event) {
    event.preventDefault()
    setIsLoading(true)
    try {
      const data = await login(email, password)
      dispatch({ type: 'CHANGE_USER', payload: data.data })
      localStorage.setItem('user', JSON.stringify(data.data))
      axios.defaults.headers.common['authentication'] = data.data.token
      navigate('/')
    } catch (error) {
      console.log(error)
      const errorContent = JSON.stringify(error)
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
          <Button
            icon='Copy'
            onlyIcon
            onClick={() => {
              navigator.clipboard.writeText(errorContent)
              toast.success('Erro copiado para área de transferência')
            }}
          />
        </div>
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='login__inputs'>
      <header>
        <img src={spectter} alt='Logo Spectter.' />
      </header>

      <main>
        <h2>
          Preencha os campos com suas <br />
          <span>informações de login</span>
        </h2>
        <form className='login__inputs_container' onSubmit={handleLogin}>
          <Input
            placeholder='Digite seu e-mail aqui'
            label='Email'
            value={email}
            onChange={setEmail}
          />
          <Input
            placeholder='Digite sua senha aqui'
            label='Senha'
            type='password'
            value={password}
            onChange={setPassword}
          />
          <div className='login__form__disclaimer'>
            <span>
              Ao clicar em "Entrar", você declara que leu, compreendeu e aceita integralmente os{' '}
              <Link to='/termos-de-uso' target='_blank'>
                Termos de Uso
              </Link>{' '}
              e o{' '}
              <Link to='/aviso-de-privacidade' target='_blank'>
                Aviso de Privacidade
              </Link>{' '}
              da plataforma.
            </span>
          </div>
          <Button contained color='green' fullWidth type='submit'>
            {isLoading ? <Spinner color='#272929' size={24} /> : 'ENTRAR'}
          </Button>
          <a onClick={() => props.onChangeStep(2)}>Esqueci minha senha</a>
          <a
            href='https://www.semprocesso.com.br/spectter/planos-spectter'
            target='_blank'
            rel='noreferrer'>
            Quero uma conta
          </a>
        </form>
      </main>

      <footer>
        <p>powered by </p>
        <a href='https://www.semprocesso.com.br/' target='_blank' rel='noreferrer'>
          {' '}
          sem processo
        </a>
      </footer>
    </div>
  )
}

export default LoginForm
