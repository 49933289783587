import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { Input, Button } from '../../components/DesignSystem'
import { forgetPassword } from '../../services/authServices'

import spectter from '../../assets/spectter.svg'

import './index.scss'
import Spinner from '../Spinner'

const ForgetPassword = props => {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  async function handleForgetPassword(event) {
    event.preventDefault()
    setIsLoading(true)
    try {
      await forgetPassword(email)
      toast.success('Caso você seja usuário, receberá um email para redefinir sua senha.')
      props.onChangeStep(1)
    } catch (error) {
      console.log(error)
      const errorContent = JSON.stringify(error)
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
          <Button
            icon='Copy'
            onlyIcon
            onClick={() => {
              navigator.clipboard.writeText(errorContent)
              toast.success('Erro copiado para área de transferência')
            }}
          />
        </div>,
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='login__inputs'>
      <header>
        <img src={spectter} alt='Logo Spectter.' />
      </header>

      <main>
        <h2>
          Preencha o campo com o seu <br />
          <span>email</span>
        </h2>
        <form className='login__inputs_container' onSubmit={handleForgetPassword}>
          <Input
            placeholder='Digite seu e-mail aqui'
            label='Email'
            value={email}
            onChange={setEmail}
          />
          <Button contained color='green' fullWidth type='submit'>
            {isLoading ? <Spinner color='#272929' size={24} /> : 'ENVIAR'}
          </Button>
          <a onClick={() => props.onChangeStep(1)}>Voltar para login</a>
        </form>
      </main>

      <footer>
        <p>powered by </p>
        <a href='https://www.semprocesso.com.br/' target='_blank' rel='noreferrer'>
          {' '}
          sem processo
        </a>
      </footer>
    </div>
  )
}

export default ForgetPassword
