import { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Copy, Expand } from 'lucide-react'
import { toast } from 'react-toastify'
import { BeatLoader } from 'react-spinners'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

// Components
import AddPromptFavorites from '../AddPromptFavorites'

import './index.scss'

const ChatMessage = ({ profile, message, noCopy, hasLoader = false }) => {
  const [hasTable, setHasTable] = useState(false)
  
  const messageRef = useRef(null)
  
  const dispatch = useDispatch()

  useEffect(() => {
    if (messageRef.current?.querySelector('table')) {
      setHasTable(true)
    }
  }, [messageRef])

  function handleCopyToClipboard(e, value) {
    e.preventDefault()
    navigator.clipboard.writeText(value)
    toast.success('Mensagem copiada')
  }

  function handleOpenMessageDetail() {
    dispatch({ type: 'CHANGE_MESSAGE_DETAIL', payload: { message } })
    dispatch({ type: 'CHANGE_MESSAGE_DETAIL_OPEN', payload: true })
  }

  return (
    <div ref={messageRef} className={`message__container message__container--${profile}`}>
      {profile === 'system' && (
        <header className='message__header'>
          <h3 className='message__header__title'>spectter</h3>
          {hasTable && (
            <div>
              <button onClick={handleOpenMessageDetail}>
                <Expand size={16} color='#fff' />
              </button>
            </div>
          )}
        </header>
      )}
      {profile !== 'system' && <AddPromptFavorites promptText={message} />}
      <div className='message__section'>
        <div
          className={`message__section__text ${
            profile === 'system' ? 'message__section__text--system' : ''
          }`}>
          <ReactMarkdown remarkPlugins={[remarkGfm]} className='markdown__container'>
            {message}
          </ReactMarkdown>
          {hasLoader && (
            <BeatLoader className='message__section__beat-loader' color='#fff' size={4} />
          )}
        </div>
        {profile === 'system' && !noCopy && !hasLoader && (
          <button
            className='message__section__button'
            onClick={e => handleCopyToClipboard(e, message)}>
            <Copy size={16} color='#fff' />
          </button>
        )}
      </div>
    </div>
  )
}

export default ChatMessage
