import moment from 'moment'
import * as validate from '../validate'

export const getGreeting = () => {
  const hour = new Date().getHours()

  if (hour > 5 && hour < 12) {
    return 'Bom dia'
  } else if (hour >= 12 && hour < 18) {
    return 'Boa tarde'
  } else {
    return 'Boa noite'
  }
}

export const getlinkToTJ = (lawsuitNumber, lawsuitUf) => {
  let link = ''

  if (lawsuitNumber && lawsuitNumber.length == 25 && lawsuitUf) {
    const lawsuitNumberOnlyNumber = lawsuitNumber.replace(/\D/g, '')
    const links = [
      {},
      {},
      {},
      {},
      {},
      {
        AC: `http://pje.trt14.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        AL: `http://pje.trt19.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        AP: `http://pje.trt8.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        AM: `https://pje.trt11.jus.br/consultaprocessual/detalhe-processo/${lawsuitNumber}`,
        BA: `http://pje.trt5.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        CE: `http://pje.trt7.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        DF: `http://pje.trt10.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        ES: `http://pje.trt17.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        GO: `http://pje.trt18.jus.br/consultaprocessual/detalhe-processo/${lawsuitNumber}`,
        MA: `http://pje.trt16.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        MT: `http://pje.trt23.jus.br/consultaprocessual/detalhe-processo/${lawsuitNumber}`,
        MS: `http://pje.trt24.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        MG: `http://pje.trt3.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        PA: `http://pje.trt8.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        PB: `http://consultaprocessual.trt13.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        PR: `http://pje.trt9.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        PE: `http://pje.trt6.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        PI: `https://pje.trt22.jus.br/consultaprocessual/detalhe-processo/${lawsuitNumber}`,
        RJ: `http://pje.trt1.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        RN: `http://pje.trt21.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        RS: `http://pje.trt4.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        RO: `http://pje.trt14.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        RR: `https://pje.trt11.jus.br/consultaprocessual/detalhe-processo/${lawsuitNumber}`,
        SC: `http://pje.trt12.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        SP: `http://consulta.pje.trtsp.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        SE: `http://pje.trt20.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
        TO: `http://pje.trt10.jus.br/consultaprocessual/pages/consultas/ListaProcessos.seam?numero_unic=${lawsuitNumber}`,
      },
      {},
      {},
      {
        AC: `http://esaj.tjac.jus.br/cpopg/search.do?conversationId=&dadosConsulta.localPesquisa.cdLocal=-1&cbPesquisa=NUMPROC&dadosConsulta.tipoNuProcesso=UNIFICADO&numeroDigitoAnoUnificado=${lawsuitNumber.substring(
          0,
          15
        )}&foroNumeroUnificado=${lawsuitNumber.substring(
          21,
          25
        )}&dadosConsulta.valorConsultaNuUnificado=${lawsuitNumber}&dadosConsulta.valorConsulta=&uuidCaptcha=`,
        AL: `http://www2.tjal.jus.br/cpopg/search.do?conversationId=&dadosConsulta.localPesquisa.cdLocal=-1&cbPesquisa=NUMPROC&dadosConsulta.tipoNuProcesso=UNIFICADO&numeroDigitoAnoUnificado=${lawsuitNumber.substring(
          0,
          15
        )}&foroNumeroUnificado=${lawsuitNumber.substring(
          21,
          25
        )}&dadosConsulta.valorConsultaNuUnificado=${lawsuitNumber}&dadosConsulta.valorConsulta=&uuidCaptcha=`,
        AP: `http://tucujuris.tjap.jus.br/api/publico/buscar-autos-consulta-publica?id=&numero_unico=${lawsuitNumber}&nome_parte=&documento_parte=&nome_advogado=&oab_advogado=&numero_carta_precatoria=&situacao_processo=&captcha=`,
        AM: `http://consultasaj.tjam.jus.br/cpopg/search.do?conversationId=&dadosConsulta.localPesquisa.cdLocal=-1&cbPesquisa=NUMPROC&dadosConsulta.tipoNuProcesso=UNIFICADO&numeroDigitoAnoUnificado=${lawsuitNumber.substring(
          0,
          15
        )}&foroNumeroUnificado=${lawsuitNumber.substring(
          21,
          25
        )}&dadosConsulta.valorConsultaNuUnificado=${lawsuitNumber}&dadosConsulta.valorConsulta=&uuidCaptcha=`,
        BA: `http://esaj.tjba.jus.br/cpopg/search.do?conversationId=&dadosConsulta.localPesquisa.cdLocal=-1&cbPesquisa=NUMPROC&dadosConsulta.tipoNuProcesso=UNIFICADO&numeroDigitoAnoUnificado=${lawsuitNumber.substring(
          0,
          15
        )}&foroNumeroUnificado=${lawsuitNumber.substring(
          21,
          25
        )}&dadosConsulta.valorConsultaNuUnificado=${lawsuitNumber}&dadosConsulta.valorConsulta=&uuidCaptcha=`,
        CE: `http://esaj.tjce.jus.br/cpopg/search.do?conversationId=&dadosConsulta.localPesquisa.cdLocal=-1&cbPesquisa=NUMPROC&dadosConsulta.tipoNuProcesso=UNIFICADO&numeroDigitoAnoUnificado=${lawsuitNumber.substring(
          0,
          15
        )}&foroNumeroUnificado=${lawsuitNumber.substring(
          21,
          25
        )}&dadosConsulta.valorConsultaNuUnificado=${lawsuitNumber}&dadosConsulta.valorConsulta=&uuidCaptcha=`,
        DF: '',
        ES: '',
        GO: `http://pjd.tjgo.jus.br/BuscaProcessoPublica?numeroProcessoConsulta=${lawsuitNumberOnlyNumber}&PaginaAtual=2&Passo=6`,
        MA: '',
        MT: '',
        MS: `https://esaj.tjms.jus.br/cpopg5/search.do?conversationId=&dadosConsulta.localPesquisa.cdLocal=1&cbPesquisa=NUMPROC&dadosConsulta.tipoNuProcesso=UNIFICADO&numeroDigitoAnoUnificado=${lawsuitNumber.substring(
          0,
          15
        )}&foroNumeroUnificado=${lawsuitNumber.substring(
          21,
          25
        )}&dadosConsulta.valorConsultaNuUnificado=${lawsuitNumber}&dadosConsulta.valorConsulta=&uuidCaptcha=`,
        MG: `http://www4.tjmg.jus.br/juridico/sf/proc_resultado.jsp?comrCodigo=24&numero=1&listaProcessos=${lawsuitNumberOnlyNumber}&btn_pesquisar=Pesquisar`,
        PA: '',
        PB: '',
        PR: '',
        PE: '',
        PI: '',
        RJ: `http://www4.tjrj.jus.br/numeracaoUnica/faces/index.jsp?numProcesso=${lawsuitNumber}`,
        RN: `http://esaj.tjrn.jus.br/cpopg/search.do?conversationId=&dadosConsulta.localPesquisa.cdLocal=-1&cbPesquisa=NUMPROC&dadosConsulta.tipoNuProcesso=UNIFICADO&numeroDigitoAnoUnificado=${lawsuitNumber.substring(
          0,
          15
        )}&foroNumeroUnificado=${lawsuitNumber.substring(
          21,
          25
        )}&dadosConsulta.valorConsultaNuUnificado=${lawsuitNumber}&dadosConsulta.valorConsulta=&uuidCaptcha=`,
        RS: '',
        RO: '',
        RR: '',
        SC: `http://esaj.tjsc.jus.br/cpopg/search.do?conversationId=&dadosConsulta.localPesquisa.cdLocal=-1&cbPesquisa=NUMPROC&dadosConsulta.tipoNuProcesso=UNIFICADO&numeroDigitoAnoUnificado=${lawsuitNumber.substring(
          0,
          15
        )}&foroNumeroUnificado=${lawsuitNumber.substring(
          21,
          25
        )}&dadosConsulta.valorConsultaNuUnificado=${lawsuitNumber}&dadosConsulta.valorConsulta=&uuidCaptcha=`,
        SP: `http://esaj.tjsp.jus.br/cpopg/search.do?conversationId=&dadosConsulta.localPesquisa.cdLocal=-1&cbPesquisa=NUMPROC&dadosConsulta.tipoNuProcesso=UNIFICADO&numeroDigitoAnoUnificado=${lawsuitNumber.substring(
          0,
          15
        )}&foroNumeroUnificado=${lawsuitNumber.substring(
          21,
          25
        )}&dadosConsulta.valorConsultaNuUnificado=${lawsuitNumber}&dadosConsulta.valorConsulta=&uuidCaptcha=`,
        SE: '',
        TO: '',
      },
      {},
    ]

    link = links[Number(lawsuitNumber.substring(16, 17))][lawsuitUf.toUpperCase()]
  }

  return link
}

/**
 * @param {Object} filters Object of filters to be used in the search
 * @returns {String} String of the filters to be used in the search
 * ----
 * @example filtersJSONToString({Origem: ["TESTE","TESTE1"], UF:["SP","RJ"]})
 */
export function filtersJSONToString(filters = {}) {
  if (typeof filters === 'string') return filters
  return JSON.stringify(filters)
    .split('],')
    .map(e => e.replace(',', '+').replace(/(["{}\\[\]])/g, ''))
    .filter(e => e.split(':')[1]?.length > 0)
    .join(',')
}

/**
 * @param {String} filters String of filters to be used in the search
 * @returns {Object} Object of filters to be used in the search
 * ----
 * @example filtersStringToJSON("Origem:TESTE+TESTE1, UF:SP+RJ, ")
 */
export function filtersStringToJSON(filters = '') {
  if (!filters.includes(':')) return filters
  return Object.fromEntries(
    filters
      .split(',')
      .filter(f => f !== '')
      .map(e => e.trim())
      .map(e => e.split(':'))
      .map(e => [e[0], e[1]?.split('+').map(e => e.trim())])
  )
}

export function formatDateToRange(value) {
  function formatDate(date) {
    const dateValue = date
    const day = dateValue.getDate()
    const month = dateValue.getMonth() + 1
    const year = dateValue.getFullYear()

    return `${year}-${month}-${day}`
  }

  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate()
  }

  let newValue = value
  const today = new Date()
  const otherDate = new Date()
  const weekDay = today.getDay()
  const lastWeekFirstDay = weekDay + 7
  const lastWeekLastDay = weekDay + 1

  const currentYear = today.getFullYear()

  const lastMonth = today.getMonth()
  const lastMonthFirstDay = today.getDate() + getDaysInMonth(currentYear, lastMonth) - 1
  const lastMonthLastDay = today.getDate()

  switch (newValue) {
    case 'today':
      const todayFormatted = formatDate(today)
      newValue = {
        initialDate: todayFormatted,
        finalDate: todayFormatted,
      }
      return newValue
    case 'yesterday':
      newValue = {
        initialDate: formatDate(new Date(today.setDate(today.getDate() - 1))),
        finalDate: formatDate(today),
      }
      return newValue
    case 'thisWeek':
      const thisWeekIni = new Date(new Date().setHours(0, 0, 0, 0))
      thisWeekIni.setDate(today.getDate() - weekDay)

      newValue = {
        initialDate: formatDate(thisWeekIni),
        finalDate: formatDate(today),
      }
      return newValue
    case 'lastWeek':
      const lastWeekIni = new Date(new Date().setHours(0, 0, 0, 0))
      lastWeekIni.setDate(today.getDate() - lastWeekFirstDay)
      const lastWeekEnd = new Date(new Date().setHours(0, 0, 0, 0))
      lastWeekEnd.setDate(today.getDate() - lastWeekLastDay)

      newValue = {
        initialDate: formatDate(lastWeekIni),
        finalDate: formatDate(lastWeekEnd),
      }
      return newValue
    case 'thisMonth':
      const thisMonthIni = new Date(new Date().setHours(0, 0, 0, 0))
      thisMonthIni.setDate(today.getDate() - today.getDate() + 1)

      newValue = {
        initialDate: formatDate(thisMonthIni),
        finalDate: formatDate(today),
      }
      return newValue
    case 'lastMonth':
      const lastMonthIni = new Date(new Date().setHours(0, 0, 0, 0))
      lastMonthIni.setDate(today.getDate() - lastMonthFirstDay)
      const lastMonthEnd = new Date(new Date().setHours(0, 0, 0, 0))
      lastMonthEnd.setDate(today.getDate() - lastMonthLastDay)

      newValue = {
        initialDate: formatDate(lastMonthIni),
        finalDate: formatDate(lastMonthEnd),
      }
      return newValue
    case 'thisYear':
      const firstDayYear = new Date(new Date(currentYear, 0, 1).setHours(0, 0, 0, 0))

      newValue = {
        initialDate: formatDate(firstDayYear),
        finalDate: formatDate(today),
      }
      return newValue
    case 'lastYear':
      const firstDayLastYear = new Date(new Date(currentYear - 1, 0, 1).setHours(0, 0, 0, 0))
      const lastDayLastYear = new Date(new Date(currentYear - 1, 11, 31).setHours(0, 0, 0, 0))

      newValue = {
        initialDate: formatDate(firstDayLastYear),
        finalDate: formatDate(lastDayLastYear),
      }
      return newValue
    case 'last30days':
      otherDate.setDate(today.getDate() - 30)
      otherDate.setHours(0, 0, 0, 0)

      newValue = {
        initialDate: formatDate(otherDate),
        finalDate: formatDate(today),
      }
      return newValue
    case 'last60days':
      otherDate.setDate(today.getDate() - 60)
      otherDate.setHours(0, 0, 0, 0)

      newValue = {
        initialDate: formatDate(otherDate),
        finalDate: formatDate(today),
      }
      return newValue
    case 'last90days':
      otherDate.setDate(today.getDate() - 90)
      otherDate.setHours(0, 0, 0, 0)

      newValue = {
        initialDate: formatDate(otherDate),
        finalDate: formatDate(today),
      }
      return newValue
    default:
      return newValue
  }
}

export function formatIsoDate(isoDate) {
  let date = new Date(isoDate)

  const day = date.getDate()
  const month = date.getMonth() + 1

  return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${date.getFullYear()}`
}

export function dispatchFormattedLinearFilters({ dispatch, dispatchType, previousData, newData }) {
  const linearFiltersObj = newData.reduce(
    (obj, item) => ({
      ...obj,
      [item.key]: item.value ?? 0,
    }),
    {}
  )
  let newLinearFilters = previousData.map(linearFilter => ({
    ...linearFilter,
    value: linearFiltersObj[linearFilter.key],
  }))

  dispatch({
    type: dispatchType,
    payload: newLinearFilters,
  })
}

export function setMask(mask, value) {
  switch (mask) {
    case 'CPF':
      return validate.cpf(value)
    case 'CNPJ':
      return validate.cnpj(value)
    case 'CNJ':
      return validate.lawsuitNumber(value)
    case 'OAB':
      return validate.oab(value)
    case 'CURRENCY':
      return validate.currency(value)
    case 'ONLYLETTERS_ONLYNUMBERS_UPPERCASE':
      return validate.removeAccents(value).toUpperCase()
    case 'ONLYLETTERS':
      return validate.onlyLetters(value)
    case 'ONLYLETTERS_UPPERCASE':
      return validate.onlyLetters(value).toUpperCase()
    case 'ONLYNUMBERS':
      return validate.onlyNumber(value)
    case 'UPPERCASE':
      return value.toLowerCase()
    case 'CREDITCARD':
      return validate.creditCard(validate.onlyNumber(value))
    case 'DATE':
      return moment(value).format('DD/MM/YYYY')
    case 'PHONE':
      return validate.phone(value)
    default:
      return value
  }
}

export function splitDateString(key, date) {
  let today = moment()

  switch (date) {
    case 'today':
      return {
        [key]: {
          initialDate: today.format('YYYY-MM-DD'),
          finalDate: today.format('YYYY-MM-DD'),
        },
      }
    case 'yesterday':
      return {
        [key]: {
          initialDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
          finalDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        },
      }
    case 'thisWeek':
      return {
        [key]: {
          initialDate: moment().startOf('week').format('YYYY-MM-DD'),
          finalDate: today.format('YYYY-MM-DD'),
        },
      }
    case 'lastWeek':
      return {
        [key]: {
          initialDate: moment().startOf('week').subtract(1, 'week').format('YYYY-MM-DD'),
          finalDate: moment().startOf('week').subtract(1, 'days').format('YYYY-MM-DD'),
        },
      }
    case 'thisMonth':
      return {
        [key]: {
          initialDate: moment().startOf('month').format('YYYY-MM-DD'),
          finalDate: today.format('YYYY-MM-DD'),
        },
      }
    case 'lastMonth':
      return {
        [key]: {
          initialDate: moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
          finalDate: moment().startOf('month').subtract(1, 'days').format('YYYY-MM-DD'),
        },
      }
    case 'thisYear':
      return {
        [key]: {
          initialDate: moment().startOf('year').format('YYYY-MM-DD'),
          finalDate: today.format('YYYY-MM-DD'),
        },
      }
    case 'lastYear':
      return {
        [key]: {
          initialDate: moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD'),
          finalDate: moment().startOf('year').subtract(1, 'days').format('YYYY-MM-DD'),
        },
      }
    default:
      break
  }
}

export function base64ToArrayBuffer(base64) {
  let binaryString = atob(base64)
  let bytes = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

export function returnListCounterText(data, page) {
  if (data?.totalDocs) {
    let aux = 1

    if (page) {
      aux = page
    }

    let max = aux * 25
    if (data.totalDocs < max) {
      max = data.totalDocs
    }

    let min = 1 + 25 * (aux - 1)

    if (aux === 1) {
      min = 1
    }

    return `Exibindo ${min}-${max} de ${data.totalDocs}`
  } else {
    return ''
  }
}
