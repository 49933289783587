import FileList from '../FileList'
import CustomTooltip from '../CustomTooltip'

import './index.scss'

const ChatBox = ({
  style,
  title,
  files,
  currentFile,
  setCurrentFile,
  description,
  headerActions,
  className,
  children,
  isFetchingFiles,
  topContent,
}) => {
  return (
    <div className={`chat__box ${className}`} style={style}>
      <div className='chat__box__header'>
        <div className='chat__box__header__content'>
          {title.toLowerCase() !== 'faça perguntas' ? (
            <CustomTooltip name={title}>
              <h3>{title}</h3>
            </CustomTooltip>
          ) : (
            <h3>{title}</h3>
          )}
          <p className='chat__box__header__text'>{description}</p>
        </div>
        {headerActions}
      </div>
      {files?.length > 0 && (
        <FileList
          files={files}
          currentFile={currentFile}
          setCurrentFile={setCurrentFile}
          isFetching={isFetchingFiles}
        />
      )}
      {topContent}
      <div className='chat__viewer' /* or className='pdf__viewer' */>{children}</div>
    </div>
  )
}

export default ChatBox
