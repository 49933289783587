import React, { useState } from 'react'
import { X, CheckCircle, AlertCircle, AlertOctagon, XCircle } from 'lucide-react'

// Components
import Spinner from '../../Spinner'

// Styles
import './index.scss'

const AlertIcon = ({ type }) => {
  if (type === 'success') {
    return <CheckCircle size={20} color='#2d6a00' />
  }
  if (type === 'info') {
    return <AlertCircle size={20} color='#16b1ff' />
  }
  if (type === 'warn') {
    return <AlertOctagon size={20} color='#7c5800' />
  }
  if (type === 'error') {
    return <XCircle size={20} color='#670300' />
  }
}

const Alert = ({
  type,
  title,
  text,
  canClose,
  containerStyle,
  contentStyle,
  textStyle,
  iconWrapperStyle,
  onClose,
}) => {
  const [isHidden, setIsHidden] = useState(false)

  function handleClose() {
    if (onClose) {
      onClose()
    } else {
      setIsHidden(true)
    }
  }

  return (
    <div
      className={`alert__container alert__container--${type} ${
        isHidden && 'alert__container--hidden'
      }`}
      style={{ ...containerStyle }}>
      <div
        className={`alert__icon-wrapper alert__icon-wrapper--${type}`}
        style={{ ...iconWrapperStyle }}>
        {type === 'promise' ? <Spinner size={18} /> : <AlertIcon type={type} />}
      </div>
      <div className='alert__content' style={{ ...contentStyle }}>
        <div className='alert__header'>
          <h3>{title}</h3>

          {canClose && (
            <button className='alert__close' type='action' onClick={handleClose}>
              <X size={12} color='#3B3B3B' />
            </button>
          )}
        </div>
        <p style={{ textStyle }}>{text}</p>
      </div>
    </div>
  )
}

export default Alert
