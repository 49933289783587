const initialState = {
  lastMessageDate: null,
  isMessageDetailOpen: false,
  messageDetail: {},
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'CHANGE_LAST_MESSAGE_DATE':
      return { ...state, lastMessageDate: action.payload }
    case 'CHANGE_MESSAGE_DETAIL':
      return { ...state, messageDetail: action.payload }
    case 'CHANGE_MESSAGE_DETAIL_OPEN':
      return { ...state, isMessageDetailOpen: action.payload }
    default:
      return state
  }
}

export default reducer
