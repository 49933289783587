import { useRef, useState } from 'react'
import CustomIcon from '../../../../components/CustomIcon'
import './index.scss'

const MediaPlayer = ({ src, mediaType, style }) => {
  const mediaRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [playbackRate, setPlaybackRate] = useState(1)
  const [showMedia, setShowMedia] = useState(false)

  const togglePlayPause = () => {
    if (isPlaying) {
      mediaRef.current.pause()
    } else {
      mediaRef.current.play()
    }
    setIsPlaying(!isPlaying)
  }

  const formatTime = time => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, '0')
    return `${minutes}:${seconds}`
  }

  const handleTimeUpdate = () => {
    setCurrentTime(mediaRef.current.currentTime)
  }

  const handleDurationChange = () => {
    setDuration(mediaRef.current.duration)
  }

  const handleSeek = event => {
    const newTime = (event.target.value / 100) * duration
    mediaRef.current.currentTime = newTime
    setCurrentTime(newTime)
  }

  const handlePlaybackRate = type => {
    if (type === 'increase') {
      if (playbackRate >= 2) return
      mediaRef.current.playbackRate = mediaRef.current.playbackRate + 0.25
      setPlaybackRate(playbackRate + 0.25)
    } else {
      if (playbackRate <= 0.25) return
      mediaRef.current.playbackRate = mediaRef.current.playbackRate - 0.25
      setPlaybackRate(playbackRate - 0.25)
    }
  }

  return (
    <div className='custom-player' style={style}>
      <div className='custom-player--content'>
        <div className='custom-player--content-header'>
          <h3>{mediaType === 'video' ? 'Vídeo' : 'Áudio'} da transcrição</h3>
          <div>
            <a href={src} download>
              <button>
                <CustomIcon icon={'DownloadCloud'} size={12} /> <span>Baixar Mídia</span>
              </button>
            </a>
            <button onClick={() => setShowMedia(!showMedia)}>
              <CustomIcon icon={showMedia ? 'ChevronUp' : 'ChevronDown'} size={12} />{' '}
              <span>{showMedia ? 'Esconder' : 'Mostrar'}</span>
            </button>
          </div>
        </div>
        {mediaType === 'video' && (
          <video
            style={showMedia ? {} : { display: 'none' }}
            ref={mediaRef}
            src={src}
            onTimeUpdate={handleTimeUpdate}
            onDurationChange={handleDurationChange}
            controls
          />
        )}
        {mediaType === 'audio' && (
          <div
            style={
              showMedia
                ? { display: 'flex', flexDirection: 'column', gap: '8px' }
                : { display: 'none' }
            }>
            <audio
              ref={mediaRef}
              src={src}
              onTimeUpdate={handleTimeUpdate}
              onDurationChange={handleDurationChange}
            />
            <div className='controls'>
              <div>
                <button onClick={() => (mediaRef.current.currentTime -= 10)}>
                  <CustomIcon icon='UndoDot' size={16} color='#64FEDA' />
                </button>
                <button onClick={togglePlayPause}>
                  {isPlaying ? (
                    <CustomIcon icon='Pause' size={16} color='#64FEDA' />
                  ) : (
                    <CustomIcon icon='Play' size={16} color='#64FEDA' />
                  )}
                </button>
                <button onClick={() => (mediaRef.current.currentTime += 10)}>
                  <CustomIcon icon='RedoDot' size={16} color='#64FEDA' />
                </button>
              </div>
              <div className='controls--playback'>
                <button onClick={() => handlePlaybackRate('decrease')}>
                  <CustomIcon icon='Minus' size={12} color='#64FEDA' strokeWidth={4} />
                </button>
                <span>{playbackRate}x</span>
                <button onClick={() => handlePlaybackRate('increase')}>
                  <CustomIcon icon='Plus' size={12} color='#64FEDA' strokeWidth={4} />
                </button>
              </div>
            </div>
            <div className='progress-bar'>
              <span>{formatTime(currentTime)}</span>
              <input
                type='range'
                value={(currentTime / duration) * 100 || 0}
                onChange={handleSeek}
                max='100'
              />
              <span>{formatTime(duration)}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MediaPlayer
