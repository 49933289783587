import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'

export default function ProtectedRoute({
  isPublic = false,
  isNotFound = false,
  isDeveloper = false,
}) {
  const loggedUserStringified = localStorage.getItem('user')
  const user = JSON.parse(loggedUserStringified)
  if (isPublic) {
    if (loggedUserStringified || isNotFound) return <Navigate to='/' />
    return (
      <>
        <Outlet />
        <Footer />
      </>
    )
  }

  // if (readDocs) {
  //   if (user && !user.features.readDocs) {
  //     toast.warning('Seu grupo não possui esta funcionalidade')
  //     return <Navigate to='/' />
  //   }
  // }

  // if (createDocs) {
  //   if (user && !user.features.createDocs) {
  //     toast.warning('Seu grupo não possui esta funcionalidade')
  //     return <Navigate to='/' />
  //   }
  // }

  // if (isDeveloper) {
  //   if (user && !user.features.isDeveloper) {
  //     toast.warning('Seu grupo não possui esta funcionalidade')
  //     return <Navigate to='/' />
  //   }
  // }

  if (!loggedUserStringified || isNotFound) return <Navigate to='/login' />

  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}
