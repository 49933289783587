import axios from 'axios'

async function createSignature(body) {
  try {
    let { data } = await axios.post('/subscription/subscribe', body)
    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}

async function cancel() {
  try {
    let { data } = await axios.post('/subscription/cancel')
    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}

async function updateSignature(body) {
  try {
    let { data } = await axios.put('/subscription/update', body)
    return data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export { createSignature, cancel, updateSignature }
