import React from 'react'

import * as Dialog from '@radix-ui/react-dialog'
import Button from '../DesignSystem/Button'
import CustomIcon from '../CustomIcon'
import Spinner from '../../components/Spinner'

import './index.scss'

const DialogDemo = ({
  children,
  openButton,
  title,
  description,
  confirmButton,
  confirmAction,
  loading,
  modalOpen,
  bgColor = '#1b1b1b',
  dialogDescriptionStyle = {},
  onOpenChange,
  customOpenButton,
  ...props
}) => {
  return (
    <Dialog.Root open={modalOpen} onOpenChange={onOpenChange} modal={false}>
      <Dialog.Trigger asChild>
        {customOpenButton ? (
          customOpenButton
        ) : (
          <Button
            fullWidth
            sizeIcon={openButton.sizeIcon ? openButton.sizeIcon : 24}
            icon={openButton.icon}
            color={openButton.color}
            type={openButton.type}
            textUppercase
            onClick={''}
            style={{
              border: '1px solid #64feda',
              fontSize: '10px',
              fontWeight: '700',
              lineHeight: '10px',
              borderRadius: '0',
              ...openButton.style,
            }}
            props>
            {openButton.text}
          </Button>
        )}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />
        <div className='dialog__overlay'>
          <Dialog.Content className='dialog__content' onInteractOutside={e => e.preventDefault()}>
            <Dialog.Title className='dialog__title'>
              {title}
              <Dialog.Description
                className='dialog__description'
                style={{ ...dialogDescriptionStyle }}>
                {description}
              </Dialog.Description>
            </Dialog.Title>
            {children}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* <Dialog.Close asChild> */}
              <div className='dialog__button'>
                {loading ? (
                  <Button
                    disabled
                    fullWidth
                    contained
                    color='green'
                    textUppercase
                    style={{
                      fontSize: '10px',
                      letterSpacing: '2px',
                      fontWeight: '700',
                      lineHeight: '10px',
                      height: '35px',
                    }}>
                    <Spinner color='#272929' size={20} />
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    contained
                    color='green'
                    onClick={() => confirmAction()}
                    textUppercase
                    style={{
                      fontSize: '10px',
                      letterSpacing: '2px',
                      fontWeight: '700',
                      lineHeight: '10px',
                      height: '35px',
                    }}>
                    {confirmButton}
                  </Button>
                )}
              </div>
              {/* </Dialog.Close> */}
            </div>
            <Dialog.Close asChild>
              <button className='close__button' aria-label='Close'>
                <CustomIcon icon={'X'} size={'20px'} />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default DialogDemo
