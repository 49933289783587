import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Button, Select } from '../../components/DesignSystem'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'
import useRecaptcha from '../../hooks/useRecaptcha'
import states from '../../constants/states.json'
import { createSignature } from '../../services/subscriptionServices'
import {
  creditCard,
  cvvValidator,
  expiredAt,
  formatDocument,
  onlyNumber,
  phone as phoneMask,
  zipCodeMask,
} from '../../constants/validate'
import './index.scss'
import CreditCard from '../../components/CreditCard'

function Payment() {
  const { user } = useSelector(state => state.app)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha()
  const [cardNumber, setCardNumber] = useState('')
  const [cardName, setCardName] = useState('')
  const [cardExpirationDate, setCardExpirationDate] = useState('')
  const [cardCvv, setCardCvv] = useState('')
  const [document, setDocument] = useState('')
  const [street, setStreet] = useState('')
  const [streetNumber, setStreetNumber] = useState('')
  const [neighborhood, setNeighborhood] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [phone, setPhone] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [cityOptions, setCityOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (selectedState) {
      let value = states.find(state => state.value === selectedState)
      setCityOptions(value.cities)
    }
  }, [selectedState])

  function verifySignatureData(e) {
    e.preventDefault()

    if (cardNumber === '') {
      toast.error('Número do cartão inválido')
      return
    }
    if (cardName === '') {
      toast.error('Nome do cartão inválido')
      return
    }
    if (cardExpirationDate === '') {
      toast.error('Data do cartão inválida')
      return
    }
    if (cardCvv === '') {
      toast.error('CVV do cartão inválido')
      return
    }
    if (document === '') {
      toast.error('Documentação inválida')
      return
    }
    if (street === '') {
      toast.error('Rua inválida')
      return
    }
    if (streetNumber === '') {
      toast.error('Número inválido')
      return
    }
    if (zipcode === '') {
      toast.error('CEP inválido')
      return
    }
    if (neighborhood === '') {
      toast.error('Bairro inválido')
      return
    }
    if (phone === '') {
      toast.error('Telefone inválido')
      return
    }
    if (selectedState === '') {
      toast.error('Estado inválido')
      return
    }
    if (selectedCity === '') {
      toast.error('Cidade inválida')
      return
    }
    if (capchaToken === '') {
      toast.error('Recaptcha inválido')
      return
    }

    setIsLoading(true)
    newSignature()
  }

  async function newSignature() {
    let body = {
      cardNumber: onlyNumber(cardNumber),
      expirationDate: cardExpirationDate,
      cvv: cardCvv,
      holderName: cardName,
      document,
      address: {
        street,
        streetNumber,
        zipCode: onlyNumber(zipcode),
        neighborhood,
        state: selectedState,
        city: selectedCity,
      },
      phone,
      'g-recaptcha-response': capchaToken,
    }

    try {
      await createSignature(body)
      setCardCvv('')
      setCardExpirationDate('')
      setCardName('')
      setCardNumber('')
      setDocument('')
      let newUser = user
      newUser.status = 'paid'
      dispatch({ type: 'CHANGE_USER', payload: newUser })
      localStorage.setItem('user', JSON.stringify(newUser))
      axios.defaults.headers.common['authentication'] = newUser.token
      toast.success('Assinatura efetuada com sucesso!')
      navigate('/lista-consultar-documentos')
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.errors[0].message)
    } finally {
      recaptchaRef.current.reset()
      setIsLoading(false)
    }
  }

  return (
    <form className='payment' onSubmit={verifySignatureData}>
      <div className='payment__container'>
        <h1>pagamento</h1>
      </div>
      <div className='payment__container'>
        <h2>Dados do cartão</h2>
        <div className='payment__card__container'>
          <CreditCard
            number={cardNumber}
            holderName={cardName}
            expirationDate={cardExpirationDate}
            cvv={cardCvv}
          />
          <div className='payment__card'>
            <Input
              placeholder='0000 0000 0000 0000'
              label='Número do cartão'
              value={cardNumber}
              onChange={value => setCardNumber(creditCard(value))}
              required
            />
            <Input
              placeholder='Nome e sobrenome'
              label='Nome no cartão'
              value={cardName}
              onChange={value => setCardName(value)}
              required
            />
            <Input
              placeholder='000.000.000-00'
              label='CPF/CNPJ'
              value={document}
              onChange={value => setDocument(formatDocument(value))}
              required
            />
            <div style={{ display: 'flex', gap: '16px' }}>
              <Input
                placeholder='MM/AA'
                label='Expira em'
                value={cardExpirationDate}
                onChange={value => setCardExpirationDate(expiredAt(value))}
                required
              />
              <Input
                placeholder='123'
                label='CVV'
                value={cardCvv}
                onChange={value => setCardCvv(cvvValidator(value))}
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className='payment__container'>
        <h2>Endereço de cobrança</h2>
        <div className='payment__card'>
          <Input
            placeholder='(xx)xxxxx-xxxx'
            label='Telefone'
            value={phone}
            onChange={value => setPhone(phoneMask(value))}
            required
          />
          <Input
            placeholder='Rua'
            label='Rua'
            value={street}
            onChange={value => setStreet(value)}
            required
          />
          <div style={{ display: 'flex', gap: '16px' }}>
            <Input
              placeholder='Número'
              label='Número'
              value={streetNumber}
              onChange={value => setStreetNumber(onlyNumber(value))}
              required
            />
            <Input
              placeholder='CEP'
              label='CEP'
              value={zipcode}
              onChange={value => setZipcode(zipCodeMask(value))}
              required
            />
          </div>
          <Input
            placeholder='Bairro'
            label='Bairro'
            value={neighborhood}
            onChange={value => setNeighborhood(value)}
            required
          />
          <div style={{ display: 'flex', gap: '16px' }}>
            <Select
              options={states}
              label='Estado'
              value={selectedState}
              onChange={setSelectedState}
              required
            />
            <Select
              options={cityOptions}
              label='Cidade'
              value={selectedCity}
              onChange={setSelectedCity}
              required
            />
          </div>
          <div className='recaptcha__holder'>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey='6LctzyEqAAAAAMyWvVKya5kg4ixSBXyuThCTNAaT'
              onChange={handleRecaptcha}
            />
          </div>
        </div>
      </div>
      <Button
        fullWidth
        contained
        color='green'
        type='submit'
        textUppercase
        loading={isLoading}
        style={{
          fontSize: '10px',
          fontWeight: '700',
          lineHeight: '10px',
          padding: '16px 32px',
          marginTop: '16px',
          width: '80%',
        }}>
        Confirmar
      </Button>
    </form>
  )
}

export default Payment
