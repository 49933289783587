import React from 'react'
import * as RadixPopover from '@radix-ui/react-popover'

import './index.scss'

function Popover({ open, onClose, trigger, content }) {
  return (
    <RadixPopover.Root open={open}>
      <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
      <RadixPopover.Content
        avoidCollisions
        collisionPadding={8}
        onInteractOutside={onClose}
        className='popover__content'>
        {content}
      </RadixPopover.Content>
    </RadixPopover.Root>
  )
}

export default Popover
