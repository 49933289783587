import React from "react";
import { Download } from 'lucide-react';
import {Button} from "../DesignSystem"
import { toast } from 'react-toastify'
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import "./index.scss";

/**
 * @param {Boolean} disabled 
 * @param {any} value 
 * @param {String} type - copy, download, edit
 * @param {Function} editfn - copy, download, edit
 * @param {String} size - copy, download, edit
 * @returns
 */
function ButtonFN({
  value,
  editfn = () => console.log('editfn is required'),
  type = 'default',
  size = '20px',
  className,
  documentName = "documento",
  ...props
}) {

  function handleCopy(e) {
    if(!value) return console.log('value is required');
    e.preventDefault()
    navigator.clipboard.writeText(value)
    toast.success('Mensagem copiada')
  }

  function handleEdit(e) {
    e.preventDefault()
    if(!value) return console.log('value is required');
    editfn()
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    title:{
      fontSize: 12,
      fontWeight:'bold',
      marginBottom: 5,
      color: '#1b1b1b',
    },
    paragraph: {
      fontSize: 12,
      marginBottom: 15,
      color: '#1b1b1b',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
  });

  const MyDoc = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
        {formatedValue.map(p => 
          <Text key={formatedValue.length} style={p.type === 'title' ? styles.title : styles.paragraph}>
            {p.text}
          </Text>
          
        )}
        </View>
      </Page>
    </Document>
  );

 function formatValue() {
  if(!value) return [];
  const array = value?.split(/\*\*(.*?)\*\*/g).filter(Boolean)
  const result = []

  for (let i = 0; i < array.length; i++) {
    const text = array[i].trim()
    const type = i % 2 === 0 ? "title" : "paragraph"
    result.push({ type, text })
  }
  return result
 }

 const formatedValue = formatValue()

  return (
    <>
      {type === 'copy' && <Button icon={'Copy'} onClick={handleCopy} sizeIcon={size} customClassName={className} props={props}/>}
      {type === 'edit' && <Button icon={'Edit'} onClick={handleEdit} sizeIcon={size} customClassName={className} props={props}/>}
      {type === 'download' && <Button sizeIcon={size} customClassName={className} props={props} 
      children={
      <PDFDownloadLink document={<MyDoc /> || null} fileName={`${documentName?.replace(/ /g, '_')}.pdf`}>
        {() => (<Download/>)}
      </PDFDownloadLink>}/>}
    </>
  );
}

export default ButtonFN;
