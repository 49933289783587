import React from 'react'
import * as AlertDialog from '@radix-ui/react-alert-dialog'
import Button from '../DesignSystem/Button'
import Spinner from '../Spinner'

import './index.scss'

const AlertDialogDemo = ({
  children,
  title,
  description,
  cancelButton,
  confirmButton,
  modalOpen,
  setModalOpen,
  confirmAction,
  loading,
}) => (
  <AlertDialog.Root open={modalOpen}>
    <AlertDialog.Trigger asChild>{children}</AlertDialog.Trigger>
    <AlertDialog.Portal>
      <AlertDialog.Overlay className='alert__dialog__overlay' />
      <AlertDialog.Content className='alert__dialog__content'>
        <AlertDialog.Title className='alert__dialog__title'>{title}</AlertDialog.Title>
        <AlertDialog.Description className='alert__dialog__description'>
          {description}
        </AlertDialog.Description>
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end', marginTop: '16px' }}>
          <div className='alert__dialog__buttons__container'>
            <AlertDialog.Cancel asChild>
              <Button
                fullWidth
                color='green'
                type='submit'
                onClick={() => setModalOpen('')}
                textUppercase
                disabled={loading}
                style={{
                  color: 'white',
                  border: '1px solid #64feda',
                  fontSize: '10px',
                  fontWeight: '700',
                  lineHeight: '10px',
                  height: '30px',
                }}>
                {cancelButton}
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action asChild>
              <Button
                fullWidth
                disabled={loading}
                contained
                color='green'
                type='submit'
                onClick={() => confirmAction()}
                textUppercase
                style={{
                  fontSize: '10px',
                  fontWeight: '700',
                  lineHeight: '10px',
                  height: '30px',
                }}>
                {loading ? <Spinner color='#272929' size={20} /> : confirmButton}
              </Button>
            </AlertDialog.Action>
          </div>
        </div>
      </AlertDialog.Content>
    </AlertDialog.Portal>
  </AlertDialog.Root>
)

export default AlertDialogDemo
