import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Calendar, ChevronDown, Eye, EyeOff } from 'lucide-react'
import _ from 'lodash'

import CustomIcon from '../../CustomIcon'
import { setMask } from '../../../constants/others'
import Spinner from '../../Spinner'

import './index.scss'

const debouncedOnChange = _.debounce((value, onChange) => {
  onChange(value)
}, 350)

/**
 * @param {String} value - Input value
 * @param {String} label - The label
 * @param {String} placeholder - Text that appears in the input when it has no value set
 * @param {String} type - Type of input
 * @param {Boolean} required - Flag indicating required
 * @param {Boolean} error - Flag indicating error
 * @param {Boolean} disabled - Flag indicating disabled
 * @param {Boolean} showLabel - Flag indicating hide the label
 * @param {Number} maxLength - Maximum number of characters of value
 * @param {Boolean} autoComplete - Enable/disable browser autocomplete feature
 * @param {Function} onBlur - Callback on blur
 * @param {Function} onChange - Callback on change
 * @param {Function} onPressEnter - Function to be called when pressing enter
 * @param {Function} onInvalid - Callback on invalid
 * @param {Object}  style - Styling object
 * @param {Boolean} isDateInput - Identifies if the input is being used in the date picker
 * @param {String} icon - Icon name
 *
 * @returns {React.ReactElement}
 */

const Input = forwardRef(
  (
    {
      value = '',
      label = 'Sample',
      placeholder = 'Sample',
      type = 'text',
      required = false,
      error = false,
      disabled = false,
      maxLength = 1000,
      autoComplete = true,
      onBlur = (value = '') => true,
      onChange = (value = '') => {},
      onPressEnter = () => {},
      onInvalid = () => {},
      style,
      isDateInput = false,
      isDatalist = false,
      list,
      id,
      showLabel = true,
      withoutBorder = false,
      mask,
      withDebounce = false,
      isLoading,
      icon = '',
    },
    ref
  ) => {
    const fallbackRef = useRef(null)
    const inputRef = ref || fallbackRef
    const [isFocus, setIsFocus] = useState(false)
    const [hasError, setHasError] = useState(error)
    const [hasValue, setHasValue] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [inputValue, setInputValue] = useState(value)

    useEffect(() => setHasError(error), [error])

    function handleClickTogglePassword() {
      setShowPassword(!showPassword)
    }

    function handleOnBlur(evt) {
      const value = evt.target.value
      if (onBlur) {
        const response = onBlur(value)
        if (response === false) {
          setHasError(true)
        } else {
          setHasError(false)
        }
      }
      setIsFocus(false)
    }

    function handleOnChange(evt) {
      let value
      if (isDateInput) {
        value = evt
      } else {
        value = evt.target.value
      }
      onChange && onChange(value)
      setHasError(error)
    }

    function handleOnChangeInputValue(evt) {
      const value = evt.target.value
      if (mask) {
        const newValue = setMask(mask, value)
        setInputValue(newValue)
      } else {
        setInputValue(value)
      }

      if (value.length <= maxLength) {
        debouncedOnChange(value, onChange)
      }
      setHasError(error)
    }

    function handleOnInvalid() {
      setHasError(true)
      onInvalid && onInvalid()
    }

    function handlePressEnter(evt) {
      if (evt.key === 'Enter') onPressEnter && onPressEnter()
    }

    function returnLabelClassName() {
      let currentClassName = 'input__label'
      if (withoutBorder) currentClassName += ' input__label--none'
      if (isFocus || hasValue || value || inputValue) currentClassName += ' input__label--open'
      if (isFocus) currentClassName += ' input__label--focus'
      return currentClassName
    }

    function returnClassName() {
      let currentClassName = 'input__wrapper'
      if (hasError) currentClassName += ' input__wrapper--error'
      if (disabled) currentClassName += ' input__wrapper--disabled'
      return currentClassName
    }

    function returnInputClassName() {
      let currentClassName = 'input'
      if (showLabel && !value && !inputValue) {
        currentClassName += ' input--hide-placeholder'}
      return currentClassName
    }

    return (
      <div className={returnClassName()} title={`${label}${required ? ' *' : ''}`}>
        {showLabel && (
          <span className={returnLabelClassName()}>
            {label}
            {required && ' *'}
          </span>
        )}
        <input
          ref={inputRef}
          className={returnInputClassName()}
          value={withDebounce ? inputValue : value}
          placeholder={placeholder}
          type={type === 'password' && showPassword ? 'text' : type}
          style={style}
          onMouseDown={() => setIsFocus(true)}
          onClick={() => setIsFocus(true)}
          onChange={withDebounce ? handleOnChangeInputValue : handleOnChange}
          onBlur={handleOnBlur}
          onFocus={() => setIsFocus(true)}
          onKeyPress={handlePressEnter}
          onAnimationStart={e =>
            e.animationName === 'onAutoFillStart' ? setHasValue(true) : setHasValue(false)
          }
          required={required}
          onInvalid={handleOnInvalid}
          maxLength={maxLength}
          disabled={disabled}
          autoComplete={autoComplete ? 'on' : 'do-not-autofill'}
          list={list && list}
          id={id && id}
        />
        {icon && (
          <span className='input__icon'>
            <CustomIcon icon={icon} size={24} />
          </span>
        )}
        {type === 'password' && (
          <span className={`input__password-eye`} onClick={handleClickTogglePassword}>
            {showPassword ? <EyeOff /> : <Eye />}
          </span>
        )}
        {isDateInput && <Calendar />}
        {isDatalist && <ChevronDown />}
        {isLoading && (
          <div className='input__spinner-container'>
            <Spinner size={20} />
          </div>
        )}
      </div>
    )
  }
)

export default Input
