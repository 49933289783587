const FAQ = [
  {
    title: 'Fiz uma feature, como posso inserí-la na documentação?',
    content:
      'O conteúdo desta documentação é dinamicamente gerado a partir do JSON que é exportado pelo Postman. Dessa maneira, caso você desenvolva uma nova feature no Spectter, o recomendado é que adicione as requisições no Workspace do Postman. Com isso, o conteúdo desta documentação será automaticamente atualizado. Caso não veja as alterações realizadas de imediato, tente recarregar a página ou limpar o cache do navegador.',
  },
]

const API_KEY = 'PMAK-65fddb33efc75600011bfabe-419ae9640201ea9739c22d2e14352c0d5f'

export { FAQ, API_KEY }
