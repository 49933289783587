import React from 'react'
import { Button } from '../../../../components/DesignSystem'

import './index.scss'

function Editor({ value, setValue, placeholder = '', handleSubmit, disabled = false }) {
  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSubmit()
    }
  }

  return (
    <div className='editor-container'>
      <h1 className='editor__title'>Comando de edição</h1>
      <div className='editor'>
        <textarea
          value={value}
          placeholder={placeholder}
          onChange={e => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <div className='editor__button-container'>
          <Button children='Gerar' contained onClick={handleSubmit} disabled={disabled} />
        </div>
      </div>
    </div>
  )
}

export default Editor
