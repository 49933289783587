import React from 'react'
import './index.scss'

function  TitleSpace({title, subtitle,}) {
  return (
    <div className='title-space-container'>
      <h1 className='title'>{title}</h1>
      <p className='subtitle'>{subtitle}</p>
    </div>
  )
}

export default  TitleSpace