import React, { useEffect, useState } from 'react'
import { ChevronDown } from 'lucide-react'

import './index.scss'

/**
 * @param {String} value - Input value
 * @param {String} label - The label
 * @param {Array.<{value: String, label: String}>} options - List options
 * @param {Boolean} required - Flag indicating required
 * @param {Boolean} error - Flag indicating error
 * @param {Boolean} disabled - Flag indicating disabled
 * @param {Boolean} showLabel - Flag indicating hide the label
 * @param {Function} onBlur - Callback on blur
 * @param {Function} onChange - Callback on change
 * @param {Function} onPressEnter - Function to be called when pressing enter
 * @param {Function} onInvalid - Callback on invalid
 * @param {Object}  style - Styling object
 *
 * @returns {React.ReactElement}
 */

const Select = ({
  value = '',
  label = 'Sample',
  options = [{ value: 'sample', label: 'Sample' }],
  required = false,
  error = false,
  disabled = false,
  onBlur = () => {},
  onChange = (value = '') => {},
  onPressEnter = () => {},
  onInvalid = () => {},
  onChangeError = () => {},
  showLabel = true,
  insideWrapper = false,
  hideOptionSelect = false,
  style,
}) => {
  const [isFocus, setIsFocus] = useState(false)
  const [hasError, setHasError] = useState(error)
  const [hasValue, setHasValue] = useState(false)

  useEffect(() => setHasError(error), [error])

  function handleOnBlur() {
    setIsFocus(false)
    onBlur && onBlur()
  }

  function handleOnChange(evt) {
    const value = evt.target.value
    onChange && onChange(value)
    setHasError(error)
    onChangeError && onChangeError(error)
  }

  function handleOnInvalid() {
    setHasError(true)
    onChangeError && onChangeError(true)
    onInvalid && onInvalid()
  }

  function handlePressEnter(evt) {
    if (evt.key === 'Enter') onPressEnter && onPressEnter()
  }

  function returnLabelClassName() {
    let currentClassName = 'select__label'
    if (isFocus || hasValue || value) currentClassName += ' select__label--open'
    if (isFocus) currentClassName += ' select__label--focus'
    return currentClassName
  }

  function returnSelectClassName() {
    let currentClassName = 'select'
    if (!value) currentClassName += ' select--empty'
    if (hasError && insideWrapper) currentClassName += ' select--wrapped-error'
    if (showLabel && !value) currentClassName += ' select--hide-placeholder'
    return currentClassName
  }

  function returnClassName() {
    let currentClassName = 'select__wrapper'
    if (hasError && !insideWrapper) currentClassName += ' select__wrapper--error'
    if (hasError && insideWrapper) currentClassName += ' select__wrapper--wrapped-error'
    if (disabled) currentClassName += ' select__wrapper--disabled'

    return currentClassName
  }

  return (
    <div className={returnClassName()} title={`${label}${required ? ' *' : ''}`}>
      {showLabel && (
        <span className={returnLabelClassName()}>
          {label}
          {required && ' *'}
        </span>
      )}
      <select
        className={returnSelectClassName()}
        value={value}
        style={{ style }}
        onMouseDown={() => setIsFocus(true)}
        onClick={() => setIsFocus(true)}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onKeyPress={handlePressEnter}
        onAnimationStart={e =>
          e.animationName === 'onAutoFillStart' ? setHasValue(true) : setHasValue(false)
        }
        required={required}
        onInvalid={handleOnInvalid}>
        {!hideOptionSelect && <option value=''>Selecione</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <ChevronDown />
    </div>
  )
}

export default Select
