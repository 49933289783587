import * as einValidator from 'ein-validator'

export const required = value => (value ? undefined : 'Campo Obrigatório')

export const selected = value => (value ? undefined : 'Opção Inexistente')

export const minLength = min => value =>
  value && value.length < min ? `Campo Incompleto` : undefined

export const minLength6 = minLength(6)

export const minLength13 = minLength(13)

export const minLength14 = minLength(14)

export const minLength18 = minLength(18)

export const minLength25 = minLength(25)

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? 'Email Inválido' : true

export const validateEmail = (value = '') =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? true : false

export const validatePassword = (password = '') =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/gm.test(password) ? true : false

export const phone = value => {
  const phoneOnlyNumber = onlyNumber(value)

  if (!value) {
    return value
  }
  if (value.length <= 2) {
    return `(${phoneOnlyNumber.slice(0, 2)}`
  }
  if (value.length <= 3) {
    return `(${phoneOnlyNumber.slice(0, 2)}`
  }
  if (value.length <= 8) {
    return `(${phoneOnlyNumber.slice(0, 2)})${phoneOnlyNumber.slice(2, 6)}`
  }
  if (value.length <= 13) {
    return `(${phoneOnlyNumber.slice(0, 2)})${phoneOnlyNumber.slice(2, 6)}-${phoneOnlyNumber.slice(
      6,
      11
    )}`
  }
  if (value.length <= 14) {
    return `(${phoneOnlyNumber.slice(0, 2)})${phoneOnlyNumber.slice(2, 7)}-${phoneOnlyNumber.slice(
      7,
      11
    )}`
  } else {
    return `(${phoneOnlyNumber.slice(0, 2)})${phoneOnlyNumber.slice(2, 7)}-${phoneOnlyNumber.slice(
      7,
      11
    )}`
  }
}

export const validateOab = value => (value.length !== 9 ? `Formato incorreto. Ex: 123123/RJ` : true)

export const onlyNumber = value => {
  return value.replace(/[^\d]/g, '')
}

export const validadeEin = value => {
  return einValidator.isValid(value)
}

export const ein = value => {
  const einOnlyNumber = onlyNumber(value)

  if (!value) {
    return value
  }
  if (einOnlyNumber.length <= 2) {
    return `${einOnlyNumber}`
  }
  if (einOnlyNumber.length <= 9) {
    return `${einOnlyNumber.slice(0, 2)}-${einOnlyNumber.slice(2, 9)}`
  }
}

export const formatDocument = value => {
  const documentOnlyNumber = onlyNumber(value)
  if (documentOnlyNumber.length <= 9) {
    value = ein(String(documentOnlyNumber))
  } else if (documentOnlyNumber.length > 9 && documentOnlyNumber.length <= 11) {
    value = cpf(String(documentOnlyNumber))
  } else if (documentOnlyNumber.length >= 12) {
    value = cnpj(String(documentOnlyNumber).slice(0, 14))
  }
  return value
}

export const cpf = value => {
  const cpfOnlyNumber = onlyNumber(value)

  if (cpfOnlyNumber.length <= 6) return cpfOnlyNumber.replace(/(\d{3})(\d{1})/g, '$1.$2')
  else if (cpfOnlyNumber.length <= 9)
    return cpfOnlyNumber.replace(/(\d{3})(\d{3})(\d{1})/g, '$1.$2.$3')
  else if (cpfOnlyNumber.length <= 11)
    return cpfOnlyNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/g, '$1.$2.$3-$4')
  else return cpfOnlyNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4').slice(0, 14)
}
// export const cpf = value => {
//   const cpfOnlyNumber = onlyNumber(value)

//   if (!value) {
//     return value
//   }
//   if (value.length <= 3) {
//     return `${cpfOnlyNumber}`
//   }
//   if (value.length <= 6) {
//     return `${cpfOnlyNumber.slice(0, 3)}.${cpfOnlyNumber.slice(3, 6)}`
//   }
//   if (value.length <= 9) {
//     return `${cpfOnlyNumber.slice(0, 3)}.${cpfOnlyNumber.slice(3, 6)}.${cpfOnlyNumber.slice(6, 9)}`
//   }
//   if (value.length <= 14) {
//     return `${cpfOnlyNumber.slice(0, 3)}.${cpfOnlyNumber.slice(3, 6)}.${cpfOnlyNumber.slice(6, 9)}-${cpfOnlyNumber.slice(9, 11)}`
//   }
// }

export const cnpj = value => {
  const cnpjOnlyNumber = onlyNumber(value)

  if (cnpjOnlyNumber.length <= 5) return cnpjOnlyNumber.replace(/(\d{2})(\d{1})/g, '$1.$2')
  else if (cnpjOnlyNumber.length <= 8)
    return cnpjOnlyNumber.replace(/(\d{2})(\d{3})(\d{1})/g, '$1.$2.$3')
  else if (cnpjOnlyNumber.length <= 12)
    return cnpjOnlyNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/g, '$1.$2.$3/$4')
  else if (cnpjOnlyNumber.length <= 14)
    return cnpjOnlyNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/g, '$1.$2.$3/$4-$5')
  else
    return cnpjOnlyNumber
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/g, '$1.$2.$3/$4-$5')
      .slice(0, 18)
}
// export const cnpj = value => {
//   const cnpjOnlyNumber = onlyNumber(value)

//   if (!value) {
//     return value
//   }
//   if (value.length <= 2) {
//     return `${cnpjOnlyNumber.slice(0, 2)}`
//   }
//   if (value.length <= 5) {
//     return `${cnpjOnlyNumber.slice(0, 2)}.${cnpjOnlyNumber.slice(2, 5)}`
//   }
//   if (value.length <= 8) {
//     return `${cnpjOnlyNumber.slice(0, 2)}.${cnpjOnlyNumber.slice(2, 5)}.${cnpjOnlyNumber.slice(5, 8)}`
//   }
//   if (value.length <= 12) {
//     return `${cnpjOnlyNumber.slice(0, 2)}.${cnpjOnlyNumber.slice(2, 5)}.${cnpjOnlyNumber.slice(5, 8)}/${cnpjOnlyNumber.slice(8, 12)}`
//   }
//   if (value.length <= 14) {
//     return `${cnpjOnlyNumber.slice(0, 2)}.${cnpjOnlyNumber.slice(2, 5)}.${cnpjOnlyNumber.slice(5, 8)}/${cnpjOnlyNumber.slice(8, 12)}-${cnpjOnlyNumber.slice(12, 14)}`
//   }
// }

export const unformatDocument = value => {
  return (value = value.replace(/(\.|\/|)/g, ''))
}

export const expiredAt = value => {
  const expiredAtOnlyNumber = onlyNumber(value)

  if (!value) {
    return value
  }
  if (value.length <= 2) {
    return `${expiredAtOnlyNumber}`
  } else {
    return `${expiredAtOnlyNumber.slice(0, 2)}/${expiredAtOnlyNumber.slice(2, 4)}`
  }
}

export const creditCard = value => {
  const creditCardOnlyNumber = onlyNumber(value)
  if (!value) {
    return value
  }
  if (value.length <= 4) {
    return `${creditCardOnlyNumber}`
  }
  if (value.length <= 8) {
    return `${creditCardOnlyNumber.slice(0, 4)}.${creditCardOnlyNumber.slice(4, 8)}`
  }
  if (value.length <= 12) {
    return `${creditCardOnlyNumber.slice(0, 4)}.${creditCardOnlyNumber.slice(
      4,
      8
    )}.${creditCardOnlyNumber.slice(8, 12)}`
  } else {
    return `${creditCardOnlyNumber.slice(0, 4)}.${creditCardOnlyNumber.slice(
      4,
      8
    )}.${creditCardOnlyNumber.slice(8, 12)}.${creditCardOnlyNumber.slice(12, 16)}`
  }
}

export const cvc = value => {
  if (value.length <= 3) {
    return value
  } else {
    return value.slice(0, value.length - 1)
  }
}

export const formatDate = (date, includeTime = true) => {
  if (!date) {
    return null
  }

  let year, month, day, hours, minutes

  if (includeTime) {
    date = new Date(date)

    day = date.getDate()
    month = date.getMonth() + 1
    year = date.getFullYear()
    hours = date.getHours()
    minutes = date.getMinutes()
  } else {
    date = new Date(date).toLocaleString('pt-BR', { timeZone: 'Europe/London' })

    const splitDate = date.split(' ')[0].split('/')
    const splitTime = date.split(' ')[1].split(':')

    day = splitDate[0]
    month = splitDate[1]
    year = splitDate[2]
    hours = splitTime[0]
    minutes = splitTime[1]
  }

  if (year.length > 4) year = year.slice(0, 4)
  if (month.toString().length < 2) month = '0' + month
  if (day.toString().length < 2) day = '0' + day

  if (day.toString().length === 1) day = '0' + day

  if (month.toString().length === 1) month = '0' + month

  if (hours.toString().length === 1) hours = '0' + hours

  if (minutes.toString().length === 1) minutes = '0' + minutes

  return `${day}/${month}/${year}` + (includeTime ? ` ${hours}:${minutes}` : '')
}

export const maskDate = (value, showHours = false) => {
  if (!value) return
  let v = value.replace(/\D/g, '').slice(0, 20)
  if (v.length >= 8 && showHours) {
    return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4, 8)} ${v.slice(8, 10)}:${v.slice(10, 12)}`
  } else if (v.length >= 5) {
    return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`
  } else if (v.length >= 3) {
    return `${v.slice(0, 2)}/${v.slice(2)}`
  }
  return v
}

export const base64 = bytes => {
  const byteCharacters = window.atob(bytes)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: 'application/pdf' })
  return file
}

export const lawsuitNumber = value => {
  const lawsuitNumberOnlyNumber = onlyNumber(value)

  if (!value) {
    return value
  }
  if (value.length <= 7) {
    return `${lawsuitNumberOnlyNumber.slice(0, 7)}`
  }
  if (value.length <= 10) {
    return `${lawsuitNumberOnlyNumber.slice(0, 7)}-${lawsuitNumberOnlyNumber.slice(7, 9)}`
  }
  if (value.length <= 15) {
    return `${lawsuitNumberOnlyNumber.slice(0, 7)}-${lawsuitNumberOnlyNumber.slice(
      7,
      9
    )}.${lawsuitNumberOnlyNumber.slice(9, 13)}`
  }
  if (value.length <= 17) {
    return `${lawsuitNumberOnlyNumber.slice(0, 7)}-${lawsuitNumberOnlyNumber.slice(
      7,
      9
    )}.${lawsuitNumberOnlyNumber.slice(9, 13)}.${lawsuitNumberOnlyNumber.slice(13, 14)}`
  }
  if (value.length <= 20) {
    return `${lawsuitNumberOnlyNumber.slice(0, 7)}-${lawsuitNumberOnlyNumber.slice(
      7,
      9
    )}.${lawsuitNumberOnlyNumber.slice(9, 13)}.${lawsuitNumberOnlyNumber.slice(
      13,
      14
    )}.${lawsuitNumberOnlyNumber.slice(14, 16)}`
  }

  return `${lawsuitNumberOnlyNumber.slice(0, 7)}-${lawsuitNumberOnlyNumber.slice(
    7,
    9
  )}.${lawsuitNumberOnlyNumber.slice(9, 13)}.${lawsuitNumberOnlyNumber.slice(
    13,
    14
  )}.${lawsuitNumberOnlyNumber.slice(14, 16)}.${lawsuitNumberOnlyNumber.slice(16, 20)}`
}

export const removeAccents = value =>
  value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9; ªº-]/gi, '')

export const onlyLetters = value =>
  value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z ]/gi, '')

export const oab = value =>
  value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9]/gi, '')
    .toUpperCase()

export const currency = (value, decimals = true) => {
  if (decimals) {
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})$/, ',$1')
    value = value.replace(/(\d+)(\d{3},\d{2})$/g, '$1.$2')
  }

  let qtdLoop = (value.length - 3) / 3
  let count = 0
  while (qtdLoop > count) {
    count++
    value = value.replace(/(\d+)(\d{3}.*)/, '$1.$2')
  }
  value = value.replace(/^(0)(\d)/g, '$2')

  return value
}

export const floatToCurrency = (value = 0.0, prefix = true) => {
  const result = value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  if (!prefix) {
    return result.replace('R$', '')
  }
  return result
}

export const hourMinute = (value = '') => {
  let valueOnlyNumber = String(onlyNumber(value))
  value = valueOnlyNumber

  if (valueOnlyNumber.length <= 4) {
    value = value.replace(/^(\d{2})(\d{1,2})$/, '$1:$2')
  } else if (valueOnlyNumber.length > 4) {
    value = value.slice(0, -1).replace(/^(\d{2})(\d{2})$/, '$1:$2')
  }

  if (valueOnlyNumber[0] > 2 || valueOnlyNumber[1] > 4 || valueOnlyNumber[2] > 5)
    return value.slice(0, -1)
  return value
}

export const hourMinuteSecond = (value = '') => {
  let valueOnlyNumber = String(onlyNumber(value))
  value = valueOnlyNumber

  if (valueOnlyNumber.length <= 4) {
    value = value.replace(/^(\d{2})(\d{1,2})$/, '$1:$2')
  } else if (valueOnlyNumber.length <= 6) {
    value = value.replace(/^(\d{2})(\d{2})(\d{1,2})$/, '$1:$2:$3')
  } else if (valueOnlyNumber.length > 6) {
    value = value.slice(0, -1).replace(/^(\d{2})(\d{2})(\d{2})$/, '$1:$2:$3')
  }

  if (
    valueOnlyNumber[0] > 2 ||
    valueOnlyNumber[1] > 4 ||
    valueOnlyNumber[2] > 5 ||
    valueOnlyNumber[4] > 5
  )
    return value.slice(0, -1)
  return value
}

const multiplica = (array1, array2) => {
  let arrayTotal = []
  for (let i = 0; i < array1.length; i++) {
    arrayTotal.push(array1[i] * array2[i])
  }
  return arrayTotal
}
const soma = array => {
  let total = 0
  for (let i = 0; i < array.length; i++) {
    total = total + array[i]
  }
  return total
}
const valorRepetido = value => {
  return value.split('').every(char => char === value[0])
}
export const validateCpf = value => {
  value = String(value)

  const chave1 = [10, 9, 8, 7, 6, 5, 4, 3, 2]
  const chave2 = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2]

  let arrayCpf = []

  if (value.length === 14) {
    arrayCpf = value.split('.').join('').split('-')
  } else if (value.length === 11) {
    arrayCpf = [value.slice(0, 9), value.slice(9)]
  } else {
    return false
  }

  if (valorRepetido(arrayCpf.join(''))) return false

  const mod = value => (value * 10) % 11
  const verificar10ou11 = value => (value === 10 || value === 11 ? 0 : value)

  let resto1 = verificar10ou11(mod(soma(multiplica(arrayCpf[0], chave1))))
  let resto2 = verificar10ou11(mod(soma(multiplica(arrayCpf[0] + arrayCpf[1][0], chave2))))

  if (String(resto1) + String(resto2) === arrayCpf[1]) {
    return true
  } else {
    return false
  }
}
export const validateCnpj = value => {
  value = String(value)

  const chave1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  const chave2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

  let arrayCnpj = []

  if (value.length === 18) {
    arrayCnpj = value.split('.').join('').replace('/', '').split('-')
  } else if (value.length === 14) {
    arrayCnpj = [value.slice(0, 12), value.slice(12)]
  } else {
    return false
  }

  if (valorRepetido(arrayCnpj.join(''))) return false

  const mod = value => value % 11
  const menorQue2 = value => (value < 2 ? 0 : 11 - value)

  let resto1 = menorQue2(mod(soma(multiplica(arrayCnpj[0], chave1))))
  let resto2 = menorQue2(mod(soma(multiplica(arrayCnpj[0] + arrayCnpj[1][0], chave2))))

  if (String(resto1) + String(resto2) === arrayCnpj[1]) {
    return true
  } else {
    return false
  }
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const cvvValidator = value => {
  if (value.length <= 3) {
    return value
  } else {
    return value.slice(0, value.length - 1)
  }
}

export const zipCodeMask = value => {
  let cep = value.replace(/\D/g, '')
  if (cep.length > 5) {
    cep = cep.substring(0, 5) + '-' + cep.substring(5, 8)
  }
  return cep
}
