import { combineReducers } from 'redux'
import appReducer from './app'
import chatReducer from './chat'
import transcriptReducer from './transcript'

const reducers = combineReducers({
  app: appReducer,
  chat: chatReducer,
  transcript: transcriptReducer,
})

export default reducers
