import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import React from 'react'

import CustomIcon from '../CustomIcon'

import './index.scss'

/**
 * DropDown component
 * @param {{
 * children: React.Element
 * options: [{label:string, action: function}]
 * container: ?HTMLElement
 * className: ?string
 * noArrow: ?boolean
 * disabled: ?boolean
 * }} props Props
 * @returns {React.Element} - the DropDown component
 */
const DropDown = ({
  children,
  className,
  options,
  noArrow = false,
  container,
  disabled = false,
}) => {
  function triggerClassName() {
    let string = ['dropdown__trigger']
    if (noArrow) string.push('dropdown__trigger--no-arrow')
    if (className) string.push(className)
    return string.join(' ')
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild disabled={disabled}>
        <button className={triggerClassName()} aria-label={children} disabled={disabled}>
          {children}
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal container={container || document.body}>
        <DropdownMenu.Content className='dropdown__content' align='end' sideOffset={5}>
          {options.map(({ label, action, icon }, index) => (
            <DropdownMenu.Item key={index} onClick={action} className='dropdown__content__item'>
              <CustomIcon size={16} icon={icon} />{' '}
              <div dangerouslySetInnerHTML={{ __html: label }} />
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default DropDown
