const initialState = {
  currentTranscript: '',
  replacements: [],
  highlightedTranscript: '',
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'CHANGE_TRANSCRIPT':
      return { ...state, currentTranscript: action.payload }
    case 'CHANGE_REPLACEMENT':
      const currentReplacements = state.replacements
      const existingReplacement = currentReplacements.find(
        replacement => replacement.label === action.payload.label,
      )
      if (existingReplacement) {
        const existingIndex = currentReplacements.indexOf(existingReplacement)
        currentReplacements[existingIndex] = action.payload
        return { ...state, replacements: currentReplacements }
      }
      return { ...state, replacements: [...state.replacements, action.payload] }
    case 'HIGHLIGHT_TRANSCRIPT':
      return { ...state, highlightedTranscript: action.payload }
    default:
      return state
  }
}

export default reducer
