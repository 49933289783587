import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { ChevronLeft, ChevronRight } from 'lucide-react';

import { maskDate } from '../../../constants/validate';
import { Input } from '../index';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

registerLocale('ptBR', ptBR);

/**
 * @param {String} value - DateTime value
 * @param {Date} minDate - Minimum limit for the date
 * @param {Date} maxDate - Maximum limit for the date
 * @param {Date} minTime - Minimum limit for the time
 * @param {Date} maxTime - Maximum limit for the time
 * @param {Boolean} showHours - Defines if the value contains time
 * @param {String} label - The label
 * @param {Boolean} showLabel - Flag indicating hide the label
 * @param {String} placeholder - Text that appears in the input when it has no value set
 * @param {Boolean} required - Flag indicating required
 * @param {Boolean} error - Flag indicating error
 * @param {Boolean} disabled - Flag indicating disabled
 * @param {Function} onBlur - Callback on blur
 * @param {Function} onChange - Callback on change
 *
 * @returns {React.ReactElement}
 */

function CustomReactDatePicker({
  value = '',
  minDate = new Date('1900-01-01'),
  maxDate = new Date('2999-12-31'),
  minTime = null,
  maxTime = null,
  showHours = false,
  label = 'Sample',
  placeholder = '23/06/2016',
  required = false,
  error = false,
  disabled = false,
  onBlur = (value = '') => true,
  onChange = (value = '') => {},
  showLabel = true,
  monthsShown = 1,
  customHeader,
  hasInput = true,
  inline = false,
  startDate = '',
  endDate = '',
  openToDate = '',
  isoString = false,
  labelOpen = false,
  maxWidth,
  halfWidth,
}) {
  const [selectedValue, setSelectedValue] = useState('');
  const [hasError, setHasError] = useState(error);
  const [showYears, setShowYears] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (showHours && value.length >= 16) {
      handleChangeValueField(value, 'string');
    }
    if (!showHours && value.length >= 10) {
      handleChangeValueField(value, 'string');
    }
    
    // eslint-disable-next-line
  }, [showHours]);

  useEffect(() => {
    function checkViewport() {
      if (window.innerWidth <= 720) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    checkViewport();

    window.addEventListener('resize', checkViewport);

    return () => {
      window.removeEventListener('resize', checkViewport);
    };
  }, []);

  function handleChangeValueField(value, type, withTime = false) {
    if (type === 'string') {
      onChange(value);
      if (value.length === 0) {
        setSelectedValue('');
        return;
      }

      const offSet = new Date().getTimezoneOffset() / 60;
      const currentDate = new Date(value);
      const currentDateFormatted = currentDate.setHours(
        currentDate.getHours() + offSet,
        currentDate.getMinutes(),
        currentDate.getSeconds(),
        currentDate.getMilliseconds(),
      );

      setSelectedValue(currentDateFormatted);
      if (isoString) {
        setSelectedValue(currentDateFormatted);
      } else {
        if (withTime) {
          setSelectedValue(stringToDateTime(value));
        } else {
          setSelectedValue(stringToDate(value));
        }
      }
    } else {
      onChange(moment(value).format('DD/MM/YYYY'));
      setSelectedValue(value);
    }
  }

  function handleChange(date, evt) {
    if (evt && evt.target.className.includes('input')) return;

    if (date) {
      handleChangeValueField(date, 'date');
    }

    setHasError(false);
  }

  function handleChangeRaw(evt) {
    if (evt.target.className.includes('react-datepicker__day')) return;

    const valueOriginal = evt.target.value;
    const value = valueOriginal.replaceAll(/([^0-9])\D+/g, '');

    if (showHours && value.length >= 16 && moment(value, 'YYYY-MM-DD HH:mm').isValid()) {
      handleChangeValueField(value, 'string', true);
    } else if (!showHours && value.length >= 10 && moment(value, 'YYYY-MM-DD').isValid()) {
      handleChangeValueField(value, 'string');
    } else {
      setHasError(true);
    }

    if (value.length === 0) {
      handleChangeValueField('', 'string');
      if (required) {
        setHasError(true);
      }
    }
  }

  function stringToDateTime(value) {
    const [hour, minute] = value.split(' ')[1].split(':');
    const [day, month, year] = value.split(' ')[0].split('/');
    return new Date(Number(year), month - 1, Number(day), hour, minute);
  }

  function stringToDate(value) {
    const [day, month, year] = value.split('/');
    return new Date(Number(year), month - 1, Number(day));
  }

  function handleOnBlur(date) {
    if (required && !moment(date, 'YYYY-MM-DD').isValid()) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }

  const handleCalendarOpen = () => {
    document.addEventListener(
      'touchstart',
      (event) => {
        event.stopPropagation();
      },
      true,
    );
  };

  function returnClassName() {
    let className = 'container__date-picker';

    if (customHeader) {
      className += ' container__date-picker--custom-header';
    }

    return className;
  }

  return (
    <div className={returnClassName()} style={maxWidth ? {width: '100%'} : halfWidth ? {width: "calc(50% - 6px)"} : {}}>
      <ReactDatePicker
        locale="ptBR"
        placeholderText={placeholder}
        selected={selectedValue}
        dateFormat={showHours ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
        timeFormat="HH:mm"
        showTimeSelect={showHours}
        required={required}
        formatWeekDay={(nameOfDay) => {
          let firstLetter = nameOfDay.charAt(0).toUpperCase();
          return firstLetter; // + nameOfDay.slice(1, 3)
        }}
        minDate={minDate}
        maxDate={maxDate}
        minTime={minTime}
        maxTime={maxTime}
        startDate={startDate}
        endDate={endDate}
        monthsShown={monthsShown}
        onChange={handleChange}
        onChangeRaw={handleChangeRaw}
        onBlur={handleOnBlur}
        openToDate={openToDate ? openToDate : undefined}
        calendarClassName="custom-calendar"
        timeClassName={() => 'custom-time'}
        dayClassName={() => 'custom-day'}
        popperClassName="custom-picker"
        popperPlacement="bottom-end"
        showPopperArrow={false}
        disabled={disabled}
        fixedHeight
        timeCaption="Hora"
        inline={inline}
        onCalendarOpen={handleCalendarOpen}
        customInput={
          hasInput ? (
            <CustomInput
              label={label}
              error={hasError}
              showHours={showHours}
              showLabel={showLabel}
              labelOpen={labelOpen}
            />
          ) : undefined
        }
        renderCustomHeader={({ ...props }) =>
          customHeader ? (
            customHeader(props)
          ) : (
            <CustomHeader onClickData={() => setShowYears(!showYears)} {...props} />
          )
        }
        // showMonthYearPicker={showYears}
        withPortal={isMobile} // use mobile
      />
    </div>
  );
}

const CustomHeader = ({
  onClickData,
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <div className="custom-header">
      <span onClick={onClickData}>{moment(date).format('MMMM YYYY')}</span>
      <div>
        <button
          onClick={(e) => {
            e.preventDefault();
            decreaseMonth();
          }}
          disabled={prevMonthButtonDisabled}
        >
          <ChevronLeft />
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            increaseMonth();
          }}
          disabled={nextMonthButtonDisabled}
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

const CustomInput = forwardRef(({ value, showHours, onClick, showLabel, labelOpen, ...props }, ref) => {
  return (
    <div onClick={onClick}>
      <Input
        isDateInput
        ref={ref}
        value={maskDate(value, showHours)}
        showLabel={showLabel}
        labelOpen={labelOpen}
        {...props}
      />
    </div>
  );
});

export default CustomReactDatePicker;
