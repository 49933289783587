import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { createDocument } from '../../services/documentsServices'
import { useQueryClient } from '@tanstack/react-query'

import NotificationForm from './components/NotificationForm'
import DocumentGenerator from '../../components/DocumentGenerator'
import TitleSpace from '../../components/TitleSpace'
import { Button } from '../../components/DesignSystem'

import './index.scss'

function Notifications() {
  const {
    user: { token },
  } = useSelector(state => state.app)

  const [step, setStep] = useState(1)
  const [result, setResult] = useState('')
  const [documentId, setDocumentId] = useState(null)
  const [isDone, setIsDone] = useState(false)
  const [prompt, setPrompt] = useState('')
  const queryClient = useQueryClient()

  async function createNotification() {
    try {
      await createDocument(prompt, 'notification', false, false, token, setResult, setDocumentId)
      setIsDone(true)
      queryClient.invalidateQueries(['usage-quantity'])
    } catch (error) {
      console.log(error)
      const errorContent = JSON.stringify(error)
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
          <Button
            icon='Copy'
            onlyIcon
            onClick={() => {
              navigator.clipboard.writeText(errorContent)
              toast.success('Erro copiado para área de transferência')
            }}
          />
        </div>
      )
    }
  }

  return (
    <div className='notifications-container'>
      {step === 1 && (
        <NotificationForm
          setStep={setStep}
          createNotification={createNotification}
          setPrompt={setPrompt}
        />
      )}

      {step === 2 && (
        <>
          <TitleSpace
            title='Notificação'
            subtitle={
              'Confira o texto criado pela inteligência artificial e edite caso necessário. Se quiser inserir anexos na sua notificação, siga estas instruções.'
            }
          />
          <DocumentGenerator result={result} documentId={documentId} isDone={isDone} />
        </>
      )}
    </div>
  )
}

export default Notifications
