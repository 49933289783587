import axios from 'axios'
import { useState, useEffect } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

// Components
import { PlusCircle } from 'lucide-react'
import Spinner from '../../components/Spinner'
import DialogDemo from '../../components/DialogDemo'
import Input from '../../components/DesignSystem/Input'
import TextArea from '../../components/DesignSystem/TextArea'
import ArgumentCard from './ArgumentCard/ArgumentCard'
import { Button } from '../../components/DesignSystem'
import DynamicLoading from '../../components/DynamicLoading'

// Services
import {
  addArgumentToComplaint,
  getComplaintDetails,
  getComplaintFileURL,
  updateComplaintFullContent,
} from '../../services/documentsServices'

// Styles
import './index.scss'

function ComplaintDetails() {
  const [modalOpen, setModalOpen] = useState('')

  // Arguments States
  const [newArgument, setNewArgument] = useState({ title: '', thesis: '' })
  const [isGeneratingFullContent, setIsGeneratingFullContent] = useState(false)

  // Full Content States
  const [editCommand, setEditCommand] = useState('')
  const [currentVersion, setCurrentVersion] = useState(0)
  const [isDownloading, setIsDownloading] = useState(false)

  const queryClient = useQueryClient()
  const { complaintId } = useParams()
  const { user } = useSelector(state => state.app)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { data, isLoading } = useQuery({
    queryKey: ['complaint-detail', complaintId],
    queryFn: async () => {
      const { data } = await getComplaintDetails(complaintId)

      return data
    },
    onError: () => toast.error('Erro ao buscar detalhes da petição'),
    enabled: !!user.token,
  })

  if (isLoading) {
    return (
      <main className='complaints-details'>
        <Spinner />
      </main>
    )
  }

  async function addArgument() {
    try {
      await addArgumentToComplaint(complaintId, newArgument)
      queryClient.invalidateQueries('complaint-detail')
      toast.success('Argumento adicionado com sucesso')
      setModalOpen('')
    } catch (error) {
      console.log(error)
      toast.error(
        'Erro ao adicionar argumento. Verifique se os campos estão preenchidos corretamente',
      )
    }
  }

  async function generateFullContent() {
    try {
      setIsGeneratingFullContent(true)
      await updateComplaintFullContent({ complaintId })
      queryClient.invalidateQueries('complaint-detail')
      toast.success('Petição gerada com sucesso')
      window.scrollTo(0, 0)
    } catch (error) {
      console.log(error)
      toast.error('Erro ao gerar petição. Por favor, tente novamente mais tarde')
    } finally {
      setIsGeneratingFullContent(false)
    }
  }

  async function rewriteComplaint() {
    try {
      setIsGeneratingFullContent(true)
      await updateComplaintFullContent({
        complaintId,
        complaintIndex: currentVersion,
        instructions: editCommand,
      })
      queryClient.invalidateQueries('complaint-detail')
      toast.success('Petição editada com sucesso')
      setCurrentVersion(data.fullContent.length)
      setEditCommand('')
      window.scrollTo(0, 0)
    } catch (error) {
      console.log(error)
      toast.error('Erro ao editar petição. Por favor, tente novamente mais tarde')
    } finally {
      setIsGeneratingFullContent(false)
    }
  }

  async function downloadComplaint() {
    try {
      setIsDownloading(true)
      const fileURL = await getComplaintFileURL({ complaintId, complaintIndex: currentVersion })
      const response = await axios.get(fileURL, { responseType: 'blob' })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `peticao-${new Date().toLocaleDateString()}.docx`)
      document.body.appendChild(link)
      link.click()

      window.URL.revokeObjectURL(url)
      link.remove()
    } catch (error) {
      console.log(error)
      toast.error('Erro ao baixar petição. Por favor, tente novamente mais tarde')
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <main className='complaints-details'>
      {data?.status === 'arguments' ? (
        <>
          <h1>Edição dos Argumentos da Petição</h1>
          <section className='arguments__container'>
            {data?.arguments?.map(argument => (
              <ArgumentCard key={argument._id} complaintId={complaintId} {...argument} />
            ))}
            <button className='add-argument--button' onClick={() => setModalOpen('add-argument')}>
              <PlusCircle color='#1ae5b5' size={32} />
              Adicionar Argumento
            </button>
          </section>
          <DialogDemo
            customOpenButton
            modalOpen={modalOpen === 'add-argument'}
            onClose={() => setModalOpen('')}
            title='Adicionar Argumento'
            description={'Adicione um novo argumento para ser incorporado à Petição'}
            confirmButton={'Adicionar'}
            confirmAction={addArgument}
            onOpenChange={e => setModalOpen(e ? 'add-argument' : '')}>
            <Input
              placeholder='Ex: Responsabilidade Objetiva do Estado'
              label='Título'
              value={newArgument.title}
              onChange={e => setNewArgument({ ...newArgument, title: e })}
              required
            />
            <TextArea
              label='Tese'
              hasLabel
              placeholder={
                'Ex: De acordo com o art. 37, § 6º, da Constituição Federal, o Estado é responsável por danos causados aos particulares em decorrência de sua atuação, independentemente de culpa, ou seja, basta que se comprove o nexo causal entre a ação ou omissão do Estado e o dano sofrido.'
              }
              value={newArgument.thesis}
              onChange={e => setNewArgument({ ...newArgument, thesis: e })}
              maxLength={2000}
              required
            />
          </DialogDemo>
          <Button contained fullWidth onClick={generateFullContent}>
            {isGeneratingFullContent ? <Spinner size={24} /> : 'Gerar Petição'}
          </Button>
          {isGeneratingFullContent && (
            <DynamicLoading
              title='Gerando petição'
              subtitle='Estamos trabalhando na criação da sua petição. Por favor, não feche esta janela.'
              loadingMessage='Preparando a petição com atenção aos detalhes...'
            />
          )}
        </>
      ) : (
        <>
          <h1>Detalhes da Petição</h1>
          <nav className='complaint-version__nav'>
            <div className='complaint-version--list'>
              {data.fullContent.length > 1 &&
                data.fullContent.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentVersion(index)}
                    className={
                      index === currentVersion ? 'pagination__card--current' : 'pagination__card'
                    }>
                    Versão {index + 1}
                  </button>
                ))}
            </div>
            <div className='complaint-version--actions'>
              <Button
                type='button'
                icon='Copy'
                sizeIcon={20}
                onClick={() => {
                  navigator.clipboard.writeText(data.fullContent[currentVersion])
                  toast.success('Petição copiada para a área de transferência')
                }}
              />
              <Button
                type='button'
                onClick={downloadComplaint}
                icon={isDownloading ? undefined : 'Download'}
                sizeIcon={20}
                loading={isDownloading}
              />
            </div>
          </nav>
          <section className='complaint-content__container'>
            <ReactMarkdown remarkPlugins={[remarkGfm]} className='markdown__container'>
              {data.fullContent[currentVersion] || data.fullContent[0]}
            </ReactMarkdown>
          </section>
          <section className='complaint-edit__container'>
            <h3>Edite a petição</h3>
            <TextArea
              label='Comando de edição'
              hasLabel
              placeholder={
                'Ex: Faça menção a Caio Mário da Silva Pereira, em sua obra Instituições de Direito Civil, para fundamentar a tese de responsabilidade objetiva do Estado.'
              }
              value={editCommand}
              onChange={e => setEditCommand(e)}
              maxLength={2000}
              required
            />
            <Button
              disabled={isGeneratingFullContent}
              contained
              fullWidth
              onClick={rewriteComplaint}>
              {isGeneratingFullContent ? <Spinner size={24} /> : 'Editar Petição'}
            </Button>
          </section>
          {isGeneratingFullContent && (
            <DynamicLoading
              title='Editando petição'
              subtitle='Estamos trabalhando na edição da sua petição. Por favor, não feche esta janela.'
              loadingMessage='Editando a petição com atenção aos detalhes...'
            />
          )}
        </>
      )}
    </main>
  )
}

export default ComplaintDetails
