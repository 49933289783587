import { useLocation } from 'react-router-dom'
import PrivacyNote from '../../assets/Aviso de Privacidade_Spectter_VF.pdf'
import TermsOfUse from '../../assets/Termos_de_Uso_Spectter_VF.pdf'
import './index.scss'

function Terms() {
  const { pathname } = useLocation()
  if (pathname === '/aviso-de-privacidade') {
    return <iframe src={PrivacyNote} title='Aviso de Privacidade' />
  } else {
    return <iframe src={TermsOfUse} title='Termos de Uso' />
  }
}

export default Terms
