import React, { useRef, useState, useEffect } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CryptoJS from 'crypto-js'
import _ from 'lodash'

// Components
import DocumentItem from '../../components/DocumentItem'
import Pagination from '../../components/Pagination'
import Spinner from '../../components/Spinner'
import HeaderFeatures from '../../components/HeaderFeatures'
import CustomTooltip from '../../components/CustomTooltip'
import DynamicLoading from '../../components/DynamicLoading'
import { Button, Alert, Input } from '../../components/DesignSystem'

// Services
import { getProgress, uploadDocument } from '../../services/readDocsServices'
import { getDocumentList } from '../../services/readDocsServices'

// Utils
import { formatIsoDate } from '../../constants/others'
import { formatBytes } from '../../constants/validate'

import './index.scss'

const MAX_FILE_SIZE = 500 * 1024 * 1024

export default function ReadDocsList() {
  const {
    user: { token, status },
  } = useSelector(state => state.app)
  const { page } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const docsList = useRef(null)
  const inputFileRef = useRef(null)

  const [documentSearch, setDocumentSearch] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [customId, setCustomId] = useState(null)
  const [progress, setProgress] = useState({ progress: 0, message: 'Enviando...' })

  const { isLoading, isFetching, data } = useQuery({
    queryKey: ['readDocs-list', page, documentSearch],
    queryFn: ({ signal }) => fetchDocuments(signal),
    enabled: !_.isEmpty(token),
    refetchOnWindowFocus: true,
    retry: false,
  })

  useEffect(() => {
    let interval = null
    if (customId) {
      interval = setInterval(() => {
        getFileProgress()
      }, 2000)
    } else if (!customId && interval) {
      clearInterval(interval)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
      setProgress({ progress: 0, message: 'Enviando...' })
    }
  }, [customId])

  function handleSearch(value) {
    setDocumentSearch(value)
    navigate('/lista-consultar-documentos/')
  }

  async function fetchDocuments(signal) {
    try {
      const { data, meta } = await getDocumentList({ page, documentSearch, signal })

      return { documents: data.documents, totalPages: meta.totalPages, totalDocs: meta.total }
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.log(error)
        const errorContent = JSON.stringify(error)
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
            <Button
              icon='Copy'
              onlyIcon
              onClick={() => {
                navigator.clipboard.writeText(errorContent)
                toast.success('Erro copiado para área de transferência')
              }}
            />
          </div>
        )
      }
      return { documents: [], totalPages: 0, totalDocs: 0 }
    }
  }

  async function getFileProgress() {
    try {
      const { data } = await getProgress(customId)
      setProgress({ progress: data.progress, message: data.statusMessage })
    } catch (error) {
      console.log(error)
      setProgress({ progress: progress.progress, message: 'Erro ao buscar progresso' })
    }
  }

  function handleChangePageNumber(pageNumber) {
    handleScrollToTop()
    navigate(`/lista-consultar-documentos/${pageNumber}`)
  }

  // function handleClickNewFile() {
  //   setIsUploadModalOpen(true)
  // }

  // function handleCloseUploadModal() {
  //   setIsUploadModalOpen(false)
  // }

  function returnAlertTitle() {
    let filtered = data?.documents.filter(o => o.status === 'ocr')
    if (!filtered) return

    if (filtered.length > 1) {
      return `${filtered.length} documentos em processamento`
    } else {
      return '1 documento em processamento'
    }
  }

  function returnAlertText() {
    let filtered = data?.documents.filter(o => o.status === 'ocr')

    if (!filtered) return

    if (filtered.length > 1) {
      return `Estamos processando o arquivo ${filtered[0]?.name} e mais ${filtered.length - 1} ${
        filtered.length - 1 === 1 ? 'documento' : 'documentos'
      }. Em breve eles estarão na lista abaixo.`
    } else {
      return `Estamos processando o arquivo ${filtered[0]?.name}. Em breve ele estará na lista abaixo.`
    }
  }

  function returnListItemsClassName() {
    let className = 'read_docs-list__items'
    if (isLoading) className += ' read_docs-list__items--loading'
    if (!isLoading && _.isEmpty(data?.documents)) className += ' read_docs-list__items--empty'

    return className
  }

  function returnRefreshInfoClassName() {
    let className = 'read_docs-list__refresh-info'
    if (!isLoading && isFetching) className += ' read_docs-list__refresh-info--visible'

    return className
  }

  function returnCounterText() {
    if (data?.totalDocs) {
      let aux = 1

      if (page) {
        aux = page
      }

      let max = aux * 25
      if (data.totalDocs < max) {
        max = data.totalDocs
      }

      let min = 1 + 25 * (aux - 1)

      if (aux === 1) {
        min = 1
      }

      return `Exibindo ${min}-${max} de ${data.totalDocs}`
    } else {
      return ''
    }
  }

  async function handleUploadDocument(file) {
    setIsUploading(true)
    let formData = new FormData()
    formData.append('file', file)
    try {
      const generatedId = CryptoJS.lib.WordArray.random(12).toString(CryptoJS.enc.Hex)
      setCustomId(generatedId)
      let { data } = await uploadDocument(formData, generatedId)
      if (data.ocr) {
        toast.info(`Estamos processando o arquivo ${data.document?.name}.`)
      }
      queryClient.invalidateQueries(['readDocs-list'])
      queryClient.invalidateQueries(['usage-quantity'])
    } catch (error) {
      console.log(error)
      console.log(error.response?.data?.errors[0]?.message)
      const errorContent = JSON.stringify(error)
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
          <Button
            icon='Copy'
            onlyIcon
            onClick={() => {
              navigator.clipboard.writeText(errorContent)
              toast.success('Erro copiado para área de transferência')
            }}
          />
        </div>
      )
    } finally {
      setIsUploading(false)
      setCustomId(null)
    }
  }

  // Nova implementação
  function handleSelectFile(e) {
    const supportedMimeTypes = [
      'application/pdf',
      'application/msword', // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/vnd.oasis.opendocument.text', // .odt
    ]

    const file = e.target?.files[0]
    if (file?.size >= MAX_FILE_SIZE) {
      toast.error(`O arquivo excedeu o tamanho de ${formatBytes(MAX_FILE_SIZE)}`)
      return
    }
    if (!supportedMimeTypes.includes(file?.type)) {
      toast.error('Formato de arquivo não permitido')
      return
    }
    handleUploadDocument(file)
    inputFileRef.current.value = null
  }

  function handleScrollToTop() {
    docsList.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  return (
    <>
      <HeaderFeatures />
      <div className='read_docs-list'>
        <section className='read_docs-list__info' ref={docsList}>
          <div>
            <h1>Consulta de documentos</h1>
            <p>Acesse todos os seus documentos e suas respectivas conversas</p>
          </div>
          <input
            type='file'
            ref={inputFileRef}
            hidden
            onChange={handleSelectFile}
            accept='.pdf,.doc,.docx,.odt'
            size={MAX_FILE_SIZE}
            multiple={false}
          />
          {status !== 'paid' ? (
            <CustomTooltip
              description={
                'Você não possui um plano ativo. Para contratar basta ir em "Configurações" e preencher os dados do seu cartão.'
              }
              name='Assinatura necessária'>
              <Button
                loading={isUploading}
                outlined
                icon='MessageSquarePlus'
                onClick={() => inputFileRef.current.click()}
                style={{
                  borderRadius: 0,
                  textTransform: 'uppercase',
                  fontSize: 10,
                  fontWeight: 700,
                  letterSpacing: 2,
                }}
                disabled={status !== 'paid'}>
                Novo chat
              </Button>
            </CustomTooltip>
          ) : (
            <Button
              loading={isUploading}
              outlined
              icon='MessageSquarePlus'
              onClick={() => inputFileRef.current.click()}
              style={{
                borderRadius: 0,
                textTransform: 'uppercase',
                fontSize: 10,
                fontWeight: 700,
                letterSpacing: 2,
                color: 'white',
              }}
              disabled={status !== 'paid'}>
              Novo chat
            </Button>
          )}
        </section>
        <div className='read_docs-list__divider' />
        {data?.documents?.length > 0 &&
          data.documents.filter(o => o.status === 'ocr').length > 0 && (
            <Alert type='info' title={returnAlertTitle()} text={returnAlertText()} />
          )}
        <section className='read_docs-list__listing-section'>
          <div className='read_docs-list__listing-section__search'>
            <Input
              withDebounce
              placeholder='Título do chat'
              label='Título do chat'
              value={documentSearch}
              onChange={handleSearch}
              icon={'Search'}
            />
          </div>
          <div>
            <div className='read_docs-list__listing-section__header'>{returnCounterText()}</div>
            <div className={returnRefreshInfoClassName()}>
              <p>Sincronizando dados</p>
              <Spinner size={10} />
            </div>
          </div>
          <div className={returnListItemsClassName()}>
            {isLoading && <Spinner />}
            {!isLoading && _.isEmpty(data?.documents) && (
              <p className='read_docs-list__empty-message'>Nenhum documento encontrado</p>
            )}
            {!isLoading &&
              !_.isEmpty(data?.documents) &&
              data.documents
                .filter(o => o.status === 'success')
                .map(document => (
                  <DocumentItem
                    key={document._id}
                    documentId={document._id}
                    documentName={document.name}
                    uploadDate={formatIsoDate(document.createdAt)}
                    chat={document.chat}
                    lastMessage={true}
                    lastItem={{ label: 'Data da última interação' }}
                    path={'chat'}
                    legacy={document.legacy}
                  />
                ))}
            {!isLoading && !_.isEmpty(data?.documents) && (
              <Pagination
                currentPage={page}
                totalPages={data ? data.totalPages : 0}
                onClickAction={handleChangePageNumber}
              />
            )}
          </div>
        </section>
        {isUploading && <DynamicLoading percent={progress.progress} loadingMessage={progress.message} />}
      </div>
      <ReactQueryDevtools />
    </>
  )
}
