import React, { useState } from 'react'

import DialogDemo from '../DialogDemo'

import './index.scss'

const goodPractices = [
  {
    title: '1. Clareza e Especificidade',
    boldDesc: 'Seja Claro e Direto:',
    desc: 'Formule as perguntas de maneira clara e precisa. Evite ambiguidades que possam levar a interpretações errôneas.',
    boldExample: 'Exemplo:',
    example:
      "Em vez de 'Qual é o prazo?', pergunte 'Qual foi o prazo estipulado para manifestação no documento X?'",
  },
  {
    title: '2. Contexto e Relevância',
    boldDesc: 'Forneça Contexto Suficiente:',
    desc: ' Sempre que possível, inclua o contexto necessário para a pergunta, de modo que a resposta seja mais adequada à situação e menos genérica.',
    boldExample: 'Exemplo:',
    example:
      'Em vez de: "Qual é o procedimento correto para interposição de recurso de apelação?", pergunte "Considerando o conteúdo dos documentos X e Y, qual é o procedimento correto para interposição de recurso de apelação?"',
  },
  {
    title: '3. Relações Explícitas entre Documentos',
    boldDesc: 'Estabeleça Conexões Claras:',
    desc: 'Quando se referir a múltiplos documentos, estabeleça relações explícitas entre eles para que o modelo entenda o contexto completo.',
    boldExample: 'Exemplo:',
    example:
      'De acordo com o dispositivo de sentença do documento X e o conteúdo da apelação do documento Y, quais foram os pontos atacados?',
  },
  {
    title: '4. Estrutura dos Prompts',
    boldDesc: 'Use uma Estrutura Lógica:',
    desc: 'Estruture os prompts de maneira lógica, começando com uma introdução ao contexto seguida da pergunta específica.',
    boldExample: 'Exemplo:',
    example: 'No contrato firmado entre as partes A e B, quais são as obrigações da parte B?',
  },
  {
    title: '5. Uso de Exemplos e Cenários',
    boldDesc: 'Inclua Exemplos:',
    desc: 'Use exemplos e cenários específicos para ilustrar a pergunta e facilitar a compreensão.',
    boldExample: 'Exemplo:',
    example:
      'Em um cenário onde a empresa A viola a cláusula Y do contrato do documento X, quais são as possíveis penalidades conforme o Código Civil Brasileiro?',
  },
]

const transcriptionPractices = [
  {
    title: 'Nomeie com Contexto:',
    tips: [
      {
        boldDesc: 'Nomeie com Contexto:',
        desc: 'Na tela de identificação de cada participante é importante que as nomeações sejam contextualizadas, dessa forma nossa IA consegue atribuir mais valor ao contexto das falas.',
        boldExample: 'Exemplo:',
        example:
          'Em vez de nomear um participante como "Carlos Eduardo", dê preferência para "Testemunha de Defesa Carlos Eduardo".',
      },
      {
        boldDesc: 'Seja específico:',
        desc: 'Especialmente para áudios e vídeos muito longos, em que, possivelmente, um mesmo ponto é levantado muitas vezes, é importante especificar aquele sobre o qual você deseja perguntar.',
        boldExample: 'Exemplo:',
        example:
          '"Quais foram os pontos principais do depoimento da Testemunha X em relação à materialidade do crime?"',
      },
    ],
  },
]

/**
 * HeaderSection component
 * @param {{
 * title: string
 * paragraph: string
 * secondary: boolean
 * noPadding: boolean
 * hasGoodPracticesDialog: boolean
 * }} props Props
 * @returns {React.Element} - the HeaderSection component
 */
function HeaderSection({ title, paragraph, secondary, noPadding, hasGoodPracticesDialog }) {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <section className='header-section' style={noPadding && { padding: '0' }}>
      {secondary ? <h6>{title}</h6> : <h1>{title}</h1>}
      <p>{paragraph}</p>
      {hasGoodPracticesDialog && (
        <DialogDemo
          openButton={{
            text: 'Clique aqui para acessar nosso manual de bons prompts.',
            style: {
              padding: 0,
              fontSize: 12,
              border: 'none',
              width: 'fit-content',
              maxWidth: '100%',
              lineHeight: '18px',
            },
          }}
          style={{ height: '40px' }}
          dialogDescriptionStyle={{ marginTop: 20 }}
          bgColor='#272929'
          title='Manual de bons prompts'
          description='Para garantir que o Spectter forneça respostas mais precisas e relevantes ao consultar um documento jurídico, é fundamental seguir algumas boas práticas na construção dos prompts.'
          confirmButton='Fechar'
          confirmAction={() => setModalOpen(false)}
          modalOpen={modalOpen}
          onOpenChange={e => setModalOpen(e ? 'edit-prompt' : '')}>
          <ul className='header-section__prompts-good-practices'>
            {goodPractices.map((goodPractice, index) => (
              <div role='listitem' key={index}>
                <h2>{goodPractice.title}</h2>
                <ul className='header-section__prompts-good-practices__list'>
                  <li>
                    <strong>{goodPractice.boldDesc} </strong> <span>{goodPractice.desc}</span>
                    <ul className='header-section__prompts-good-practices__sub-list'>
                      <li>
                        <strong>{goodPractice.boldExample} </strong>
                        <span>{goodPractice.example}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            ))}

            {transcriptionPractices.map((transcriptionPractice, index) => (
              <div key={index}>
                <h2>Transcrições de áudio e vídeo</h2>
                <ul className='header-section__prompts-good-practices__list'>
                  {transcriptionPractice.tips.map((tip, tipIndex) => (
                    <li key={tipIndex}>
                      <strong>{tip.boldDesc} </strong> <span>{tip.desc}</span>
                      <ul className='header-section__prompts-good-practices__sub-list'>
                        <li>
                          <strong>{tip.boldExample} </strong>
                          <span>{tip.example}</span>
                        </li>
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </ul>
        </DialogDemo>
      )}
    </section>
  )
}

export default HeaderSection
