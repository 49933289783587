import { useState, useRef, useCallback, useEffect } from 'react'

const useRecaptcha = () => {
  const [capchaToken, setCapchaToken] = useState('')
  const recaptchaRef = useRef()

  const handleRecaptcha = useCallback(token => {
    setCapchaToken(token)
  }, [])

  useEffect(() => {
    const refreshCaptcha = () => {
      if (recaptchaRef.current && capchaToken) {
        recaptchaRef.current.reset()
        setCapchaToken('')
      }
    }

    let tokenRefreshTimeout

    if (capchaToken) {
      tokenRefreshTimeout = setTimeout(refreshCaptcha, 300000) // 300 seconds
    }

    return () => {
      if (tokenRefreshTimeout) {
        clearTimeout(tokenRefreshTimeout)
      }
    }
  }, [capchaToken])

  return { capchaToken, setCapchaToken, recaptchaRef, handleRecaptcha }
}

export default useRecaptcha
