import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import * as Accordion from '@radix-ui/react-accordion'

import CustomIcon from '../../../components/CustomIcon'

function DocumentationAccordion({ items, nested = false }) {
  const accordionItemClassName = nested ? 'accordion-item accordion-item-nested' : 'accordion-item'

  return (
    <Accordion.Root type='multiple'>
      {items.map((item, index) => (
        <Accordion.Item value={`item-${index}`} className={accordionItemClassName}>
          <Accordion.Trigger className='accordion-trigger'>
            {item.name}
            <CustomIcon icon='ChevronDown' className='accordion-chevron' />
          </Accordion.Trigger>
          <Accordion.Content className='accordion-content'>
            {item.description && (
              <ReactMarkdown
                className='tech-documentation__markdown__container'
                remarkPlugins={[remarkGfm]}>
                {item.description}
              </ReactMarkdown>
            )}
            {item.item ? (
              <DocumentationAccordion items={item.item} nested />
            ) : (
              <ReactMarkdown
                className='tech-documentation__markdown__container'
                remarkPlugins={[remarkGfm]}>
                {item.request.description}
              </ReactMarkdown>
            )}
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  )
}

export default DocumentationAccordion
