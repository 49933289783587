import React, { useRef } from 'react'
// import { ReactComponent as PdfIcon } from '../../assets/pdf-icon.svg'
import { File } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useQueryClient, useMutation } from '@tanstack/react-query'

// Components
import Button from '../DesignSystem/Button'
import ItemValue from '../ItemValue'
import Input from '../DesignSystem/Input'
import DialogDemo from '../DialogDemo'
import DropDown from '../DropDown'
import AlertDialogDemo from '../AlertDialogDemo'
import CustomIcon from '../CustomIcon'

// Utils
import { formatIsoDate } from '../../constants/others'

import { inactivateDocument, renameDocument } from '../../services/documentsServices'
import { inactivateReadDocument, renameReadDocs } from '../../services/readDocsServices'

import './index.scss'

export default function DocumentItem({
  icon = 'File',
  editable = false,
  documentName,
  documentId,
  uploadDate,
  disabled,
  chat,
  lastMessage = false,
  lastItem,
  path = 'documento',
  legacy = false,
  status,
}) {
  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const containerRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState('')

  const [documentInputName, setDocumentInputName] = useState(documentName)

  const { mutate: deleteDocument } = useMutation({
    mutationFn: async () => {
      setIsLoading(true)
      const body = { docId: documentId }
      if (path === 'chat' || path === 'transcricao') {
        await inactivateReadDocument(body)
      } else {
        await inactivateDocument(body)
      }
    },
    onSuccess: () => {
      toast.success('Documento excluído com sucesso')
      queryClient.invalidateQueries(['readDocs-list'])
      queryClient.invalidateQueries(['documents-list'])
      queryClient.invalidateQueries(['transcripts-list'])
      setModalOpen('')
    },
    onError: error => {
      const errorContent = JSON.stringify(error)
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {error.response?.data?.errors[0]?.message || 'Erro ao excluir documento'}
          <Button
            icon='Copy'
            onlyIcon
            onClick={() => {
              navigator.clipboard.writeText(errorContent)
              toast.success('Erro copiado para área de transferência')
            }}
          />
        </div>
      )
      console.log(error)
    },
    onSettled: () => setIsLoading(false),
  })

  function getLastInterection() {
    if (chat.length === 0) return '-'

    const lastMessage = chat[chat.length - 1]
    return formatIsoDate(lastMessage.createdAt)
  }

  function handleNavigate() {
    navigate(`/${path}/${documentId}`)
  }

  function verifyDocumentName() {
    if (documentInputName === '') {
      toast.warning('Nome inválido')
      return
    }

    if (documentInputName === documentName) {
      toast.warning('Insira um nome diferente')
      return
    }

    if (path === 'chat' || path === 'transcricao') {
      setModalOpen('edit-read-docs')
      setIsLoading(true)
      submitReadDocsChanges()
    } else {
      setModalOpen('edit-document')
      setIsLoading(true)
      submitDocumentChanges()
    }
  }

  async function submitDocumentChanges() {
    const body = {
      name: documentInputName,
      documentId,
    }
    try {
      await renameDocument(body)
      toast.success('Alterações salvas com sucesso')
      setModalOpen('')
      queryClient.invalidateQueries(['documents-list'])
    } catch (error) {
      console.log(error)
      const errorContent = JSON.stringify(error)
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
          <Button
            icon='Copy'
            onlyIcon
            onClick={() => {
              navigator.clipboard.writeText(errorContent)
              toast.success('Erro copiado para área de transferência')
            }}
          />
        </div>
      )
    } finally {
      setIsLoading(false)
    }
  }

  async function submitReadDocsChanges() {
    const body = {
      name: documentInputName,
      docId: documentId,
    }
    try {
      await renameReadDocs(body)

      toast.success('Alterações salvas com sucesso')
      setModalOpen('')
      if (path === 'chat') {
        queryClient.invalidateQueries(['readDocs-list'])
      }

      if (path === 'transcricao') {
        queryClient.invalidateQueries(['transcripts-list'])
      }
    } catch (error) {
      console.log(error)
      const errorContent = JSON.stringify(error)
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
          <Button
            icon='Copy'
            onlyIcon
            onClick={() => {
              navigator.clipboard.writeText(errorContent)
              toast.success('Erro copiado para área de transferência')
            }}
          />
        </div>
      )
    } finally {
      setIsLoading(false)
    }
  }

  function renameDocumentConfirm() {
    setModalOpen('edit-document')
  }

  function renameReadDocumentConfirm() {
    setModalOpen('edit-read-docs')
  }

  function deleteDocumentConfirm() {
    setModalOpen('delete-document')
  }

  const dropdownOptions = [
    {
      label: 'Renomear',
      action: renameDocumentConfirm,
      icon: 'PenLine',
    },
  ]
  if (!disabled) {
    dropdownOptions.push({
      label: 'Excluir',
      action: deleteDocumentConfirm,
      icon: 'Trash',
    })
  }

  return (
    <div className='document-item'>
      <div>
        <div className='document-item__pdf-icon'>
          <CustomIcon icon={icon} />
        </div>
        <ItemValue value={documentName} isDocumentName />
      </div>
      <div>
        <p>Data de criação</p>
        <ItemValue value={uploadDate} />
      </div>
      <div>
        <p>{lastItem.label}</p>
        <ItemValue
          value={lastMessage ? getLastInterection() : lastItem.value}
          icon={lastItem.icon}
        />
      </div>
      {editable ? (
        <div className='document-item__buttons'>
          <DialogDemo
            customOpenButton
            style={{ height: '40px' }}
            title={'Renomear documento'}
            description={'Troque o nome e clique em salvar alterações para aplicar as mudanças.'}
            confirmButton={'Salvar alterações'}
            confirmAction={verifyDocumentName}
            loading={isLoading}
            modalOpen={modalOpen === 'edit-document'}
            onOpenChange={e => setModalOpen(e ? 'edit-document' : '')}>
            <Input
              placeholder='Nome do documento'
              label='Nome do documento'
              value={documentInputName}
              onChange={setDocumentInputName}
            />
          </DialogDemo>
          <Button
            contained
            fullWidth
            disabled={disabled}
            onClick={handleNavigate}
            style={{
              textTransform: 'uppercase',
              fontSize: '10px',
              fontWeight: '700',
              lineHeight: '10px',
              borderRadius: '0',
            }}>
            {legacy ? 'Visualizar' : status === 'waiting-speakers' ? 'Identificar' : 'Consultar'}
          </Button>

          <DropDown noArrow container={containerRef.current} options={dropdownOptions}>
            <CustomIcon icon={'MoreVertical'} size={'20px'} />
          </DropDown>

          <AlertDialogDemo
            title={'Excluir documento?'}
            description={'Clique em confirmar para deletar esse documento.'}
            cancelButton={'Cancelar'}
            confirmButton={'Confirmar'}
            modalOpen={modalOpen === 'delete-document'}
            setModalOpen={setModalOpen}
            confirmAction={deleteDocument}
            loading={isLoading}
          />
        </div>
      ) : (
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px' }}
          ref={containerRef}>
          <DialogDemo
            customOpenButton
            style={{ height: '40px' }}
            title={'Renomear documento'}
            description={'Troque o nome e clique em salvar alterações para aplicar as mudanças.'}
            confirmButton={'Salvar alterações'}
            confirmAction={verifyDocumentName}
            loading={isLoading}
            modalOpen={modalOpen === 'edit-read-docs'}
            onOpenChange={e => setModalOpen(e ? 'edit-read-docs' : '')}>
            <Input
              placeholder='Nome do documento'
              label='Nome do documento'
              value={documentInputName}
              onChange={setDocumentInputName}
            />
          </DialogDemo>
          <Button
            contained
            fullWidth
            onClick={handleNavigate}
            textUppercase
            style={{
              fontSize: '10px',
              fontWeight: '700',
              lineHeight: '10px',
              borderRadius: '0',
            }}>
            {legacy ? 'Visualizar' : status === 'waiting-speakers' ? 'Identificar' : 'Consultar'}
          </Button>

          <DropDown
            noArrow
            container={containerRef.current}
            options={[
              {
                label: 'Renomear',
                action: renameReadDocumentConfirm,
                icon: 'PenLine',
              },
              {
                label: 'Excluir',
                action: deleteDocumentConfirm,
                icon: 'Trash',
              },
            ]}>
            <CustomIcon icon={'MoreVertical'} size={'20px'} />
          </DropDown>

          <AlertDialogDemo
            title={'Excluir documento?'}
            description={'Clique em confirmar para deletar esse documento.'}
            cancelButton={'Cancelar'}
            confirmButton={'Confirmar'}
            modalOpen={modalOpen === 'delete-document'}
            setModalOpen={setModalOpen}
            confirmAction={deleteDocument}
            loading={isLoading}
          />
        </div>
      )}
    </div>
  )
}
