import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

// Components
import TextArea from '../../components/DesignSystem/TextArea'
import Input from '../../components/DesignSystem/Input'
import Select from '../../components/DesignSystem/Select'
import Checkbox from '../../components/DesignSystem/Checkbox'
import Button from '../../components/DesignSystem/Button'
import Spinner from '../../components/Spinner'
import * as Accordion from '@radix-ui/react-accordion'
import AccordionItem from '../../components/AccordionItem/AccordionItem'
import DynamicLoading from '../../components/DynamicLoading'

// Utils
import { currency, formatBytes, formatDocument, phone } from '../../constants/validate'
import { generateComplaintArguments } from '../../services/documentsServices'
import { transcribeAudio } from '../../services/transcriptionServices'

// Styles
import './index.scss'

const Complaints = () => {
  // Form states
  const [distribution, setDistribution] = useState({
    complaintType: '',
    competentCourt: '',
  })
  const [plaintiffsData, setPlaintiffsData] = useState([])
  const [defendantsData, setDefendantsData] = useState([])
  const [facts, setFacts] = useState('')
  const [evidences, setEvidences] = useState({
    producedEvidence: '',
    pendingEvidence: '',
  })
  const [claims, setClaims] = useState({
    necessaryClaims: '',
    complementaryClaims: [],
  })
  const [processValue, setProcessValue] = useState('')
  const [openFields, setOpenFields] = useState([])

  // Speech recognition states
  const [listeningField, setListeningField] = useState('')
  const [trascriptionField, setTranscriptionField] = useState('')
  const [fieldsBeingTranscribed, setFieldsBeingTranscribed] = useState([])

  // Litisconsortium states
  const [passiveLitisconsortium, setPassiveLitisconsortium] = useState(false)
  const [activeLitisconsortium, setActiveLitisconsortium] = useState(false)
  const [activeLitisconsortiumNumber, setActiveLitisconsortiumNumber] = useState(1)
  const [passiveLitisconsortiumNumber, setPassiveLitisconsortiumNumber] = useState(1)

  // Form submission
  const [isSubmitting, setIsSubmitting] = useState(false)

  const navigate = useNavigate()
  const recognitionRef = useRef(null)
  const inputFileRef = useRef(null)

  const complementaryClaimsOptions = [
    'Tutela de Urgência',
    'Tutela de Evidência',
    'Justiça Gratuita',
    'Inversão do Ônus da Prova',
    'Sigilo Processual',
    'Tramitação Prioritária',
  ]

  const MAX_FILE_SIZE = 50 * 1024 * 1024

  useEffect(() => {
    if ('SpeechRecognition' in window) {
      recognitionRef.current = new window.SpeechRecognition()
    } else if ('webkitSpeechRecognition' in window) {
      recognitionRef.current = new window.webkitSpeechRecognition()
    }

    if (recognitionRef.current) {
      recognitionRef.current.continuous = true
      recognitionRef.current.interimResults = true
      recognitionRef.current.lang = 'pt-BR'

      recognitionRef.current.onend = () => {
        setListeningField('')
      }

      recognitionRef.current.onresult = event => {
        let interim = ''
        let final = ''

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            final += event.results[i][0].transcript
          } else {
            interim += event.results[i][0].transcript
          }
        }

        setListeningField(prev => {
          switch (prev) {
            case 'facts':
              setFacts(prevFacts => prevFacts + final)
              break
            case 'producedEvidence':
              setEvidences(prevEvidences => ({
                ...prevEvidences,
                producedEvidence: prevEvidences.producedEvidence + final,
              }))
              break
            case 'pendingEvidence':
              setEvidences(prevEvidences => ({
                ...prevEvidences,
                pendingEvidence: prevEvidences.pendingEvidence + final,
              }))
              break
            default:
              break
          }
          return prev
        })
      }
    }
  }, [listeningField])

  const startListening = field => {
    if (recognitionRef.current) {
      try {
        recognitionRef.current.start()
        setListeningField(field)
      } catch (error) {
        return
      }
    }
  }

  const stopListening = () => {
    if (recognitionRef.current) {
      try {
        recognitionRef.current.stop()
        setListeningField('')
        return
      } catch (error) {
        console.log(error)
        return
      }
    }
  }  

  function handleStartTranscription(field) {
    setTranscriptionField(field)
    inputFileRef.current.click()
  }

  function handleSelectFile(e) {
    const supportedMimeTypes = ['audio/mp3', 'audio/wav', 'audio/ogg']

    const file = e.target?.files[0]
    if (file?.size >= MAX_FILE_SIZE) {
      toast.error(`O arquivo excedeu o tamanho de ${formatBytes(MAX_FILE_SIZE)}`)
      return
    }

    if (!supportedMimeTypes.includes(file?.type)) {
      toast.error('Formato de arquivo não permitido')
      return
    }
    handleUploadAudio(file)
    inputFileRef.current.value = null
  }

  async function handleUploadAudio(file) {
    setFieldsBeingTranscribed(prevFields => [...prevFields, trascriptionField])
    let formData = new FormData()
    formData.append('file', file)
    try {
      const { data } = await transcribeAudio({ formData })
      switch (trascriptionField) {
        case 'facts':
          setFacts(prevFacts => prevFacts + data)
          break
        case 'producedEvidence':
          setEvidences(prevEvidences => ({
            ...prevEvidences,
            producedEvidence: prevEvidences.producedEvidence + data,
          }))
          break
        case 'pendingEvidence':
          setEvidences(prevEvidences => ({
            ...prevEvidences,
            pendingEvidence: prevEvidences.pendingEvidence + data,
          }))
          break

        default:
          break
      }

      toast.success('Áudio transcrito com sucesso')
    } catch (error) {
      console.log(error.response?.data?.errors[0]?.message)
      toast.error(error.response?.data?.errors[0]?.message || 'Erro interno do servidor')
    } finally {
      setFieldsBeingTranscribed(prevFields =>
        prevFields.filter(field => field !== trascriptionField),
      )
      setTranscriptionField('')
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    // await is necessary to ensure that the fields are opened before submitting the form
    await setOpenFields(['distribution', 'facts', 'evidences', 'claims'])
    const formElement = e.target
    const formIsValid = formElement.checkValidity()
    formElement.reportValidity()
    if (!formIsValid) return

    setIsSubmitting(true)
    try {
      const complaintGeneralData = {
        distribution,
        plaintiffsData,
        defendantsData,
        facts,
        evidences,
        claims: {
          ...claims,
          complementaryClaims: claims.complementaryClaims.join(', '),
        },
        processValue,
      }
      const complaintId = await generateComplaintArguments(complaintGeneralData)
      if (complaintId) {
        toast.success('Argumentos da petição inicial gerados com sucesso')
        navigate(`/peticao/${complaintId}`)
      }
    } catch (error) {
      console.log(error)
      toast.error('Erro ao gerar argumentos da petição inicial')
    } finally {
      setIsSubmitting(false)
      setOpenFields([])
    }
  }

  return (
    <form className='complaints' onSubmit={handleSubmit}>
      <h1>Petições Iniciais</h1>
      <Accordion.Root type='multiple' value={openFields} onValueChange={setOpenFields}>
        <AccordionItem
          title='1. Distribuição'
          subtitle='Insira informações sobre o juízo e as partes'
          value='distribution'>
          <fieldset>
            <legend>Juízo</legend>
            <Input
              label='Tipo de Ação'
              hasLabel
              placeholder='Exemplo: Ação de Indenização por Danos Materiais e Morais'
              value={distribution.complaintType}
              onChange={value => setDistribution({ ...distribution, complaintType: value })}
              required
            />
            <Input
              label='Juízo Competente'
              hasLabel
              placeholder='Exemplo: 1ª Vara Cível da Comarca de São Paulo'
              value={distribution.competentCourt}
              onChange={value => setDistribution({ ...distribution, competentCourt: value })}
            />
          </fieldset>

          <fieldset>
            <legend>Polo Ativo</legend>
            <Checkbox
              value={activeLitisconsortium}
              label='Litisconsórcio Ativo'
              onChange={value => setActiveLitisconsortium(value)}
              blackCheck
            />
            {activeLitisconsortium && (
              <Select
                label='Quantidade de Requerentes'
                hasLabel
                value={activeLitisconsortiumNumber}
                onChange={value => setActiveLitisconsortiumNumber(parseInt(value))}
                options={new Array(10)
                  .fill(0)
                  .map((_, index) => ({ value: index + 1, label: index + 1 }))}
              />
            )}
            {activeLitisconsortium && activeLitisconsortiumNumber > 1 ? (
              new Array(activeLitisconsortiumNumber).fill(0).map((_, index) => (
                <fieldset key={index}>
                  <legend>Requerente {index + 1}</legend>
                  <Input
                    label='Nome'
                    hasLabel
                    placeholder='Exemplo: João da Silva'
                    required
                    value={plaintiffsData[index]?.name}
                    onChange={value => {
                      const newPlaintiffsData = [...plaintiffsData]
                      newPlaintiffsData[index] = { ...newPlaintiffsData[index], name: value }
                      setPlaintiffsData(newPlaintiffsData)
                    }}
                  />
                  <Input
                    label='CPF/CNPJ'
                    hasLabel
                    placeholder='Exemplo: 123.456.789-00'
                    value={plaintiffsData[index]?.cpfCnpj}
                    onChange={value => {
                      const newPlaintiffsData = [...plaintiffsData]
                      newPlaintiffsData[index] = {
                        ...newPlaintiffsData[index],
                        cpfCnpj: formatDocument(value),
                      }
                      setPlaintiffsData(newPlaintiffsData)
                    }}
                  />
                  <Input
                    label='Endereço'
                    hasLabel
                    placeholder='Exemplo: Rua das Flores, 123, São Paulo/SP'
                    value={plaintiffsData[index]?.address}
                    onChange={value => {
                      const newPlaintiffsData = [...plaintiffsData]
                      newPlaintiffsData[index] = { ...newPlaintiffsData[index], address: value }
                      setPlaintiffsData(newPlaintiffsData)
                    }}
                  />
                  <div style={{ display: 'flex', gap: '12px' }}>
                    <Input
                      label='Telefone'
                      hasLabel
                      placeholder='Exemplo: (11) 98765-4321'
                      value={plaintiffsData[index]?.phone}
                      onChange={value => {
                        const newPlaintiffsData = [...plaintiffsData]
                        newPlaintiffsData[index] = {
                          ...newPlaintiffsData[index],
                          phone: phone(value),
                        }
                        setPlaintiffsData(newPlaintiffsData)
                      }}
                    />
                    <Input
                      label='Email'
                      hasLabel
                      placeholder='Exemplo: email@exemplo.com.br'
                      value={plaintiffsData[index]?.email}
                      onChange={value => {
                        const newPlaintiffsData = [...plaintiffsData]
                        newPlaintiffsData[index] = { ...newPlaintiffsData[index], email: value }
                        setPlaintiffsData(newPlaintiffsData)
                      }}
                    />
                  </div>
                </fieldset>
              ))
            ) : (
              <>
                <Input
                  label='Nome'
                  hasLabel
                  placeholder='Exemplo: João da Silva'
                  value={plaintiffsData[0]?.name}
                  onChange={value => setPlaintiffsData([{ ...plaintiffsData[0], name: value }])}
                  required
                />
                <Input
                  label='CPF/CNPJ'
                  hasLabel
                  placeholder='Exemplo: 123.456.789-00'
                  value={plaintiffsData[0]?.cpfCnpj}
                  onChange={value =>
                    setPlaintiffsData([{ ...plaintiffsData[0], cpfCnpj: formatDocument(value) }])
                  }
                />
                <Input
                  label='Endereço'
                  hasLabel
                  placeholder='Exemplo: Rua das Flores, 123, São Paulo/SP'
                  value={plaintiffsData[0]?.address}
                  onChange={value => setPlaintiffsData([{ ...plaintiffsData[0], address: value }])}
                />
                <div style={{ display: 'flex', gap: '12px' }}>
                  <Input
                    label='Telefone'
                    hasLabel
                    placeholder='Exemplo: (11) 98765-4321'
                    value={plaintiffsData[0]?.phone}
                    onChange={value =>
                      setPlaintiffsData([{ ...plaintiffsData[0], phone: phone(value) }])
                    }
                  />
                  <Input
                    label='Email'
                    hasLabel
                    placeholder='Exemplo: email@exemplo.com.br'
                    value={plaintiffsData[0]?.email}
                    onChange={value => setPlaintiffsData([{ ...plaintiffsData[0], email: value }])}
                  />
                </div>
              </>
            )}
          </fieldset>

          <fieldset>
            <legend>Polo Passivo</legend>
            <Checkbox
              value={passiveLitisconsortium}
              label='Litisconsórcio Passivo'
              onChange={value => setPassiveLitisconsortium(value)}
              blackCheck
            />
            {passiveLitisconsortium && (
              <Select
                label='Quantidade de Requeridos'
                hasLabel
                value={passiveLitisconsortiumNumber}
                onChange={value => setPassiveLitisconsortiumNumber(parseInt(value))}
                options={new Array(10)
                  .fill(0)
                  .map((_, index) => ({ value: index + 1, label: index + 1 }))}
              />
            )}
            {passiveLitisconsortium && passiveLitisconsortiumNumber > 1 ? (
              new Array(passiveLitisconsortiumNumber).fill(0).map((_, index) => (
                <fieldset key={index}>
                  <legend>Requerido {index + 1}</legend>
                  <Input
                    label='Nome'
                    hasLabel
                    placeholder='Exemplo: João da Silva'
                    required
                    value={defendantsData[index]?.name}
                    onChange={value => {
                      const newDefendantsData = [...defendantsData]
                      newDefendantsData[index] = { ...newDefendantsData[index], name: value }
                      setDefendantsData(newDefendantsData)
                    }}
                  />
                  <Input
                    label='CPF/CNPJ'
                    hasLabel
                    placeholder='Exemplo: 123.456.789-00'
                    value={defendantsData[index]?.cpfCnpj}
                    onChange={value => {
                      const newDefendantsData = [...defendantsData]
                      newDefendantsData[index] = {
                        ...newDefendantsData[index],
                        cpfCnpj: formatDocument(value),
                      }
                      setDefendantsData(newDefendantsData)
                    }}
                  />
                  <Input
                    label='Endereço'
                    hasLabel
                    placeholder='Exemplo: Rua das Flores, 123, São Paulo/SP'
                    value={defendantsData[index]?.address}
                    onChange={value => {
                      const newDefendantsData = [...defendantsData]
                      newDefendantsData[index] = { ...newDefendantsData[index], address: value }
                      setDefendantsData(newDefendantsData)
                    }}
                  />
                  <div style={{ display: 'flex', gap: '12px' }}>
                    <Input
                      label='Telefone'
                      hasLabel
                      placeholder='Exemplo: (11) 98765-4321'
                      value={defendantsData[index]?.phone}
                      onChange={value => {
                        const newDefendantsData = [...defendantsData]
                        newDefendantsData[index] = {
                          ...newDefendantsData[index],
                          phone: phone(value),
                        }
                        setDefendantsData(newDefendantsData)
                      }}
                    />
                    <Input
                      label='Email'
                      hasLabel
                      placeholder='Exemplo: email@exemplo.com.br'
                      value={defendantsData[index]?.email}
                      onChange={value => {
                        const newDefendantsData = [...defendantsData]
                        newDefendantsData[index] = { ...newDefendantsData[index], email: value }
                        setDefendantsData(newDefendantsData)
                      }}
                    />
                  </div>
                </fieldset>
              ))
            ) : (
              <>
                <Input
                  label='Nome'
                  hasLabel
                  placeholder='Exemplo: João da Silva'
                  value={defendantsData[0]?.name}
                  onChange={value => setDefendantsData([{ ...defendantsData[0], name: value }])}
                  required
                />
                <Input
                  label='CPF/CNPJ'
                  hasLabel
                  placeholder='Exemplo: 123.456.789-00'
                  value={defendantsData[0]?.cpfCnpj}
                  onChange={value =>
                    setDefendantsData([{ ...defendantsData[0], cpfCnpj: formatDocument(value) }])
                  }
                />
                <Input
                  label='Endereço'
                  hasLabel
                  placeholder='Exemplo: Rua das Flores, 123, São Paulo/SP'
                  value={defendantsData[0]?.address}
                  onChange={value => setDefendantsData([{ ...defendantsData[0], address: value }])}
                />
                <div style={{ display: 'flex', gap: '12px' }}>
                  <Input
                    label='Telefone'
                    hasLabel
                    placeholder='Exemplo: (11) 98765-4321'
                    value={defendantsData[0]?.phone}
                    onChange={value =>
                      setDefendantsData([{ ...defendantsData[0], phone: phone(value) }])
                    }
                  />
                  <Input
                    label='Email'
                    hasLabel
                    placeholder='Exemplo: email@exemplo.com.br'
                    value={defendantsData[0]?.email}
                    onChange={value => setDefendantsData([{ ...defendantsData[0], email: value }])}
                  />
                </div>
              </>
            )}
          </fieldset>
        </AccordionItem>

        <AccordionItem
          title='2. Fatos'
          subtitle='Insira uma descrição detalhada dos fatos que motivam a ação'
          value='facts'>
          <fieldset>
            <legend>Descrição Detalhada dos Fatos</legend>
            <TextArea
              label='Descrição dos Fatos'
              hasLabel
              placeholder={`Insira uma descrição dos fatos que motivam a ação ou, se preferir, clique no ícone de microfone para narrar os fatos oralmente.\n\nExemplo: "Em 10 de abril de 2024, o autor celebrou contrato de prestação de serviços de reforma com a ré, contratando-a para a realização de obras em sua residência. No entanto, após o pagamento de 50% do valor acordado, a ré não cumpriu os prazos estipulados e entregou o serviço com graves defeitos, como infiltrações e acabamento de má qualidade. Apesar das diversas tentativas de contato para solucionar os problemas, a ré não se dispôs a realizar os reparos necessários, causando prejuízos financeiros e transtornos significativos ao autor."`}
              value={facts}
              onChange={e => setFacts(e)}
              maxLength={2000}
              shouldListen
              startListeningFunction={() => startListening('facts')}
              stopListeningFunction={stopListening}
              isListening={listeningField === 'facts'}
              transcribeAudioFunction={() => handleStartTranscription('facts')}
              isTranscribing={fieldsBeingTranscribed.includes('facts')}
              disabled={fieldsBeingTranscribed.includes('facts')}
              required
            />
          </fieldset>
        </AccordionItem>

        <AccordionItem
          title='3. Provas'
          subtitle='Insira informações sobre as provas produzidas e a produzir'
          value='evidences'>
          <fieldset>
            <legend>Provas</legend>
            <TextArea
              label='Provas Produzidas'
              hasLabel
              value={evidences.producedEvidence}
              onChange={e => setEvidences({ ...evidences, producedEvidence: e })}
              placeholder={`Insira uma breve descrição das provas já produzidas.\n\nExemplo: "O autor já produziu as seguintes provas: a) contrato de prestação de serviços de reforma; b) comprovantes de pagamento; c) fotos do serviço realizado; d) troca de mensagens com a ré."`}
              maxLength={1000}
              shouldListen
              startListeningFunction={() => startListening('producedEvidence')}
              stopListeningFunction={stopListening}
              isListening={listeningField === 'producedEvidence'}
              transcribeAudioFunction={() => handleStartTranscription('producedEvidence')}
              isTranscribing={fieldsBeingTranscribed.includes('producedEvidence')}
              disabled={fieldsBeingTranscribed.includes('producedEvidence')}
            />

            <TextArea
              label='Provas a Produzir'
              hasLabel
              value={evidences.pendingEvidence}
              onChange={e => setEvidences({ ...evidences, pendingEvidence: e })}
              placeholder={`Insira uma breve descrição das provas que se pretende produzir.\n\nExemplo: "O autor pretende produzir as seguintes provas: a) depoimento pessoal das partes; b) oitiva de testemunhas; c) realização de perícia técnica."`}
              maxLength={1000}
              shouldListen
              startListeningFunction={() => startListening('pendingEvidence')}
              stopListeningFunction={stopListening}
              isListening={listeningField === 'pendingEvidence'}
              transcribeAudioFunction={() => handleStartTranscription('pendingEvidence')}
              isTranscribing={fieldsBeingTranscribed.includes('pendingEvidence')}
              disabled={fieldsBeingTranscribed.includes('pendingEvidence')}
            />
          </fieldset>
        </AccordionItem>

        <AccordionItem
          title='4. Pedidos'
          subtitle='Insira informações sobre os pedidos a serem formulados'
          value='claims'>
          <fieldset>
            <legend>Pedidos Indispensáveis</legend>
            <TextArea
              label='Pedidos Indispensáveis'
              hasLabel
              placeholder={
                'Se for o caso, informe os pedidos que não podem faltar na petição. Caso não sejam indicados, o Spectter gerará os pedidos automaticamente com base nos fatos narrados.'
              }
              value={claims.necessaryClaims}
              onChange={e => setClaims({ ...claims, necessaryClaims: e })}
              maxLength={2000}
            />
          </fieldset>
          <fieldset>
            <legend>Pedidos Complementares</legend>
            {complementaryClaimsOptions.map(option => (
              <Checkbox
                key={option}
                label={option}
                value={claims.complementaryClaims.includes(option)}
                onChange={value =>
                  value
                    ? setClaims({
                        ...claims,
                        complementaryClaims: [...claims.complementaryClaims, option],
                      })
                    : setClaims({
                        ...claims,
                        complementaryClaims: claims.complementaryClaims.filter(
                          claim => claim !== option,
                        ),
                      })
                }
                blackCheck
              />
            ))}
          </fieldset>

          <fieldset>
            <legend>Valor da Causa</legend>
            <Input
              label='Valor da Causa'
              value={processValue}
              onChange={value => setProcessValue(currency(value))}
              hasLabel
              required
              placeholder='Exemplo: R$ 10.000,00'
            />
          </fieldset>
        </AccordionItem>

        <Button contained fullWidth disabled={isSubmitting} style={{ marginTop: '72px' }}>
          {isSubmitting ? <Spinner size={24} /> : 'Gerar argumentos'}
        </Button>
        <input
          type='file'
          ref={inputFileRef}
          hidden
          onChange={handleSelectFile}
          accept='.mp3, .wav, .ogg'
          size={MAX_FILE_SIZE}
          multiple={false}
        />
      </Accordion.Root>
      {isSubmitting && (
        <DynamicLoading
          title='Gerando argumentos'
          subtitle='Estamos trabalhando na criação dos argumentos da sua petição. Por favor, não feche esta janela.'
          loadingMessage='Preparando os argumentos jurídicos mais relevantes...'
        />
      )}
    </form>
  )
}

export default Complaints
