import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { ReactComponent as LogoMobile } from '../../assets/logo-icon.svg'
import { ReactComponent as Logo } from '../../assets/spectter.svg'
import Avatar from '../Avatar'
import DropDown from '../DropDown'
import HeaderProgressBar from '../HeaderProgressBar'
import CustomIcon from '../CustomIcon'

import { getUserUsage } from '../../services/authServices'

import './index.scss'

function Header() {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.app)
  const navigate = useNavigate()
  const headerRef = useRef(null)
  const queryClient = useQueryClient()

  async function logOut() {
    localStorage.removeItem('user')
    dispatch({ type: 'CHANGE_USER', payload: {} })
    queryClient.removeQueries({ queryKey: 'documents-list', exact: true })
    queryClient.removeQueries({ queryKey: 'readDocs-list', exact: true })
    navigateTo('/login')
  }

  const fullName = user.name + ' ' + user.surname

  function navigateTo(path) {
    navigate(path)
  }

  function returnTools() {
    let tools = []
    if (user.token) {
      // const { readDocs, createDocs, isDeveloper } = user.features
      // if (readDocs) {
      tools.push({
        label: '<strong>Consulta</strong> de documentos',
        action: () => {
          navigateTo('/lista-consultar-documentos')
        },
        icon: 'Search',
      })
      // }

      // if (createDocs) {
      tools.push({
        label: '<strong>Criação</strong> de documentos',
        action: () => {
          navigateTo('/lista-criar-documentos')
        },
        icon: 'File',
      })

      tools.push({
        label: '<strong>Transcrição</strong> de áudio/vídeo',
        action: () => {
          navigateTo('/transcricoes')
        },
        icon: 'ListVideo',
      })
      // }

      // if (isDeveloper) {
      //   tools.push({
      //     label: '<strong>Documentação</strong> técnica',
      //     action: () => {
      //       navigateTo('/documentacao')
      //     },
      //     icon: 'Settings',
      //   })
      // }
    }
    return tools
  }

  async function getUsageQuantity() {
    try {
      const { data } = await getUserUsage()
      return data
    } catch (error) {
      console.log(error)
      return { usage: 0 }
    }
  }

  const { isLoading, data } = useQuery({
    queryKey: ['usage-quantity'],
    queryFn: getUsageQuantity,
    enabled: !!user.token,
    refetchOnWindowFocus: false,
  })

  return (
    <header className='header' ref={headerRef}>
      <div className='header__logo header__logo--desktop'>
        <button
          onClick={() => {
            navigateTo('/')
          }}>
          <Logo />
        </button>
        <p>
          powered by{' '}
          <a href='https://www.semprocesso.com.br/' target='_blank noopener noreferrer'>
            sem processo
          </a>
        </p>
      </div>

      <div className='header__logo header__logo--mobile'>
        <button
          onClick={() => {
            navigateTo('/')
          }}>
          <LogoMobile />
        </button>
      </div>

      <nav>
        <HeaderProgressBar title='Utilização' percent={data?.usage} loading={isLoading} />

        <DropDown
          className='header__nav__dropdown'
          container={headerRef.current}
          options={returnTools()}>
          <CustomIcon icon='Hammer' className='header__nav__dropdown__icon' />
          Ferramentas
        </DropDown>

        <DropDown
          // noArrow
          container={headerRef.current}
          options={[
            {
              label: 'Configurações',
              action: () => {
                navigateTo('/configuracoes')
              },
              icon: 'Settings',
            },
            {
              label: 'Sair',
              action: logOut,
              icon: 'LogOut',
            },
          ]}>
          <>
            <div className='header__user header__user--desktop'>
              <Avatar name={fullName} size='large' />
              <div className='header__user__data'>
                <p>{fullName}</p>
                <span>{user.groupName}</span>
              </div>
            </div>
            <div className='header__user header__user--mobile'>
              <Avatar name={fullName} size='large' />
            </div>
          </>
        </DropDown>
      </nav>
    </header>
  )
}

export default Header
