import React from 'react'
import { ClipLoader } from 'react-spinners'

import './index.scss'

const Spinner = ({ color = '#009688', size = 35, containerHeight = 'auto' }) => {
  return (
    <div className={`spinner-container`} style={{ height: containerHeight }}>
      <ClipLoader color={color} size={size} />
    </div>
  )
}

export default Spinner
