import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

// Constants
import { API_KEY, FAQ } from './constants'

// Styles
import './index.scss'
import DocumentationAccordion from './components/DocumentationAccordion'
import Spinner from '../../components/Spinner'

function TechnicalDocumentation() {
  const getCollectionData = async () => {
    const timestamp = Date.now() // Generate a unique value (e.g., timestamp)
    const url = `https://api.getpostman.com/collections/c437f265-7f4d-4c6c-bafc-24a196611e3c?_=${timestamp}`

    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url,
      headers: {
        'X-API-Key': API_KEY,
      },
    }

    const { data } = await axios.request(config)
    return data.collection
  }

  const { data, isSuccess } = useQuery({
    queryKey: ['collectionData'],
    queryFn: getCollectionData,
    onError: error => {
      window.location.reload()
    },
    staleTime: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    retry: 1,
  })

  if (!isSuccess) {
    return (
      <div
        style={{
          display: 'flex',
          height: '80dvh',
          width: '100dvw',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Spinner />
      </div>
    )
  }

  return (
    <main className='tech-documentation__main'>
      <ReactMarkdown
        className='tech-documentation__markdown__container'
        remarkPlugins={[remarkGfm]}>
        {data?.info.description}
      </ReactMarkdown>
      <hr className='tech-documentation__separator' />
      <article className='tech-documentation__markdown__container'>
        <h2>Postman Collections</h2>
        <p style={{ marginBottom: '48px' }}>
          As collections do Postman podem ser conferidas abaixo e estão organizadas como{' '}
          <code>collection</code> {'>'} <code>rotas</code> {'>'} <code>endpoint</code>
        </p>
      </article>
      <div>
        {data?.item.map(item => (
          <DocumentationAccordion items={data?.item} />
        ))}
      </div>
      <section className='tech-documentation__markdown__container'>
        <h2>F.A.Q.</h2>
        {FAQ.map(({ title, content }) => (
          <article>
            <h4>{title}</h4>
            <p>{content}</p>
          </article>
        ))}
      </section>
    </main>
  )
}

export default TechnicalDocumentation
