import * as Tooltip from '@radix-ui/react-tooltip'
import React, { useEffect, useState } from 'react'
import './index.scss'

const CustomTooltip = ({ name, description, children }) => {
  const [open, setOpen] = useState(false)
  const [hover, setHover] = useState(false)

  useEffect(() => {
    if (hover) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [hover])

  return (
    <Tooltip.Provider className='custom-tooltip'>
      <Tooltip.Root open={open} delayDuration={0}>
        <Tooltip.Trigger onClick={() => setOpen(true)} asChild>
          <button
            className='custom-tooltip__trigger'
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}>
            {children}
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            align='center'
            className='tooltip__content'
            sideOffset={5}
            onPointerDownOutside={() => setOpen(false)}>
            <p>{name}</p>
            <span>{description}</span>
            <Tooltip.Arrow className='tooltip__arrow' />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

export default CustomTooltip
