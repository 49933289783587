import React from 'react'

import CustomIcon from '../../../../components/CustomIcon'

import './index.scss'

function Pagination({ results, currentResultIndex, setCurrentResultIndex, isGenerating = false }) {
  return (
    <div className='pagination-container'>
      {/* <Flag type='alert' message='Escolha o resultado que deseja utilizar antes de ir para a próxima etapa.' /> */}
      <div className='pagination__top'>
        <h1 className='pagination__title'>Lista de resultados recentes</h1>
        <div className='pagination__button-space'>
          {/* {currentResultIndex > 0 && */}
          <button
            className='pagination__button'
            onClick={() => setCurrentResultIndex(currentResultIndex - 1)}
            disabled={currentResultIndex < 1 || isGenerating}>
            <CustomIcon className={'pagination__button__icon'} icon={'ArrowLeftCircle'} />
          </button>
          {/* } */}
          {/* {currentResultIndex < results?.length -1 && */}
          <button
            className='pagination__button'
            onClick={() => setCurrentResultIndex(currentResultIndex + 1)}
            disabled={currentResultIndex >= results?.length - 1 || isGenerating}>
            <CustomIcon className={'pagination__button__icon'} icon={'ArrowRightCircle'} />
          </button>
        </div>
      </div>
      <div className='pagination__cards'>
        {results?.map((r, index) => (
          <button
            key={index}
            className={
              index === currentResultIndex ? 'pagination__card--current' : 'pagination__card'
            }
            onClick={!isGenerating ? () => setCurrentResultIndex(index) : undefined}
            disabled={isGenerating}>
            <p>#{index + 1} Resultado</p>
          </button>
        ))}
        {isGenerating && (
          <button
            key={results.length}
            className={
              results.length === currentResultIndex
                ? 'pagination__card--current'
                : 'pagination__card'
            }
            onClick={() => setCurrentResultIndex(results.length)}
            disabled={isGenerating}>
            <p>#{results.length + 1} Resultado</p>
          </button>
        )}
      </div>
    </div>
  )
}

export default Pagination
