import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CustomIcon from '../../../../components/CustomIcon'
import { Button, Input } from '../../../../components/DesignSystem'
import './index.scss'
import CustomTooltip from '../../../../components/CustomTooltip'

function ParticipantSnippet({ participant, transcript }) {
  const dispatch = useDispatch()
  const { replacements } = useSelector(state => state.transcript)

  const [currentSnippet, setCurrentSnippet] = useState(0)
  const [participantName, setParticipantName] = useState('')
  const [participantNameSaved, setParticipantNameSaved] = useState(participant)
  const [parsedSpeeches, setParsedSpeeches] = useState([])

  useEffect(() => {
    if (!participantName) {
      const participantSpeeches = transcript.split('\n').filter(line => line.includes(participant))
      const parsedSpeeches = participantSpeeches.map(speech => {
        speech = speech.replace(`${participant}:`, '').trim()
        return speech
      })
      setParsedSpeeches(parsedSpeeches)
    } else {
      if (replacements.find(replacement => replacement.name === participantName)) {
        const participantSpeeches = transcript.split('\n').filter(line => {
          const regex = new RegExp(`${participantName}:`)
          const match = line.match(regex)
          const matchIndex = match?.index
          if (!matchIndex) return false

          // 8 is the index of the exact match in the string:
          // eg: "[21:30] Advogado Autora: Doutor, se o senhor me permite,..."
          // on the string above, the matchIndex for "Autora" is 17, so its not an exact match
          return matchIndex === 8
        })
        const parsedSpeeches = participantSpeeches.map(speech => {
          speech = speech.replace(`${participantName}:`, '').trim()
          return speech
        })
        setParsedSpeeches(parsedSpeeches)
      }
    }
  }, [transcript, participant])

  function handleSnippetNavigation(direction) {
    if (direction === 'prev') {
      if (currentSnippet === 0) return
      setCurrentSnippet(currentSnippet - 1)
    } else {
      if (currentSnippet === parsedSpeeches.length - 1) return
      setCurrentSnippet(currentSnippet + 1)
    }
  }

  function handleHighlightSnippet() {
    dispatch({ type: 'HIGHLIGHT_TRANSCRIPT', payload: parsedSpeeches[currentSnippet] })
  }

  function handleApplyParticipantName() {
    const transcriptWithParticipantName = participantNameSaved
      ? transcript.replaceAll(`${participantNameSaved}:`, `${participantName}:`)
      : transcript.replaceAll(participant, participantName)
    dispatch({ type: 'CHANGE_TRANSCRIPT', payload: transcriptWithParticipantName })
    dispatch({ type: 'CHANGE_REPLACEMENT', payload: { label: participant, name: participantName } })
    setParticipantNameSaved(participantName)
  }

  return (
    <article
      className={`participant-snippet ${
        participantNameSaved === participantName && 'participant-snippet--saved'
      }`}>
      <div className='participant-snippet__subsection--column'>
        <header className='participant-snippet__heading'>
          <h4>Trechos do áudio</h4>
          <CustomTooltip
            name='Trechos da Conversa'
            description='Os trechos retirados do áudio possuem marcação do tempo de início e fim do excerto para auxiliar identificação.
            Utilize as setas para alternar rapidamente entre os trechos associados a este participante'>
            <CustomIcon icon='HelpCircle' size={16} color='#64FEDA' />
          </CustomTooltip>
        </header>
        <div className='participant-snippet__content'>
          <div className='participant-snippet__content-navigation'>
            <p>Trechos deste participante</p>
            <nav>
              <button onClick={() => handleSnippetNavigation('prev')}>
                <CustomIcon
                  icon='ArrowLeft'
                  size={16}
                  color={currentSnippet === 0 ? '#959595' : '#64FEDA'}
                />
              </button>
              <span>
                {currentSnippet + 1}/{parsedSpeeches.length}
              </span>
              <button onClick={() => handleSnippetNavigation('next')}>
                <CustomIcon
                  icon='ArrowRight'
                  size={16}
                  color={currentSnippet === parsedSpeeches.length - 1 ? '#959595' : '#64FEDA'}
                />
              </button>
            </nav>
          </div>
          <div className='participant-snippet__content-transcript'>
            {parsedSpeeches[currentSnippet]}
          </div>
        </div>
      </div>
      <fieldset className='participant-snippet__subsection--column'>
        <header className='participant-snippet__heading'>
          <h4>Nomeação do participante</h4>
          <CustomTooltip
            name='Nomeação do Participante'
            description='Para um melhor resultado é importante que o nome possua um contexto ou qualificação.
            Exemplo: dê preferência para "Testemunha de Acusação Maria" em vez de apenas "Maria"'>
            <CustomIcon icon='HelpCircle' size={16} color='#64FEDA' />
          </CustomTooltip>
        </header>
        <div className='participant-snippet__identification'>
          <div className='participant-snippet__identification--label'>
            <p>Identificação do participante</p>
            <h6>{participant}</h6>
          </div>
          <div className='dotted-connector'></div>
          <Input
            label='Nomeie o participante'
            placeholder='Digite o nome do participante'
            value={participantName}
            onChange={e => setParticipantName(e)}
          />
        </div>
        <div className='participant-snippet__subsection--row'>
          <Button
            outlined
            style={{
              fontSize: '10px',
              fontWeight: '700',
              padding: '4px 8px',
            }}
            textUppercase
            type='button'
            onClick={handleHighlightSnippet}>
            Localizar
          </Button>
          <Button
            contained
            style={{
              fontSize: '10px',
              fontWeight: '700',
              padding: '4px 8px',
            }}
            textUppercase
            type='button'
            onClick={handleApplyParticipantName}>
            {participantNameSaved === participantName ? 'Salvo' : 'Aplicar'}
          </Button>
        </div>
      </fieldset>
    </article>
  )
}

export default ParticipantSnippet
