import axios from 'axios'

export async function uploadMediaDocument({ formData, setProgress }) {
  const data = await axios.post('/assemblyai/media', formData, {
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      if (percentCompleted === 100) {
        return setProgress({
          value: 50,
          message: 'Processando arquivo, esta etapa pode demorar alguns minutos...',
        })
      }
      return setProgress({ value: percentCompleted / 2, message: 'Enviando arquivo...' })
    },
  })

  return data
}

export async function transcribeAudio({ formData }) {
  const data = await axios.post('/assemblyai/transcribe-sync', formData)

  return data
}
