import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import TitleSpace from '../../components/TitleSpace'
import Pagination from './components/Pagination'
import ButtonFN from '../../components/ButtonFN'
import Editor from './components/Editor'
import Spinner from '../../components/Spinner'
import DocumentGenerator from '../../components/DocumentGenerator'

import { createDocument, getDocument } from '../../services/documentsServices'

import './index.scss'

function Document() {
  const { documentId } = useParams()
  // const [newDocumentId, setNewDocumentId] = useState()
  const [results, setResults] = useState()
  const [newResult, setNewResult] = useState('')
  const [currentResult, setCurrentResult] = useState(0)
  const [currentResultIndex, setCurrentResultIndex] = useState(0)
  const [complement, setComplement] = useState('')
  const [isGenerating, setIsGenerating] = useState(false)
  const queryClient = useQueryClient()

  const {
    user: { token, status },
  } = useSelector(state => state.app)

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['document-request', token, documentId],
    queryFn: async () => {
      if (token) {
        const result = await getDocument(documentId, token)
        return result
      }
    },
    retry: 3,
    enabled: token ? true : false,
  })

  async function editResult() {
    if (complement !== '') {
      setIsGenerating(true)
      setCurrentResultIndex(results.length)
      await createDocument('', 'notification', documentId, complement, token, setNewResult, false)
      refetch()
      queryClient.invalidateQueries(['usage-quantity'])
      setIsGenerating(false)
      setNewResult('')
    }
  }

  useEffect(() => {
    if (data) {
      setResults(data?.document.messages?.filter(message => message.role === 'assistant'))
    }
    setComplement('')
  }, [data])

  useEffect(() => {
    setCurrentResultIndex(results?.length - 1)
  }, [results])

  useEffect(() => {
    if (results && results[currentResultIndex]) {
      setCurrentResult(results[currentResultIndex]?.content)
    }
  }, [results, currentResultIndex])

  return (
    <section className='document-container'>
      <div className='document__column'>
        <TitleSpace
          title='Documento'
          subtitle={
            'Confira o texto criado pela inteligência artificial e edite caso necessário. Se quiser inserir anexos na sua notificação, siga estas instruções.'
          }
        />
        <div className='document__info'>
          <Pagination
            results={results}
            currentResultIndex={currentResultIndex}
            setCurrentResultIndex={setCurrentResultIndex}
            isGenerating={isGenerating}
          />

          <div className='document__result'>
            <div className='document__result__title-space'>
              <h1 className='document__result__title'>Resultado de notificação</h1>
              {!isGenerating && (
                <div className='document__result__icon-space'>
                  <ButtonFN
                    type='copy'
                    value={currentResult}
                    className='document__result__button'
                  />
                  <ButtonFN
                    type='download'
                    value={currentResult}
                    className='document__result__button'
                    documentName={data?.document?.name}
                  />
                </div>
              )}
            </div>
            {data && isGenerating ? (
              <DocumentGenerator result={newResult} />
            ) : (
              <ReactMarkdown remarkPlugins={[remarkGfm]} className='markdown__container document__result__text'>
                {currentResult}
              </ReactMarkdown>
            )}
            {isLoading && <Spinner />}
            {isError && <h1>Erro interno no servidor</h1>}
          </div>
        </div>

        {!isGenerating && (
          <Editor
            value={complement}
            setValue={setComplement}
            placeholder='Use qualquer comando para modificar o documento.'
            handleSubmit={editResult}
            disabled={status !== 'paid'}
          />
        )}
      </div>
    </section>
  )
}

export default Document
