import React from 'react'
import * as FeatherIcon from 'lucide-react'
// import Loading from '../../Loading'
import Spinner from '../../Spinner'
import './index.scss'

/**
 *
 * @param {String} size - small, medium, large
 * @param {Boolean} contained - variation contained
 * @param {Boolean} outlined - variation outlined
 * @param {Boolean} disabled - disable button
 * @param {String} icon - Feather icon name
 * @param {Boolean} iconRight - Align icon to right
 * @param {Boolean} fullWidth - full width
 * @param {Boolean} loading
 * @param {Boolean} sizeIcon - size of icon
 * @param {Boolean} textUppercase - TextTransform of text
 * @param {Boolean} rounded - borderRadius 50px
 * @param {String} color - green, gray, black
 * @param {Boolean} bordered - borderRadius 4px
 *
 * @returns
 */
function Button({
  children = '',
  icon = '',
  iconRight = false,
  disabled = false,
  contained = false,
  outlined = false,
  color = 'green',
  size = 'small',
  loading = false,
  fullWidth = false,
  textUppercase = false,
  sizeIcon = 24,
  rounded = false,
  onlyIcon = false,
  bordered = false,
  customClassName,
  ...props
}) {
  function setClassName() {
    if (customClassName) return customClassName
    let className = 'button'
    if (size && !children) className += ` button--${size}--square`
    if (loading) className += ' button--loading'
    if (iconRight) className += ' button--icon-right'
    if (fullWidth) className += ' button--full-width'
    if (textUppercase) className += ' button--uppercase'
    if (rounded) className += ' button--rounded'
    if (onlyIcon) className += ' button--only-icon'
    if (rounded && size) className += ` button--rounded--${size}`
    if (bordered) className += ' button--bordered'
    if (contained) className += ` button--contained--${color}`
    else if (outlined) className += ` button--outlined--${color}`
    else className += ` button--text--${color}`
    return className
  }

  const CustomIcon = ({ sizeIcon }) => {
    const Icon = FeatherIcon[icon]
    return <Icon size={sizeIcon} />
  }

  return (
    <button className={setClassName()} disabled={disabled} {...props}>
      {!loading && icon && <CustomIcon sizeIcon={sizeIcon} />}
      {loading && <Spinner color={color === 'green' & contained ? '#1b1b1b' : '#ffffff'} size={12} />}
      {children && !loading && <span>{children}</span>}
    </button>
  )
}

export default Button
