import React from 'react'
import * as ScrollAreaRadix from '@radix-ui/react-scroll-area'

import './index.scss'

function ScrollArea({ children, width = '100%', height = '100%', maxHeight = 240 }) {
  return (
    <ScrollAreaRadix.Root style={{ width, height, maxHeight }} className='scroll-area__root'>
      <ScrollAreaRadix.Viewport className='scroll-area__viewport'>
        {children}
      </ScrollAreaRadix.Viewport>
      <ScrollAreaRadix.Scrollbar orientation='horizontal' className='scroll-area__scroll-bar'>
        <ScrollAreaRadix.Thumb />
      </ScrollAreaRadix.Scrollbar>
      <ScrollAreaRadix.Scrollbar orientation='vertical' className='scroll-area__scroll-bar'>
        <ScrollAreaRadix.Thumb className='scroll-area__thumb' />
      </ScrollAreaRadix.Scrollbar>
      <ScrollAreaRadix.Corner className='scroll-area__corner' />
    </ScrollAreaRadix.Root>
  )
}

export default ScrollArea
