import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'

// Components
import CustomIcon from '../../CustomIcon'

// Styles
import './index.scss'

let prevStyle = {}

/**
 * Tabs component
 * @param {{
 * tabs: [{ label: string, action: function }]
 * id: string
 * key: string
 * disabledTabs: ?array
 * maxWidth: ?number | string
 * initialActiveTab: ?number
 * }} props Props
 * @example
 * <Tabs
 *   tabs={[{icon: "Example", tabName: "Example", path: "/example"}, ...]}
 *   id="example"
 *   key="example"
 *   currentTab={{icon: "Example", tabName: "Example", path: "/example"}}
 *   onChangeTab={() => {}}
 * />
 * @returns {React.Element} - the Tabs component
 */
function CustomTabs({
  tabs = [{ icon: 'Trello', tabName: 'Sample', key: 'sample' }],
  id,
  disabledTabs = [],
  currentTab,
  maxWidth,
  type = 'outlined',
  onChangeTab,
  isMobileFull = false,
  ...props
}) {
  // const isMobile = useMedia('(max-width: 768px)')
  const outlinedTabsRef = useRef(null)
  const [styleSelectedTab, setStyleSelectedTab] = useState({ width: 0, left: 0, ...prevStyle[id] })

  function handleClickTab(tab) {
    if (disabledTabs.includes(tab.tabName)) return
    if (currentTab.tabName === tab.tabName) return
    onChangeTab(tab)
  }

  function returnContainedClassName(tab) {
    let className = 'contained-tabs__tab'

    if (currentTab?.tabName === tab?.tabName) className += ' contained-tabs__tab--selected'

    return className
  }

  function returnOutlinedClassName(tab) {
    let className = 'outlined-tabs__item'

    if (currentTab?.tabName === tab?.tabName) className += ' outlined-tabs__item--selected'
    if (tab.tabName && disabledTabs.includes(tab.tabName))
      className += ' outlined-tabs__item--disabled'

    return className
  }

  function returnOutlinedTabId(tab, index) {
    let tabId = id

    if (currentTab?.tabName === tab?.tabName) {
      tabId += `-tab-selected`
      return tabId
    }

    return `${tabId}-${index}`
  }

  useEffect(() => {
    function defineTabSelectedStyle() {
      const selectedTab = document.getElementById(`${id}-tab-selected`)

      if (selectedTab) {
        const { width = 0 } = selectedTab.getBoundingClientRect()
        const style = {
          width: `${width}px`,
          left: `${selectedTab.offsetLeft}px`,
        }

        prevStyle[id] = { ...style }
        setStyleSelectedTab(style)
      }
    }

    defineTabSelectedStyle()
    window.addEventListener('resize', defineTabSelectedStyle)

    return () => {
      window.removeEventListener('resize', defineTabSelectedStyle)
    }
  }, [currentTab, id])

  if (type === 'contained') {
    return (
      <div className='contained-tabs__container '>
        {tabs.map((tab, index) => {
          return (
            <button
              key={index}
              className={returnContainedClassName(tab)}
              onClick={() => onChangeTab(tab)}>
              <span>{tab.tabName}</span>
              <CustomIcon icon={tab.icon} size={18} />
            </button>
          )
        })}
      </div>
    )
  }

  return (
    <div
      className={`outlined-tabs${isMobileFull ? '--mobile-full' : ''}`}
      style={{ maxWidth }}
      {...props}
      ref={outlinedTabsRef}>
      {tabs.map((tab, index) => (
        <button
          id={returnOutlinedTabId(tab, index)}
          key={index}
          className={returnOutlinedClassName(tab)}
          onClick={() => handleClickTab(tab)}>
          <CustomIcon icon={tab.icon || 'File'} size={24} />
          <span>{tab.tabName}</span>
        </button>
      ))}
      {!_.isEmpty(currentTab) && (
        <div className='outlined-tabs__choice' style={{ ...styleSelectedTab }} />
      )}
    </div>
  )
}

export default CustomTabs
