import _ from 'lodash'
import { useState, useRef } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
// import { encodingForModel } from 'js-tiktoken'

// Services
import { cancelChatRun, getDocument, postQuestion } from '../../services/readDocsServices'

// Components
import CustomIcon from '../CustomIcon/index'
import ChatMessage from '../ChatMessage'
import Spinner from '../Spinner'
import SuggestionCard from '../SuggestionCard'
import FavoritePrompts from '../FavoritePrompts'
import { Button } from '../DesignSystem'
import LegacyCard from '../LegacyCard'

// Custom hooks
import { useMedia } from '../../hooks/useMedia'

// Styles
import './index.scss'

function ChatViewer({ docId, setLoadingChat, legacy = false }) {
  const {
    user: { token, status },
  } = useSelector(state => state.app)
  const [question, setQuestion] = useState('')
  const [isGenerating, setIsGenerating] = useState(false)
  const [streamedResponse, setStreamedResponse] = useState('')
  const queryClient = useQueryClient()
  // const encoding = useMemo(() => encodingForModel('gpt-4-turbo-preview'), [])

  const isMobile = useMedia('(max-width: 720px)')
  const messageRef = useRef(null)

  const suggestions = [
    'Faça um relatório jurídico dos pontos principais do documento.',
    'Quais são os instrumentos legais mencionados no documento?',
    'Existe alguma incongruência no texto do documento?',
  ]

  const { isLoading: isLoadingChat, data } = useQuery({
    queryKey: ['chat-download', docId],
    queryFn: () => fetchDocument(),
    staleTime: 1000 * 60 * 60 * 24,
    enabled: !_.isEmpty(token),
    refetchOnWindowFocus: true,
    retry: false,
    keepPreviousData: true,
  })

  async function fetchDocument() {
    try {
      const { data } = await getDocument(docId)
      const file = data.file
      setLoadingChat(false)

      if (data.document?.chat.length > 0) {
        data.document.chat = data.document.chat.reverse()
      }

      return { document: data.document, file }
    } catch (error) {
      setLoadingChat(false)
      console.log(error)
    }
  }

  function setQuestionQueryData(message) {
    queryClient.setQueryData(['chat-download', docId], prev => {
      return {
        ...prev,
        document: {
          ...prev.document,
          chat: [
            {
              _id: String(Math.random()),
              profile: 'user',
              message,
            },
            ...prev.document.chat,
          ],
        },
      }
    })
  }

  function setResponseQueryData(response) {
    queryClient.setQueryData(['chat-download', docId], prev => {
      return {
        ...prev,
        document: {
          ...prev.document,
          chat: [
            {
              _id: String(Math.random()),
              profile: 'system',
              message: response,
            },
            ...prev.document.chat,
          ],
        },
      }
    })
  }

  const sendQuestion = useMutation(
    suggestion => {
      let newMessage = suggestion || question
      /* Add new user message in chat cache */
      setQuestionQueryData(newMessage)

      setLoadingChat(true)
      setQuestion('')
      setIsGenerating(true)

      postQuestion({
        chatId: docId,
        prompt: newMessage,
        token,
        setStreamedResponse,
        setIsGenerating,
        setResponseQueryData,
      })
    },
    {
      onSuccess: () => {
        /* Invalidate query after send question to refetch chat data */
        queryClient.invalidateQueries(['usage-quantity'])
        // queryClient.invalidateQueries(['chat-download'])
      },
      onError: error => {
        queryClient.setQueryData(['chat-download', docId], prev => {
          const newchat = [...prev.document.chat]
          newchat.pop()
          return {
            ...prev,
            document: {
              ...prev.document,
              chat: newchat,
            },
          }
        })
        setLoadingChat(false)
        const errorContent = JSON.stringify(error)
        toast.error(
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {error.response?.data?.errors[0]?.message || 'Erro interno do servidor'}
            <Button
              icon='Copy'
              onlyIcon
              onClick={() => {
                navigator.clipboard.writeText(errorContent)
                toast.success('Erro copiado para área de transferência')
              }}
            />
          </div>,
        )
      },
    },
  )

  return (
    <div className='chat-viewer'>
      {/* {isGenerating && <div>{streamedResponse}</div>} */}
      <div className='chat-viewer__messages-container'>
        <div className='chat-viewer__messages'>
          {isGenerating && <ChatMessage profile='system' message={streamedResponse} hasLoader />}
          {isLoadingChat && <Spinner />}
          {!_.isEmpty(data?.document) &&
            data?.document.chat.length > 0 &&
            data.document.chat.map(c => {
              return <ChatMessage key={c._id} profile={c.profile} message={c.message} />
            })}
          <div ref={messageRef} />
        </div>
      </div>
      <div className='chat-viewer__back-space'>
        {!legacy && !_.isEmpty(data?.document) && data?.document.chat.length === 0 && (
          <div className='chat-viewer__suggestions-container'>
            {suggestions.map((item, idx) => {
              return (
                <SuggestionCard
                  title={`Sugestão ${idx + 1}`}
                  description={item}
                  onClick={() => {
                    sendQuestion.mutate(item)
                  }}
                />
              )
            })}
          </div>
        )}
        {!legacy && (
          <>
            <form
              className='chat-viewer__back-space__input-space'
              onSubmit={e => {
                e.preventDefault()
                if (isGenerating) {
                  cancelChatRun(docId)
                } else {
                  sendQuestion.mutate()
                }
              }}>
              {
                <input
                  className='chat-viewer__back-space__input-space__bar'
                  placeholder={
                    status === 'paid' ? 'Digite aqui a sua mensagem' : 'Seu plano não está ativo'
                  }
                  value={question}
                  onChange={e => !sendQuestion.isLoading && setQuestion(e.target.value)}
                  disabled={sendQuestion.isLoading || status !== 'paid'}
                />
              }
              <button
                className='chat-viewer__back-space__input-space__button'
                disabled={
                  sendQuestion.isLoading ||
                  (question.length === 0 && !isGenerating) ||
                  status !== 'paid'
                }>
                {isGenerating ? (
                  <div style={{ width: '16px', height: '16px', backgroundColor: '#272929' }} />
                ) : (
                  <CustomIcon icon='Send' size={20} />
                )}
              </button>
              {!isMobile && status === 'paid' && <FavoritePrompts onlyIcon />}
            </form>
            {isMobile && status === 'paid' && <FavoritePrompts />}
          </>
        )}
        {legacy && <LegacyCard />}

        {!legacy && (
          <p className='chat-viewer__footer'>
            Para obter resultados mais satisfatórios, é fundamental que você seja claro e minucioso
            ao perguntar.
          </p>
        )}
      </div>
    </div>
  )
}

export default ChatViewer
