import React, { useState, useEffect } from 'react'
import * as Progress from '@radix-ui/react-progress'
import Spinner from '../Spinner'
import './index.scss'

const HeaderProgressBar = ({ title, percent, loading }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const total = 20
    const currentValue = percent
    const proportionalPercent = (currentValue / total) * 100
    const roundedPercent = Math.ceil(proportionalPercent * 10) / 10

    setProgress(roundedPercent)
  }, [percent])

  return (
    <div className='header-progress-bar__container'>
      <div className='header-progress-bar__title'>
        <p>{title}</p>
        <span>{loading ? <Spinner size={8} containerHeight='16px' /> : `${progress}%`}</span>
      </div>
      <Progress.Root className='header-progress-bar__area' value={parseFloat(progress) || 0}>
        <Progress.Indicator
          className='header-progress-bar__indicator'
          style={{ transform: `translateX(-${99 - progress}%)` }}
        />
      </Progress.Root>
    </div>
  )
}

export default HeaderProgressBar
