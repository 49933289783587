import { v4 as uuidv4 } from 'uuid'
import './index.scss'

/**
 *
 * @param {Any} value - Value to be checked, true, false or "undefined"
 * @param {Boolean} disabled - If true, the checkbox will be disabled
 * @param {Function} onChange - Function to be called when the checkbox is changed, response the value of the checkbox
 * @returns
 */
function Checkbox({
  value = false,
  label,
  disabled = false,
  onChange = (checked = false) => {},
  blackCheck = false,
  ...props
}) {
  const Check = () => (
    <svg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.125 6.5125L0 3.3875L0.88125 2.50625L3.125 4.74375L7.86875 0L8.75 0.8875L3.125 6.5125Z'
        fill={blackCheck ? '#1B1B1B' : '#FFF'}
      />
    </svg>
  )

  const Line = () => (
    <svg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='3' width='9' height='1.2' fill='#FFF' />
    </svg>
  )

  const id = uuidv4()

  return (
    <div className='checkbox__container'>
      <div
        className={disabled ? 'checkbox checkbox--disabled' : 'checkbox'}
        onClick={() => onChange(!value)}
        {...props}>
        <input type='checkbox' checked={value} onChange={() => {}} id={id} />
        <span
          className={
            value !== false
              ? 'checkbox__checkmark checkbox__checkmark--checked'
              : 'checkbox__checkmark'
          }>
          {value === 'undefined' && <Line />}
          {value === true && <Check />}
        </span>
      </div>
      {label && (
        <label htmlFor={id} className='checkbox__label'>
          {label}
        </label>
      )}
    </div>
  )
}

export default Checkbox
