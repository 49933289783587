import React from 'react'

import './index.scss'

const SuggestionCard = ({ title, description, onClick }) => {
  return (
    <button className='suggestion' onClick={onClick}>
      <h3 className='suggestion__title'>{title}</h3>
      <p className='suggestion__description'>{description}</p>
    </button>
  )
}

export default SuggestionCard
